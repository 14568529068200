import withNavbar from './components/withNavBar';
import { Partner } from './Partner/Partner';
import { PartnersList } from './PartnersList/PartnersList';
import { PitchTalkMetaData } from './PitchTalkMetaData/PitchTalkMetaData';

const PartnersListPage = withNavbar(PartnersList);
const PartnerPage = withNavbar(Partner);
const PitchTalkMetaDataPage = withNavbar(PitchTalkMetaData);

export { PartnersListPage, PartnerPage, PitchTalkMetaDataPage };
