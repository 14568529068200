import { theme } from 'shared/components/theme';

export const submissionsWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  maxHeight: '500px',
  overflowY: 'auto',
  padding: '10px',
  mt: 1,
};

export const submissionBox = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '12px 8px',
  borderRadius: '5px',
  gap: '8px',
};
