import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { ToastService } from 'services/toast/ToastService';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { SettingsInput } from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';
import { IUser } from 'shared/interfaces/backendInterfaces';
import { IAuthServerModal } from 'shared/interfaces/modals';
import { checkIsAdmin, checkIsSuperAdmin } from 'shared/utils/userUtils';
import { loginUser, logoutUser } from 'store/thunks/user';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 64px',
  boxShadow: 24,
  borderRadius: '10px',
  outline: 'none',
};

export const AuthServerModal: React.FC<IAuthServerModal> = ({
  closeModal,
  isClose = true,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    setIsLoading(true);
    dispatch(loginUser(login, pass))
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const user = res.payload as IUser;
        if (checkIsAdmin(user) || checkIsSuperAdmin(user)) {
          ToastService.success('', 'Successfully login');
        } else if (user) {
          ToastService.error('', 'You do not have access to admin panel');
          dispatch(logoutUser());
        }
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        ToastService.error('', 'Incorrect email or pass');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={isClose ? closeModal : () => void {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box sx={style}>
          {isLoading && (
            <LoaderWithOverlay
              sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '10px',
              }}
            />
          )}
          <Typography
            sx={{
              fontFamily: 'Everett-Regular',
              fontSize: '1.25rem',
              textAlign: 'center',
              marginBottom: '32px',
            }}
          >
            Login to server
          </Typography>
          <SettingsInput
            inputLabel="Login: "
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            inputStyles={{
              '.MuiOutlinedInput-root': {
                color: theme.palette.input.light,
                background: theme.palette.input.dark,
              },
            }}
            color="info"
            size="small"
            boxStyles={{ mb: 2 }}
          />
          <SettingsInput
            inputLabel="Pass: "
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            inputStyles={{
              '.MuiOutlinedInput-root': {
                color: theme.palette.input.light,
                background: theme.palette.input.dark,
              },
            }}
            type="password"
            color="info"
            size="small"
            boxStyles={{ mb: 2 }}
          />
          <PrimaryButton
            sx={{ width: '100%', height: '42px', mt: 4 }}
            variant="contained"
            disabled={!login || !pass}
            onClick={handleLogin}
          >
            Sign In
          </PrimaryButton>
        </Box>
      </Modal>
    </div>
  );
};
