import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { IPartner } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { APP_ROUTES } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { useNavigate, useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { PreviewImage } from 'shared/components/PreviewImage/PreviewImage';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { flexCenter } from 'shared/utils/themeUtils';
import { getPartnerValidationSchema } from 'shared/utils/validationsUtils';
import {
  selectPartnerById,
  selectMetaDataIsLoading,
} from 'store/slices/metaData';
import { addPartner, editPartner } from 'store/thunks/metaData';

export const Partner: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showModal } = useModalContext();
  const navigate = useNavigate();

  const { partnerId } = useParams();

  const partner = useAppSelector((state) =>
    selectPartnerById(state, partnerId || '')
  );
  const isLoading = useAppSelector(selectMetaDataIsLoading);

  const formik = useFormik<IPartner>({
    initialValues: partner,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (partner.id) {
        dispatch(editPartner(values));
      } else {
        dispatch(addPartner(omit(values, 'id'))).then(() =>
          navigate(APP_ROUTES.PARTNERS)
        );
      }
    },
    validationSchema: getPartnerValidationSchema(),
  });

  const openImageModal = () => {
    showModal(EModals.UPLOAD_IMAGE_MODAL, {
      submitImage: (imageCid: string) => {
        formik.setFieldValue('image', imageCid);
      },
      image: formik.values.image || '',
      imageName: formik.values.name || '',
    });
  };

  return (
    <Box>
      <Box sx={{ width: '1300px', display: 'flex', gap: '25px' }}>
        <SettingsBox
          legend="Partner Settings"
          sx={{
            padding: '10px',
            width: '480px',
            height: 'fit-content',
            marginBottom: '20px',
            '.MuiFormGroup-root': { paddingBlock: '5px' },
          }}
        >
          {isLoading && <LoaderWithOverlay />}
          <Box>
            <SettingsInput
              inputLabel="Name"
              name="name"
              value={formik.values.name || ''}
              onFocus={() => formik.setFieldTouched('name', true)}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              error={formik.touched.name && !!formik.errors.name}
              helperText={
                formik.touched.name && formik.errors && formik.errors.name
              }
            />
            <SettingsInput
              inputLabel="Partner url"
              name="partner_url"
              value={formik.values.url || ''}
              onFocus={() => formik.setFieldTouched('url', true)}
              onChange={(e) => formik.setFieldValue('url', e.target.value)}
              error={formik.touched.url && !!formik.errors.url}
              helperText={
                formik.touched.url && formik.errors && formik.errors.url
              }
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '6px 0',
                alignItems: 'center',
              }}
            >
              <FormLabel>Partner image</FormLabel>
              <PrimaryButton
                sx={{
                  width: 'fit-content',
                  fontSize: '12px',
                  height: '30px',
                }}
                onClick={() => openImageModal()}
              >
                Upload
              </PrimaryButton>
            </div>
            <Box sx={flexCenter}>
              <PreviewImage image={formik.values.image} />
            </Box>
          </Box>
        </SettingsBox>
      </Box>
      <Box>
        <PrimaryButton
          onClick={() => formik.handleSubmit()}
          disabled={
            !(formik.isValid || formik.dirty) ||
            formik.isSubmitting ||
            isLoading
          }
        >
          {partner.id ? 'Update' : 'Add'} partner
        </PrimaryButton>
      </Box>
    </Box>
  );
};
