import { useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import EStorageKeys from 'services/constants/localStorageKeys';
import * as LocalStorage from 'services/localStorage';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { IConfirmModal } from 'shared/interfaces/modals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 32px',
  boxShadow: 24,
  borderRadius: '10px',
};

export const ConfirmModal: React.FC<IConfirmModal> = ({
  closeModal,
  confirmAction,
}) => {
  const theme = useTheme();
  const [doNotShow, setDoNotShow] = useState<boolean>(false);

  const handleConfirm = async () => {
    await confirmAction();
    if (doNotShow) {
      LocalStorage.setItem(EStorageKeys.DO_NOT_SHOW_MODALS, true);
    }
    closeModal();
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Box sx={style}>
        <Typography
          sx={{
            fontFamily: 'Everett-Regular',
            fontSize: '1.125rem',
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Be aware, your transaction might confirm without sign in wallet. This
          action is irreversible. Are you sure want to confirm?
        </Typography>
        <PrimaryButton
          sx={{ width: '100%', height: '42px', marginBottom: '20px' }}
          onClick={handleConfirm}
        >
          Confirm
        </PrimaryButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            sx={{ '.MuiSvgIcon-root': { fill: theme.palette.primary.main } }}
            value={doNotShow}
            onChange={(e) => setDoNotShow(e.target.checked)}
          />
          <Typography
            sx={{
              fontFamily: 'Everett-Regular',
              fontSize: '1.125rem',
              textAlign: 'center',
              color: theme.palette.secondary.main,
            }}
          >
            I understand, do not show me again
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
