import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { theme } from 'shared/components/theme';
import { FIVE_SEC } from 'shared/constants';
import { IInfoModal } from 'shared/interfaces/modals';
import { flexCenter } from 'shared/utils/themeUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 32px',
  boxShadow: 24,
  borderRadius: '10px',
  fontFamily: 'Everett-Regular',
};

export const InfoModal: React.FC<IInfoModal> = ({
  info,
  subInfo,
  loadingSelector,
  submitAction,
  cancelAction,
  closeAction,
  closeModal,
  closeDelay = 2 * FIVE_SEC,
}) => {
  const isLoading = useAppSelector(loadingSelector || (() => false));

  useEffect(() => {
    if (!closeDelay) return;
    const timeoutId = setTimeout(() => {
      closeModal();
    }, closeDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const onClose = () => {
    closeAction?.();
    closeModal();
  };

  const onCancel = () => {
    cancelAction?.();
    closeModal();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Box sx={style}>
        <Box sx={{ ...flexCenter, flexDirection: 'column' }}>
          {info}
          {subInfo}
        </Box>
        <Box sx={{ ...flexCenter, gap: '15px', mt: 2 }}>
          {submitAction && (
            <PrimaryButton onClick={submitAction}>Submit</PrimaryButton>
          )}
          {cancelAction && (
            <PrimaryButton
              sx={{
                '&, :hover, :active': { background: theme.palette.error.main },
              }}
              onClick={onCancel}
            >
              Cancel
            </PrimaryButton>
          )}
        </Box>
        {isLoading && <LoaderWithOverlay />}
      </Box>
    </Modal>
  );
};
