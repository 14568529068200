import { SxProps } from '@mui/material';

export const getLinkStyles = ({ isActive }: { isActive: boolean }) => ({
  textDecoration: isActive ? 'underline' : 'none',
  fontFamily: isActive ? 'Everett-Medium' : 'Everett-Regular',
});

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const SELECT_MENU_PROPS = {
  PaperProps: {
    style: { maxHeight: 48 * 4.5 + 8, width: 250 },
  },
};

export const getLineClamp = (lineClamp = 2): SxProps => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordWrap: 'break-word',
  WebkitLineClamp: lineClamp,
});
