import { IPartner } from '@pitchtalk/contract-api-js/dist/interfaces';
import { PARTNER_ID } from 'routes/routes';
import {
  parseErrorData,
  parseSuccessData,
  parseData,
} from 'services/api/utils';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export const fetchPartners = (limit: number) =>
  fetchAPI
    .get<IPartner[]>(`${API_ROUTES.partners}?limit=${limit}`)
    .then(parseData)

export const fetchPartnersCount = () =>
  fetchAPI
    .get<number>(API_ROUTES.partnersCount)
    .then(parseData)

export const fetchPartnerById = (id: string) =>
  fetchAPI
    .get<IPartner>(API_ROUTES.partnersById.replace(PARTNER_ID, id))
    .then(parseData)

export const fetchAddNewPartner = (partner: IPartner) =>
  fetchAPI
    .post(API_ROUTES.partners, { ...partner })
    .then(parseSuccessData('Partner successfully added'))
    .catch(parseErrorData('Partner add error'));

export const fetchEditPartner = (partner: IPartner) =>
  fetchAPI
    .patch(API_ROUTES.partners, { ...partner })
    .then(parseSuccessData('Partner successfully edit'))
    .catch(parseErrorData('Partner edit error'));

export const fetchRemovePartner = (id: string) =>
  fetchAPI
    .delete(API_ROUTES.partnersById.replace(PARTNER_ID, id))
    .then(parseSuccessData('Partner successfully removed'))
    .catch(parseErrorData('Partner remove error'));
