import { ESocialLinksKeys } from '@pitchtalk/contract-api-js/dist/core';
import { Attachments } from 'shared/utils/projectSettingsUtils';

export const getAttachmentsSettings = (): {
  label: string;
  name: Attachments;
}[] => [
  { label: 'Tokenomics (link):', name: Attachments.TOKENOMICS },
  { label: 'PitchDeck (link):', name: Attachments.PITCHDECK },
  { label: 'Documentation (link):', name: Attachments.WHITEPAPER },
];

export const getSocialMediaBlockSettings = (): {
  label: string;
  name: ESocialLinksKeys;
}[] => [
  { label: 'Twitter:', name: ESocialLinksKeys.TWITTER },
  { label: 'Telegram:', name: ESocialLinksKeys.TELEGRAM },
  { label: 'Medium:', name: ESocialLinksKeys.MEDIUM },
  { label: 'Discord:', name: ESocialLinksKeys.DISCORD },
  { label: 'Near Social:', name: ESocialLinksKeys.NEAR_SOCIAL },
];

export const getGeneralBlockSettings = (): {
  label: string;
  name: 'name' | 'description' | 'banner' | 'project_url' | 'logo';
  props?: { [key: string]: boolean | number };
}[] => [
  { label: 'Project name:', name: 'name' },
  {
    label: 'Project description:',
    name: 'description',
    props: { multiline: true, rows: 6 },
  },
  // { label: 'Project banner(link):', name: 'banner' },
  // { label: 'Project logo(link):', name: 'logo' },
  { label: 'Project URL:', name: 'project_url' },
];
