import { FungibleTokenService } from '@pitchtalk/contract-api-js';
import { nodeUrl } from 'services/config';

class FTService {
  private API: FungibleTokenService | null = null;

  public createService = () => {
    this.API = new FungibleTokenService(nodeUrl);
  };

  private withAPI = <T>(func: (API: FungibleTokenService) => Promise<T>) => {
    return this.API
      ? func(this.API)
      : Promise.reject(new Error('Cannot find fungible token service'));
  };

  // API METHODS
  getMetadata = (ftTokenId: string) =>
    this.withAPI((API) => API.getMetadata(ftTokenId));

  getBalanceOf = (args: { accountId: string; ftTokenId: string }) =>
    this.withAPI((API) => API.getBalanceOf(args));
}

export default new FTService();
