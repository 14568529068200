import { SxProps, Theme } from '@mui/material';
import {
  BenefitPlanLinear,
  BenefitPlanNonLinear,
  BenefitPlanTypes,
  EContributionType,
  ESocialLinksKeys,
  EUniqWithdrawType,
  ProjectArgs,
  Vesting,
  WithdrawType,
  Project,
} from '@pitchtalk/contract-api-js/dist/core';
import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import {
  EProjectType,
  EventAccessFeeTypePayed,
  IOffChainProject,
  ITag,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import {
  EActionStatus,
  EWithdrawStatus,
  EWithdrawType,
  Metadata,
  WithdrawRequest,
} from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import secToMs from 'date-fns/secondsToMilliseconds';
// import { SubProject } from 'pages/SubmissionManagement/SubmissionsPage/SubmissionsListPage/useSubmissions';
import { wrapNearId } from 'services/config';
// import { SubProjectTableData } from 'shared/components/ProjectsTable/types';
import {
  DEFAULT_TOKEN_META,
  LINEAR_VESTING_START,
  MAX_PERCENT,
  NON_LINEAR_VESTING_START,
  PRECISION_DEFAULT,
  YOCTO_IN_NEAR_DECIMALS,
  ZERO,
} from 'shared/constants';

import { getAmountFormatted } from './near';
import { getVestingTokenAmountByPrice, getVestingType } from './vestingUtils';
import { IProject } from 'shared/interfaces/backendInterfaces';

export const defaultProjectStatusStyles: SxProps<Theme> = {
  paddingBlock: '5px',
  marginInline: '15px',
  borderRadius: '5px',
  textAlign: 'center',
};

export const STATUS_COLORS = {
  [EActionStatus.New]: '#3ED8E2',
  [EActionStatus.Active]: '#16AF4A',
  [EActionStatus.Updated]: '#DE6D3C',
  [EActionStatus.Failed]: '#E23E3E',
  CLEAR: '#ca4b14',
};

type WithdrawFuncProps = {
  project?: Project;
  grantTokenId?: string;
  subMeta?: Metadata;
  eventMeta?: EventAccessFeeTypePayed;
  vesting?: Vesting | null;
};

const getProjectAvailableDonateWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ project }: WithdrawFuncProps) =>
    +getAmountFormatted(
      project?.total_donations ?? ZERO,
      tokens[project?.ft_token_id || ''].decimals
    ) -
    +getAmountFormatted(
      project?.total_withdrawn_donations ?? ZERO,
      tokens[project?.ft_token_id || ''].decimals
    );

const getProjectAvailableInvestWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ project }: WithdrawFuncProps) =>
    +getAmountFormatted(
      project?.total_investments ?? ZERO,
      tokens[project?.ft_token_id || ''].decimals
    ) -
    +getAmountFormatted(
      project?.total_withdrawn_investments ?? ZERO,
      tokens[project?.ft_token_id || ''].decimals
    );

const getProjectAvailableGrantsWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ project, grantTokenId }: WithdrawFuncProps) =>
    +getAmountFormatted(
      project?.total_grants?.[grantTokenId || ''] ?? ZERO,
      tokens[grantTokenId || ''].decimals
    ) -
    +getAmountFormatted(
      project?.total_withdrawn_grants?.[grantTokenId || ''] ?? ZERO,
      tokens[grantTokenId || ''].decimals
    );

export const getVestingTokensAvailableWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ project, vesting }: WithdrawFuncProps) => {
    if (!project || !vesting) return ZERO;
    const projectToken = tokens[project.ft_token_id];

    const vestingTokens = +getAmountFormatted(
      vesting.amount,
      tokens[vesting.ft_token_id].decimals
    );
    const totalInvestedMultByPrice = +getAmountFormatted(
      +getVestingTokenAmountByPrice(
        project.total_investments,
        +getAmountFormatted(
          vesting.price,
          YOCTO_IN_NEAR_DECIMALS,
          PRECISION_DEFAULT
        ),
        projectToken.decimals
      ),
      YOCTO_IN_NEAR_DECIMALS
    );
    const vestingWithdrawn = +getAmountFormatted(
      vesting.withdrawn_amount,
      tokens[vesting.ft_token_id].decimals
    );

    return vestingTokens - totalInvestedMultByPrice - vestingWithdrawn;
  };

export const getSubmissionTokensAvailableWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ subMeta }: WithdrawFuncProps) => {
    const tokenMeta = subMeta ? tokens[subMeta.fee_token] : DEFAULT_TOKEN_META;
    return Number(
      getAmountFormatted(subMeta?.total_fee_balance || ZERO, tokenMeta.decimals)
    );
  };

export const getEventTokensAvailableWithdraw =
  (tokens: { [key: string]: ITokenMetadata }) =>
  ({ eventMeta }: WithdrawFuncProps) => {
    const tokenMeta = eventMeta
      ? tokens[eventMeta.token_id]
      : DEFAULT_TOKEN_META;

    return Number(
      getAmountFormatted(eventMeta?.total_fee || ZERO, tokenMeta.decimals)
    );
  };

export const getAvailableWithdraw = {
  [EContributionType.DONATIONS]: getProjectAvailableDonateWithdraw,
  [EContributionType.INVESTMENTS]: getProjectAvailableInvestWithdraw,
  [EContributionType.GRANTS]: getProjectAvailableGrantsWithdraw,
  [EUniqWithdrawType.VESTING_TOKENS]: getVestingTokensAvailableWithdraw,
  [EUniqWithdrawType.SUBMISSION_FEE]: getSubmissionTokensAvailableWithdraw,
  [EUniqWithdrawType.EVENT_FEE]: getEventTokensAvailableWithdraw,
};

// UTILS
export const getProjectArgs = (subProject: IProject): ProjectArgs => ({
  name: subProject.name,
  description: subProject.description,
  logo: subProject.logo,
  banner: subProject.banner,
  project_url: subProject.project_url,
  social_links: {
    [ESocialLinksKeys.TWITTER]: subProject.social_links?.twitter || '',
    [ESocialLinksKeys.TELEGRAM]: subProject.social_links?.telegram || '',
    [ESocialLinksKeys.MEDIUM]: subProject.social_links?.medium || '',
    [ESocialLinksKeys.DISCORD]: subProject.social_links?.discord || '',
    [ESocialLinksKeys.NEAR_SOCIAL]: subProject.social_links?.near_social || '',
  },
  tags: subProject?.tags.map((tag: string | ITag): string => (tag as ITag)?.id || (tag as string)) || [],
});

export const getWithdrawType = (withdraw: WithdrawRequest): WithdrawType =>
  ({
    [EWithdrawType.Donations]: EContributionType.DONATIONS,
    [EWithdrawType.Investments]: EContributionType.INVESTMENTS,
    [EWithdrawType.Grants]: EContributionType.GRANTS,
    [EWithdrawType.Vesting]: EUniqWithdrawType.VESTING_TOKENS,
  }[withdraw.withdraw_type]);

// export const sortSubProjectsByStatus = (a: SubProject, b: SubProject) => {
//   const getStatusNumb = (status: EActionStatus) => {
//     if (status === EActionStatus.New) {
//       return 1;
//     }
//     if (status === EActionStatus.Updated) {
//       return 2;
//     }
//     if (status === EActionStatus.Failed) {
//       return 3;
//     }
//     if (status === EActionStatus.Active) {
//       return 4;
//     }
//     return 5;
//   };

//   return getStatusNumb(a.status) - getStatusNumb(b.status);
// };

// export const getSubProjectFinDataForValidation = (
//   (now: number) =>
//   (project: IProject, tokens: { [key: string]: ITokenMetadata }) => {
//     const tokenId = wrapNearId //project?.token_id || wrapNearId;
//     const projectTokenDecimals = tokens[tokenId].decimals;
//     const investmentsInfo = {} //project?.vesting_and_investment;

//     return {
//       finData: {
//         ft_token_id: tokenId,
//         investment_min: Number(
//           getAmountFormatted(
//             investmentsInfo?.investment_min ?? ZERO,
//             projectTokenDecimals,
//             PRECISION_DEFAULT
//           )
//         ),
//         investment_max: Number(
//           getAmountFormatted(
//             investmentsInfo?.investment_max ?? ZERO,
//             projectTokenDecimals,
//             PRECISION_DEFAULT
//           )
//         ),
//         total_investments_limit: Number(
//           getAmountFormatted(
//             investmentsInfo?.total_investments_limit ?? ZERO,
//             projectTokenDecimals,
//             PRECISION_DEFAULT
//           )
//         ),
//         investment_end_date: investmentsInfo?.investment_end_date ?? now,
//       },
//       vesting: {
//         vestingType: investmentsInfo?.benefit_plan
//           ? getVestingType(investmentsInfo.benefit_plan)
//           : BenefitPlanTypes.Linear,
//         ft_token_id: investmentsInfo?.token_id
//           ? investmentsInfo.token_id
//           : wrapNearId,
//         start_date: investmentsInfo?.start_sec
//           ? secToMs(investmentsInfo.start_sec)
//           : now,
//         price: investmentsInfo?.price
//           ? Number(
//               getAmountFormatted(investmentsInfo.price, YOCTO_IN_NEAR_DECIMALS)
//             )
//           : ZERO,
//         [BenefitPlanTypes.Linear]: {
//           endDate:
//             investmentsInfo &&
//             getVestingType(investmentsInfo.benefit_plan) ===
//               BenefitPlanTypes.Linear
//               ? secToMs(investmentsInfo.start_sec) +
//                 secToMs(
//                   (investmentsInfo.benefit_plan as BenefitPlanLinear)[
//                     BenefitPlanTypes.Linear
//                   ].duration
//                 )
//               : now + LINEAR_VESTING_START,
//         },
//         [BenefitPlanTypes.NonLinear]:
//           investmentsInfo &&
//           getVestingType(investmentsInfo.benefit_plan) ===
//             BenefitPlanTypes.NonLinear
//             ? (investmentsInfo.benefit_plan as BenefitPlanNonLinear)[
//                 BenefitPlanTypes.NonLinear
//               ].map(({ percentage, time_since_start }) => ({
//                 endDate:
//                   secToMs(investmentsInfo.start_sec) +
//                   secToMs(time_since_start),
//                 percentage,
//               }))
//             : [
//                 { endDate: ZERO, percentage: ZERO },
//                 {
//                   endDate: now + NON_LINEAR_VESTING_START,
//                   percentage: MAX_PERCENT,
//                 },
//               ],
//       },
//     };
//   }
// )(Date.now());

export const isProject = (project?: Project | null): project is Project =>
  Boolean(project);

// export const checkIsNewWithdraws = (
//   subProject?: IProject
// ) =>
//   Object.values(subProject?.request_to_withdraw || {}).some(
//     (withdraw) => withdraw.status === EWithdrawStatus.New
//   );

export const sleep = async (time: number) =>
  await new Promise((resolve) => setTimeout(resolve, time));

export const isOffChainProject = (
  project: Project | IOffChainProject
): project is IOffChainProject => !!(project as IOffChainProject).id;

export const isOffChainProjectType = (type: EProjectType) =>
  type === EProjectType.OffChain;
