import Box from '@mui/material/Box';
import { IPitchtalkMetadata } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { getPitchTalkMetaDataValidationSchema } from 'shared/utils/validationsUtils';
import {
  selectMetaDataIsLoading,
  selectPitchtalkMetaData,
} from 'store/slices/metaData';
import { updatePitchTalkMetaData } from 'store/thunks/metaData';

export const PitchTalkMetaData: React.FC = () => {
  const dispatch = useAppDispatch();

  const metaData = useAppSelector(selectPitchtalkMetaData);
  const isLoading = useAppSelector(selectMetaDataIsLoading);

  const formik = useFormik<IPitchtalkMetadata>({
    initialValues: {
      hackers: metaData?.hackers || 0,
      investors: metaData?.investors || 0,
      funded: metaData?.funded || 0,
    },
    enableReinitialize: true,
    onSubmit: async (values) => dispatch(updatePitchTalkMetaData(values)),
    validationSchema: getPitchTalkMetaDataValidationSchema(),
  });

  return (
    <Box>
      <Box sx={{ width: '1300px', display: 'flex', gap: '25px' }}>
        <SettingsBox
          legend="PitchTalk MetaData Settings"
          sx={{
            padding: '10px',
            width: '480px',
            height: 'fit-content',
            marginBottom: '20px',
            '.MuiFormGroup-root': { paddingBlock: '5px' },
          }}
        >
          {isLoading && <LoaderWithOverlay />}
          <Box>
            <SettingsInput
              inputLabel="Hackers"
              name="Hackers"
              value={formik.values.hackers || 0}
              onFocus={() => formik.setFieldTouched('hackers', true)}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                formik.setFieldValue('hackers', parseInt(e.target.value) || 0)
              }
              error={formik.touched?.hackers && !!formik.errors?.hackers}
              helperText={
                formik.touched?.hackers &&
                formik.errors &&
                formik.errors?.hackers
              }
            />
            <SettingsInput
              inputLabel="Investors"
              name="Investors"
              value={formik.values.investors || 0}
              onFocus={() => formik.setFieldTouched('investors', true)}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                formik.setFieldValue('investors', parseInt(e.target.value) || 0)
              }
              error={formik.touched?.investors && !!formik.errors?.investors}
              helperText={
                formik.touched?.investors &&
                formik.errors &&
                formik.errors?.investors
              }
            />
            <SettingsInput
              inputLabel="Funded $"
              name="Funded"
              value={formik.values.funded || 0}
              onFocus={() => formik.setFieldTouched('funded', true)}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                formik.setFieldValue('funded', parseInt(e.target.value) || 0)
              }
              error={formik.touched?.funded && !!formik.errors?.funded}
              helperText={
                formik.touched?.funded && formik.errors && formik.errors?.funded
              }
            />
          </Box>
        </SettingsBox>
      </Box>
      <Box>
        <PrimaryButton
          onClick={() => formik.handleSubmit()}
          disabled={
            !(formik.isValid || formik.dirty) ||
            formik.isSubmitting ||
            isLoading
          }
        >
          Save metadata
        </PrimaryButton>
      </Box>
    </Box>
  );
};
