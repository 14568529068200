// import CloseIcon from '@mui/icons-material/Close';
// import Backdrop from '@mui/material/Backdrop';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
// import { EActionStatus } from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
// import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
// import { IWhatChangedSubModal } from 'shared/interfaces/modals';
// import { DiffChangesTypes, EChangedSections } from 'shared/utils/whatChanged';

import { IWhatChangedSubModal } from 'shared/interfaces/modals';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   maxHeight: '80%',
//   background: '#141414',
//   color: 'white',
//   border: '2px solid #653EE2',
//   padding: '0px 52px 20px',
//   boxShadow: 24,
//   borderRadius: '10px',
//   outline: 'none',
//   overflowY: 'auto',
// };

// const TABLE_COLUMNS = ['Field Name', 'From', 'To'];

// type NonLinearPhaseStep = {
//   percentage?: number;
//   endDate?: string;
// };

// export const WhatChangedSubModal: React.FC<IWhatChangedSubModal> = ({
//   closeModal,
//   diff,
// }): JSX.Element => {
//   const isDiff = Object.keys(diff).filter(
//     (key) => !key.includes('.status')
//   ).length;

//   const [
//     generalSettingsSection,
//     linksSettingsSection,
//     docsSettingsSection,
//     introPitchSettingsSection,
//     updatePitchSettingsSection,
//     investPitchSettingsSection,
//     financialSettingsSection,
//     teamSettingsSection,
//   ] = Object.values(EChangedSections).map((sectionName) =>
//     Object.entries(diff).filter(([key]) => key.startsWith(sectionName))
//   );

//   const renderRow = (key: string, from: any, to: any) => (
//     <TableRow
//       key={key}
//       sx={{
//         borderBottom: '0.5px solid #FFFFFF',
//         '.MuiTableCell-root': { borderBottom: 'unset' },
//       }}
//     >
//       <TableCell sx={{ width: '20%' }}>{key}</TableCell>
//       <TableCell sx={{ width: '40%' }}>{from.toString()}</TableCell>
//       <TableCell sx={{ width: '40%' }}>{to.toString()}</TableCell>
//     </TableRow>
//   );

//   const SectionHeader = ({ title }: { title: EChangedSections }) => (
//     <TableRow sx={{ borderBottom: '2px solid #FFFFFF' }}>
//       <TableCell
//         colSpan={3}
//         sx={{ textAlign: 'center', fontSize: '1.1rem', paddingTop: '50px' }}
//       >
//         {title}
//       </TableCell>
//     </TableRow>
//   );

//   const Section = ({
//     sectionData,
//     sectionTitle,
//   }: {
//     sectionData: [string, { from: DiffChangesTypes; to: DiffChangesTypes }][];
//     sectionTitle: EChangedSections;
//   }) => (
//     <>
//       {!!Object.keys(sectionData).length && (
//         <>
//           <SectionHeader title={sectionTitle} />
//           {sectionData?.map(([key, value]) =>
//             renderRow(getFieldKey(key), value.from ?? '-', value.to ?? '-')
//           )}
//         </>
//       )}
//     </>
//   );

//   const SectionDocs = ({
//     sectionData,
//     sectionTitle,
//   }: {
//     sectionData: [string, { from: DiffChangesTypes; to: DiffChangesTypes }][];
//     sectionTitle: EChangedSections;
//   }) => (
//     <>
//       {!!sectionData.filter(
//         (section) =>
//           section[0].includes('status') &&
//           section[1].to !== EActionStatus.Active
//       ).length && (
//         <>
//           <SectionHeader title={sectionTitle} />
//           {sectionData
//             .filter((section) => section[0].includes('status'))
//             .map(([docKey, value]) => {
//               const docStatus = value.to;
//               const docName = getFieldKey(docKey);
//               const sectionValues = sectionData.filter((section) =>
//                 section[0].includes('url')
//               );
//               const sectionValue = sectionValues.find(
//                 (field) => getFieldKey(field[0]) === docName
//               )?.[1];

//               if (docStatus === EActionStatus.New) {
//                 return renderRow(
//                   docName,
//                   EActionStatus.New,
//                   sectionValue?.to || '-'
//                 );
//               }
//               if (docStatus === EActionStatus.Updated) {
//                 return renderRow(
//                   docName,
//                   sectionValue?.from || '-',
//                   sectionValue?.to || '-'
//                 );
//               }
//               if (docStatus === EActionStatus.Removed) {
//                 return renderRow(
//                   docName,
//                   sectionValue?.from || '-',
//                   EActionStatus.Removed
//                 );
//               }
//             })}
//         </>
//       )}
//     </>
//   );

//   const SectionTeam = ({
//     sectionData,
//     sectionTitle,
//   }: {
//     sectionData: [string, { from: DiffChangesTypes; to: DiffChangesTypes }][];
//     sectionTitle: EChangedSections;
//   }) => (
//     <>
//       {!!Object.keys(sectionData).length && (
//         <>
//           <SectionHeader title={sectionTitle} />
//           {sectionData?.map(([key, value]) => {
//             const name = key.split('.').slice(1)[0].replace('-', '.');

//             if (value.from && !value.to) {
//               return renderRow(name, '-', EActionStatus.Removed);
//             }
//             const field = key.split('.').slice(1)[1];
//             if (!value.from && value.to && field !== 'Links') {
//               return renderRow(name, '-', EActionStatus.New);
//             }
//             return renderRow(
//               name + ' ' + field,
//               value.from || '-',
//               value.to || '-'
//             );
//           })}
//         </>
//       )}
//     </>
//   );

//   const getFieldKey = (key: string, i = 1) => key.split('.')[i];

//   // DEBUG
//   // console.log(diff);

//   return (
//     <Modal
//       open={true}
//       onClose={closeModal}
//       closeAfterTransition
//       BackdropComponent={Backdrop}
//       BackdropProps={{ timeout: 500 }}
//     >
//       <Box sx={{ ...style, width: !isDiff ? '400px' : '80%' }}>
//         {isDiff ? (
//           <Table
//             sx={{
//               '.MuiTableHead-root': { background: '#141414' },
//               '.MuiTableCell-root': { color: '#FFFFFF', paddingInline: '8px' },
//               marginBlock: '30px',
//               position: 'relative',
//               zIndex: 1,
//             }}
//           >
//             <TableHead>
//               <TableRow>
//                 {TABLE_COLUMNS.map((column, ind) => (
//                   <TableCell
//                     sx={{
//                       textAlign: 'center',
//                       fontSize: '1.15rem',
//                       fontWeight: 700,
//                     }}
//                     key={ind}
//                   >
//                     {column}
//                     <CloseIcon
//                       sx={{
//                         position: 'absolute',
//                         zIndex: 2,
//                         right: 0,
//                         top: 15,
//                         cursor: 'pointer',
//                       }}
//                       onClick={() => closeModal()}
//                     />
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               <Section
//                 sectionData={generalSettingsSection}
//                 sectionTitle={EChangedSections.GENERAL}
//               />
//               <Section
//                 sectionData={linksSettingsSection}
//                 sectionTitle={EChangedSections.LINKS}
//               />
//               <SectionDocs
//                 sectionData={docsSettingsSection}
//                 sectionTitle={EChangedSections.DOCS}
//               />
//               <Section
//                 sectionData={introPitchSettingsSection}
//                 sectionTitle={EChangedSections.INTRO_PITCH}
//               />
//               <Section
//                 sectionData={updatePitchSettingsSection}
//                 sectionTitle={EChangedSections.UPDATE_PITCH}
//               />
//               <Section
//                 sectionData={investPitchSettingsSection}
//                 sectionTitle={EChangedSections.INVEST_PITCH}
//               />
//               {!!Object.keys(financialSettingsSection).length && (
//                 <>
//                   <SectionHeader title={EChangedSections.FINANCIAL} />
//                   {financialSettingsSection?.map(([key, value]) =>
//                     getFieldKey(key) !== 'NonLinear Vesting Phases' ? (
//                       renderRow(
//                         getFieldKey(key),
//                         value.from || '-',
//                         value.to || '-'
//                       )
//                     ) : (
//                       <>
//                         <SectionHeader
//                           title={
//                             (getFieldKey(key) +
//                               ' ' +
//                               getFieldKey(key, 2)) as EChangedSections
//                           }
//                         />
//                         <TableRow sx={{ borderBottom: '0.5px solid #FFFFFF' }}>
//                           <TableCell>Percentage</TableCell>
//                           <TableCell>
//                             {(value.from as NonLinearPhaseStep)?.percentage ??
//                               '-'}
//                           </TableCell>
//                           <TableCell>
//                             {(value.to as NonLinearPhaseStep)?.percentage ??
//                               '-'}
//                           </TableCell>
//                         </TableRow>
//                         <TableRow sx={{ borderBottom: '0.5px solid #FFFFFF' }}>
//                           <TableCell>End Date</TableCell>
//                           <TableCell>
//                             {(value.from as NonLinearPhaseStep)?.endDate ?? '-'}
//                           </TableCell>
//                           <TableCell>
//                             {(value.to as NonLinearPhaseStep)?.endDate ?? '-'}
//                           </TableCell>
//                         </TableRow>
//                       </>
//                     )
//                   )}
//                 </>
//               )}
//               <SectionTeam
//                 sectionData={teamSettingsSection}
//                 sectionTitle={EChangedSections.TEAM}
//               />
//             </TableBody>
//           </Table>
//         ) : (
//           <Box
//             sx={{
//               paddingBlock: '50px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               flexDirection: 'column',
//               gap: '35px',
//             }}
//           >
//             <Typography
//               sx={{ width: '100%', textAlign: 'center', fontSize: '1.5rem' }}
//             >
//               Cannot find changes
//             </Typography>
//             <PrimaryButton
//               sx={{ width: 'fit-content' }}
//               onClick={() => closeModal()}
//             >
//               Continue
//             </PrimaryButton>
//           </Box>
//         )}
//       </Box>
//     </Modal>
//   );
// };

export const WhatChangedSubModal: React.FC<IWhatChangedSubModal> = ({
  closeModal,
  // diff,
}): JSX.Element => <></>