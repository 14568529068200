import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { EHackathonTaskType } from '@pitchtalk/contract-api-js/dist/interfaces';
import { FormikProps } from 'formik';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { SELECT_MENU_PROPS } from 'shared/utils/themeUtils';
import { selectPartners } from 'store/slices/metaData';

import { IHackakathonTaskState } from './Tasks';

interface ITaskPrizeTypeProps {
  i: number;
  formik: FormikProps<IHackakathonTaskState[]>;
  isEventVoteEnded: boolean;
}

const HACKATHON_TYPE = [
  {
    type: EHackathonTaskType.Default,
    label: 'From organizer',
  },
  { type: EHackathonTaskType.Partner, label: 'From partner' },
];

export const TaskPrizeType: React.FC<ITaskPrizeTypeProps> = ({
  i,
  formik,
  isEventVoteEnded,
}) => {
  const partners = useAppSelector(selectPartners);

  const task = formik.values[i];

  return (
    <>
      <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
        <InputLabel id="task-type-label">Prize type</InputLabel>
        <Select
          labelId="task-type-label"
          id="task-type-label-select"
          value={task.type}
          onChange={(e) => formik.setFieldValue(`${i}.type`, e.target.value)}
          input={<OutlinedInput label="Prize type" />}
          MenuProps={SELECT_MENU_PROPS}
          readOnly={isEventVoteEnded}
        >
          {HACKATHON_TYPE.map(({ type, label }, i) => (
            <MenuItem key={type + label + i} value={type}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {task.type === EHackathonTaskType.Partner && (
        <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
          <InputLabel id="partner-label">Partner id</InputLabel>
          <Select
            labelId="partner-label"
            id="partner-label-select"
            value={task.partner}
            onChange={(e) =>
              formik.setFieldValue(`${i}.partner`, e.target.value)
            }
            input={
              <OutlinedInput
                label="Partner id"
                sx={{
                  '& .MuiSelect-outlined': {
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                }}
              />
            }
            MenuProps={SELECT_MENU_PROPS}
            readOnly={isEventVoteEnded}
          >
            {partners.map(({ id, name, image }, i) => (
              <MenuItem
                key={(id || '') + (name || '') + i}
                value={id || ''}
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ width: '36px', height: '36px' }}
                  src={getCorrectIPFSLinks({ image }).image}
                  alt="logo"
                />
                <span>{name}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
