import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { FormikProps } from 'formik';
import { isNull } from 'lodash';
import { SettingsInput } from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';

import { EventSettings } from '../helpers';

interface IMaxProjectsAmount {
  formik: FormikProps<EventSettings>;
  isReadOnly: boolean;
}

export const MaxProjectsAmount: React.FC<IMaxProjectsAmount> = ({
  formik,
  isReadOnly,
}) => {
  const isUnlimited = isNull(formik.values.participants_limit);

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue('participants_limit', e.target.checked ? null : 1);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0;
    formik.setFieldValue('participants_limit', value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          sx={{ '.MuiSvgIcon-root': { fill: theme.palette.primary.main } }}
          checked={isUnlimited}
          onChange={onCheckboxChange}
          disabled={isReadOnly}
        />
        <Typography>Unlimited</Typography>
      </Box>
      {!isUnlimited && (
        <Box>
          <SettingsInput
            inputLabel="Participants limit"
            name="participants_limit"
            value={formik.values.participants_limit || 0}
            onFocus={() => formik.setFieldTouched('participants_limit', true)}
            onChange={onInputChange}
            error={
              formik.touched?.participants_limit &&
              !!formik.errors?.participants_limit
            }
            helperText={
              formik.touched?.participants_limit &&
              formik.errors &&
              formik.errors?.participants_limit
            }
            InputProps={{ readOnly: isReadOnly }}
          />
        </Box>
      )}
    </Box>
  );
};
