import { BenefitPlanTypes } from '@pitchtalk/contract-api-js/dist/core';
// import { IEvent } from '@pitchtalk/contract-api-js/dist/interfaces';
import { MAX_DURATION, MIN_DURATION, ONE_SECOND } from 'shared/constants';
import * as regExp from 'shared/constants/regex';
import { IEventCombined } from 'store/slices/events';

import { isEventEnded, isEventStarted, isEventVoteEnded } from './eventsUtils';
import YUP from './yupUtils';
import { IEvent } from 'shared/interfaces/backendInterfaces';
import { EventSettings } from 'pages/EventsPage/Event/helpers';

const MAX_DESCRIPTION_LENGTH = 1000;

const projectExist = 'Project with this name already exist';
export const requiredField = (field = 'Field') => `${field} is required`;
const incorrectDate = 'Incorrect date';
const incorrectAmount = 'Incorrect amount';
export const invalidUrl = 'Invalid URL';
// eslint-disable-next-line quotes
const telegramInvalid = "Should be a valid 't.me' or 'telegram.me' link";
const twitterInvalid = 'Should be a valid twitter link';
const discordInvalid = 'Should be a valid discord link';
const mediumInvalid = 'Should be a valid medium link';
const nearSocialInvalid = 'Should be a valid near social link';

export const imageUploadError =
  'There is an error during image upload, choose another one, please.';

const removeEmptyString = (value: string) => value || undefined;

// SCHEMA
export const getGeneralSettingsValidationSchema = (
  projectsNames?: string[],
  isEdit?: boolean
) =>
  YUP.object({
    name: YUP.string()
      .notOneOf(
        !isEdit ? (projectsNames?.length ? projectsNames : []) : [],
        projectExist
      )
      .required(requiredField('Project name')),
    description: YUP.string()
      .max(MAX_DESCRIPTION_LENGTH, 'Description is too long')
      .required(requiredField('Project description')),
    logo: YUP.string(),
    banner: YUP.string(),
    project_url: YUP.string()
      .startWithHTTP()
      .url(invalidUrl)
      .required(requiredField('Project url')),
    telegram: YUP.string()
      .transform(removeEmptyString)
      .url(invalidUrl)
      .matches(regExp.TELEGRAM_REG_EXP, telegramInvalid)
      .optional(),
    medium: YUP.string()
      .transform(removeEmptyString)
      .url(invalidUrl)
      .matches(regExp.MEDIUM_COMBINED_REGEX, mediumInvalid)
      .optional(),
    discord: YUP.string()
      .transform(removeEmptyString)
      .url(invalidUrl)
      .matches(regExp.DISCORD_REG_EXP, discordInvalid)
      .optional(),
    twitter: YUP.string()
      .transform(removeEmptyString)
      .url(invalidUrl)
      .matches(regExp.TWITTER_COMBINED_REGEX, twitterInvalid)
      .optional(),
    near_social: YUP.string()
      .transform(removeEmptyString)
      .url(invalidUrl)
      .matches(regExp.NEAR_SOCIAL_REG_EXP, nearSocialInvalid)
      .optional(),
  });

export const getPitchSettingsValidationSchema = () =>
  YUP.object({
    name: YUP.string().required(requiredField('Pitch name')),
    speaker_name: YUP.string().required(requiredField('Speaker name')),
    starts_on: YUP.number()
      .required(requiredField('Starts date'))
      .typeError(incorrectDate),
    duration: YUP.number()
      .min(MIN_DURATION, `Pitch duration is too short, min: ${MIN_DURATION}`)
      .max(MAX_DURATION, `Pitch duration is too long, max: ${MAX_DURATION}`)
      .required(requiredField('Pitch duration')),
    video_url: YUP.string()
      .url(invalidUrl)
      .startWithHTTP()
      .required(requiredField('Video url')),
  });

export const getDocumentsValidationSchemaForSubmission = () => YUP.string();

export const getDocumentsValidationSchema = () =>
  YUP.string().startWithHTTP().url(invalidUrl);

// TODO: Reuse in project edit page
export const getFinDataValidationSchema = (pitchEndDate: number) =>
  YUP.object({
    finData: YUP.object({
      ft_token_id: YUP.string(),
      investment_min: YUP.number()
        .min(0, incorrectAmount)
        .required(requiredField())
        .typeError(incorrectAmount),
      investment_max: YUP.number().when('investment_min', (investMin) =>
        YUP.number()
          .moreThan(investMin, incorrectAmount)
          .required(requiredField())
          .typeError(incorrectAmount)
      ),
      total_investments_limit: YUP.number().when(
        'investment_max',
        (investMax) =>
          YUP.number()
            .moreThan(investMax, incorrectAmount)
            .required(requiredField())
            .typeError(incorrectAmount)
      ),
      investment_end_date: YUP.number()
        .min(
          pitchEndDate + ONE_SECOND,
          'validation.finSettings.investEndData.min'
        )
        .required(requiredField())
        .typeError(incorrectDate),
    }),
    vesting: YUP.object().when('finData.investment_end_date', (investEndDate) =>
      YUP.object({
        vestingType: YUP.string().required(requiredField()),
      }).when('vesting.vestingType', (vestingType) =>
        YUP.object({
          price: YUP.number()
            .positive(incorrectAmount)
            .moreThan(0, incorrectAmount)
            .required(requiredField())
            .typeError(incorrectDate),
          start_date: YUP.number()
            .min(
              investEndDate + ONE_SECOND,
              'validation.finSettings.vestingStart.min'
            )
            .required(requiredField())
            .typeError(incorrectDate),
          ft_token_id: YUP.string(),
          [BenefitPlanTypes.Linear]:
            vestingType !== BenefitPlanTypes.Linear
              ? YUP.object()
              : YUP.object().when('start_date', (vestingStart) =>
                  YUP.object({
                    endDate: YUP.number()
                      .min(vestingStart + ONE_SECOND, incorrectDate)
                      .required(requiredField())
                      .typeError(incorrectDate),
                  })
                ),
          [BenefitPlanTypes.NonLinear]:
            vestingType !== BenefitPlanTypes.NonLinear
              ? YUP.array()
              : YUP.array().when('start_date', (vestingStart) =>
                  YUP.array().of(
                    YUP.object({
                      endDate: YUP.number()
                        .required(requiredField())
                        .typeError(incorrectDate),
                      percentage: YUP.number().required(requiredField()),
                    }).test(
                      'validate endDate and percentage',
                      ({ endDate, percentage }, context) => {
                        const ind = (
                          context as YUP.TestContext & {
                            options: { index: number };
                          }
                        ).options.index;
                        const arr = context.parent;
                        const now = Date.now();
                        if (ind === 0) return true;

                        const prevDate =
                          ind === 1 ? vestingStart : arr[ind - 1]?.endDate;
                        const nextDate = arr[ind + 1]?.endDate || undefined;
                        const isDateInValid =
                          !endDate ||
                          (nextDate && endDate >= nextDate) ||
                          prevDate >= endDate ||
                          now >= endDate ||
                          !endDate;

                        const prevPercent = arr[ind - 1]?.percentage || 0;
                        const nextPercent = arr[ind + 1]?.percentage || 100;

                        const isPercentInValid =
                          !percentage ||
                          percentage > nextPercent ||
                          (ind !== 1 && prevPercent >= percentage) ||
                          (ind === arr.length - 1 && percentage !== 100);

                        const errors: YUP.ValidationError[] = [];
                        if (isDateInValid) {
                          errors.push(
                            new YUP.ValidationError(
                              incorrectDate,
                              '',
                              `vesting.NonLinear.[${ind}].endDate`
                            )
                          );
                        }
                        if (isPercentInValid) {
                          errors.push(
                            new YUP.ValidationError(
                              incorrectAmount,
                              '',
                              `vesting.NonLinear.[${ind}].percentage`
                            )
                          );
                        }

                        return context.createError({ message: () => errors });
                      }
                    )
                  )
                ),
        })
      )
    ),
  });

export const getInstitutionalsValidationSchema = () =>
  YUP.object().shape({
    account_id: YUP.string().required(requiredField('Wallet address')),
    name: YUP.string().required(requiredField('Fund name')),
    logo: YUP.string().required(requiredField('Logo')),
    web_url: YUP.string()
      .url(invalidUrl)
      .startWithHTTP()
      .required(requiredField('Web url')),
    participants: YUP.array().of(YUP.string().required('Error')),
  });

const getMaxProject = (event?: IEvent) => {
  if (event) {
    return isEventStarted(event)
      ? event.participants_limit || 1
      : event.participants_limit || 1;
  }
  return 1;
};

export const getEventValidationSchema = (event?: IEvent) => void {};
// YUP.object({
//   name: YUP.string().required(requiredField('Project name')),

//   description: YUP.string().max(
//     MAX_DESCRIPTION_LENGTH,
//     'Description is too long'
//   ),
//   logo: YUP.string(),
//   banner: YUP.string(),
//   web_url: YUP.string().startWithHTTP().url(invalidUrl),
//   video_url: YUP.string().startWithHTTP().url(invalidUrl),
//   social_links: YUP.object({
//     telegram: YUP.string()
//       .transform(removeEmptyString)
//       .url(invalidUrl)
//       .matches(regExp.TELEGRAM_REG_EXP, telegramInvalid)
//       .optional(),
//     medium: YUP.string()
//       .transform(removeEmptyString)
//       .url(invalidUrl)
//       .matches(regExp.MEDIUM_COMBINED_REGEX, mediumInvalid)
//       .optional(),
//     discord: YUP.string()
//       .transform(removeEmptyString)
//       .url(invalidUrl)
//       .matches(regExp.DISCORD_REG_EXP, discordInvalid)
//       .optional(),
//     twitter: YUP.string()
//       .transform(removeEmptyString)
//       .url(invalidUrl)
//       .matches(regExp.TWITTER_COMBINED_REGEX, twitterInvalid)
//       .optional(),
//     near_social: YUP.string()
//       .transform(removeEmptyString)
//       .url(invalidUrl)
//       .matches(regExp.NEAR_SOCIAL_REG_EXP, nearSocialInvalid)
//       .optional(),
//   }),

//   start_date: isEventStarted(event)
//     ? YUP.number()
//     : YUP.number()
//         .required(requiredField('Event start date'))
//         .min(Date.now() + ONE_SECOND, incorrectDate)
//         .typeError(incorrectDate),
//   end_date: isEventEnded(event)
//     ? YUP.number()
//     : YUP.number().when('start_date', (start_date) =>
//         YUP.number()
//           .required(requiredField('Event end date'))
//           .min(
//             start_date + ONE_SECOND,
//             'Event end date should be latter than event start date'
//           )
//           .typeError(incorrectDate)
//       ),
//   vote_end_date: isEventVoteEnded(event)
//     ? YUP.number()
//     : YUP.number().when(['end_date'], (end_date) =>
//         YUP.number()
//           .required(requiredField('Vote end date'))
//           .min(
//             end_date + ONE_SECOND,
//             'Vote end should be latter than event end date'
//           )
//           .typeError(incorrectDate)
//       ),

//   max_project_count: YUP.number()
//     .min(
//       getMaxProject(event),
//       `Max project count must be greater than or equal ${getMaxProject(
//         event
//       )}`
//     )
//     .nullable(true)
//     .typeError(incorrectAmount),
//   vote_limit: YUP.number()
//     .min(
//       event && isEventStarted(event) ? event.votes_limit || 1 : 1,
//       `Vote limit must be greater than or equal ${event?.votes_limit || 1}`
//     )
//     .typeError(incorrectAmount),

//   tags: YUP.array(YUP.string()).min(1, 'At least 1 tag should be settled'),
// }
// );

export const getEventScheduleCheckpointValidationSchema = (prevEndDate: Date) =>
  YUP.object({
    start_date: YUP.date()
      .min(prevEndDate, 'Incorrect date, please check previous date')
      .typeError('Incorrect date value')
      .required('Required start date'),
    title: YUP.string().required('Required title'),
    duration: YUP.number()
      .min(MIN_DURATION, `Stage duration is too short, min: ${MIN_DURATION}`)
      .max(MAX_DURATION, `Stage duration is too long, max: ${MAX_DURATION}`)
      .required('Required stage duration'),
    description: YUP.string(),
    video_url: YUP.string().startWithHTTP().url(invalidUrl).nullable(),
  });

export const getPartnerValidationSchema = () =>
  YUP.object({
    name: YUP.string().required(requiredField('Partner name')),
    image: YUP.string(),
    url: YUP.string().startWithHTTP().url(invalidUrl),
  });

export const getPitchTalkMetaDataValidationSchema = () =>
  YUP.object({
    hackers: YUP.number().min(0, incorrectAmount).typeError(incorrectAmount),
    investors: YUP.number().min(0, incorrectAmount).typeError(incorrectAmount),
    funded: YUP.number().min(0, incorrectAmount).typeError(incorrectAmount),
  });

export const getHackathonTasksValidationSchema = () =>
  YUP.array().of(
    YUP.object({
      title: YUP.string().required(requiredField('Title')),
      description: YUP.string(),
      url: YUP.string().startWithHTTP().url(invalidUrl),
      logo: YUP.string(),
      partner: YUP.string(),
    })
  );

export const getHackathonPrizeValidationSchema = () =>
  YUP.array().of(
    YUP.object({
      position: YUP.string().required(requiredField('Position')),
      amount: YUP.string().nullable(),
      description: YUP.string().nullable(),
    })
  );
