import { FormikProps } from 'formik';
import { getSocialMediaBlockSettings } from 'shared/components/GeneralSettings/blocks.constants';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';

import { EventSettings } from '../helpers';

interface ISocialMediaSettings {
  formik: FormikProps<EventSettings>;
  isReadOnly: boolean;
}

export const SocialMediaSettings: React.FC<ISocialMediaSettings> = ({
  formik,
  isReadOnly,
}) => (
  <SettingsBox
    legend="Event Social Media"
    sx={{
      padding: '10px',
      width: '400px',
      marginBottom: '20px',
      '.MuiFormGroup-root': { paddingBlock: '5px' },
    }}
  >
    {getSocialMediaBlockSettings().map(({ label, name }, ind) => (
      <SettingsInput
        key={ind}
        inputLabel={label}
        name={name}
        value={formik.values.social_links?.[name]}
        onChange={(e) =>
          formik.setFieldValue(`social_links.${name}`, e.target.value)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!formik.errors?.social_links?.[name]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        helperText={formik.errors?.social_links?.[name]}
        InputProps={{ readOnly: isReadOnly }}
      />
    ))}
  </SettingsBox>
);
