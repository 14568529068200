import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { APP_ROUTES, PARTNER_ID } from 'routes/routes';
import { NavLink, useLocation, useParams } from 'services/router';
import RefreshButton from 'shared/components/RefreshButton';
import { getLinkStyles } from 'shared/utils/themeUtils';
import { getPartners } from 'store/thunks/metaData';

const METADATA_NAVBAR_LABELS = {
  [APP_ROUTES.METADATA]: 'PitchTalk MetaData',
  [APP_ROUTES.PARTNERS]: 'Partners',
};

const PARTNERS_NAVBAR_LABELS = {
  [APP_ROUTES.PARTNERS]: 'List of partners',
  [APP_ROUTES.PARTNERS_NEW]: 'Add new partners',
  [APP_ROUTES.PARTNERS_BY_ID]: 'View/Edit partners',
};

const SubNavBar: React.FC<{ partnerId: string; isEditPage: boolean }> = ({
  partnerId,
  isEditPage,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }} component="nav">
    <List
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        '& a': {
          fontFamily: 'Everett-Regular',
          fontSize: '1.125rem',
          color: 'black',
        },
        '& li:first-of-type': { paddingLeft: 0 },
      }}
    >
      <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
        <NavLink end to={APP_ROUTES.PARTNERS} style={getLinkStyles}>
          {PARTNERS_NAVBAR_LABELS[APP_ROUTES.PARTNERS]}
        </NavLink>
      </ListItem>
      <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
        <NavLink end to={APP_ROUTES.PARTNERS_NEW} style={getLinkStyles}>
          {PARTNERS_NAVBAR_LABELS[APP_ROUTES.PARTNERS_NEW]}
        </NavLink>
      </ListItem>
      {isEditPage && partnerId && (
        <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
          <NavLink
            end
            to={APP_ROUTES.PARTNERS_BY_ID.replace(PARTNER_ID, partnerId)}
            style={() => getLinkStyles({ isActive: true })}
          >
            {PARTNERS_NAVBAR_LABELS[APP_ROUTES.PARTNERS_BY_ID]}
          </NavLink>
        </ListItem>
      )}
    </List>
    {location.pathname === APP_ROUTES.PARTNERS && (
      <RefreshButton updateThunk={getPartners as any} />
    )}
  </Box>
);

const withNavbar =
  <T,>(Component: React.FC<T>) =>
  (props: JSX.IntrinsicAttributes & T) => {
    const { partnerId } = useParams();
    const location = useLocation();
    const isEditPage = !location.pathname.includes('new');
    const isPartnersPage = location.pathname.includes('partners');

    return (
      <Box sx={{ marginInline: '20px' }}>
        <Box>
          <List
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              '& a': {
                fontFamily: 'Everett-Regular',
                fontSize: '1.125rem',
                color: 'black',
              },
              '& li:first-of-type': { paddingLeft: 0 },
            }}
          >
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink end to={APP_ROUTES.METADATA} style={getLinkStyles}>
                {METADATA_NAVBAR_LABELS[APP_ROUTES.METADATA]}
              </NavLink>
            </ListItem>
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink
                end
                to={APP_ROUTES.PARTNERS}
                style={({ isActive }) =>
                  getLinkStyles({
                    isActive: isActive || isPartnersPage,
                  })
                }
              >
                {METADATA_NAVBAR_LABELS[APP_ROUTES.PARTNERS]}
              </NavLink>
            </ListItem>
          </List>
          {isPartnersPage && (
            <SubNavBar partnerId={partnerId || ''} isEditPage={isEditPage} />
          )}
          <Component {...props} />
        </Box>
      </Box>
    );
  };

export default withNavbar;
