/* eslint-disable no-console */
import { compose } from '@reduxjs/toolkit';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import SubService from 'services/api-contract/SubmissionServiceAPI';
import { setSubmissionMetadata } from 'store/slices/submission';
import { selectIsAdmin, selectIsOwner } from 'store/slices/user';
import { AppDispatch, RootState } from 'store/store';

import { fetchFunds } from '../funds';
import { getPartners, getPitchTalkMetaData } from '../metaData';
import { getOnChainProjects, getOffChainProjects } from '../projects';
import { fetchSubmissionFunds } from '../subFunds';
import { getSubProjects, getSubProjectsAuthors } from '../subProjects';
import { getTags } from '../tags';
import { fetchTokenPrices } from '../tokenPrices';
import { fetchTokens } from '../tokens';

export const initApplication =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const isAdmin = selectIsAdmin(getState());
    const isOwner = selectIsOwner(getState());

    if (isAdmin || isOwner) {
      // dispatch(getOnChainProjects());
      dispatch(getOffChainProjects());
      dispatch(fetchTokens());
      dispatch(fetchTokenPrices());
      // dispatch(fetchFunds());
      dispatch(getTags());
      dispatch(getPartners());
      dispatch(getPitchTalkMetaData());
      // dispatch(fetchSubmissionFunds());
      // dispatch(getSubMeta());
      // dispatch(getSubProjects())
      //   .then(() => dispatch(getSubProjectsAuthors()))
      //   .catch(console.error);
    }
  };

// TODO: Move admins to redux
export const fetchAdmins = () => () =>
  Promise.all([PTService.getOwnerAccount(), PTService.getWhiteListAccounts()])
    .then(([ownerId, admins]) => ({ ownerId, admins }))
    .catch((e) => {
      console.error(e);
      return { ownerId: null, admins: [] as string[] };
    });

// TODO: Move admins to redux
export const fetchSubAdmins = () => () =>
  Promise.all([SubService.getOwnerAccount(), SubService.getWhiteListAccounts()])
    .then(([ownerId, admins]) => ({ ownerId, admins }))
    .catch((e) => {
      console.error(e);
      return { ownerId: null, admins: [] as string[] };
    });

export const getSubMeta = () => (dispatch: AppDispatch) =>
  SubService.getMetaData()
    .then(compose(dispatch, setSubmissionMetadata))
    .catch((e) => console.error(e));
