import axios from 'axios';
import EStorageKeys from 'services/constants/localStorageKeys';
import * as LocalStorage from 'services/localStorage';

import { BASE_URL } from './config';
import { refreshToken } from '../api-endpoints/authAPI';

export const fetchAPI = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-type': 'application/json' },
});

fetchAPI.interceptors.request.use(
  async (config) => {
    const token = LocalStorage.getItem(EStorageKeys.ACCESS_TOKEN);
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

fetchAPI.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const resp = await refreshToken();
      const accessToken = resp.response.accessToken;
      LocalStorage.setItem(EStorageKeys.ACCESS_TOKEN, accessToken);

      fetchAPI.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
      return fetchAPI(originalRequest);
    }
    return Promise.reject(error);
  }
);
