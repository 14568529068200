import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  EHackathonTaskType,
  IHackakathonTask,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { FormikProps, useFormik } from 'formik';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { getHackathonTasksValidationSchema } from 'shared/utils/validationsUtils';
import { updateHackathonTasks } from 'store/thunks/events';

import { TaskDescription } from './TaskDescription';
import { TaskLogo } from './TaskLogo';
import { TaskPrizeType } from './TaskPrizeType';

interface ITasksProps {
  offChainEventId: string;
  tasks: IHackakathonTask[];
  isEventVoteEnded: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TASKS_LIMIT = 10;

export type IHackakathonTaskState = Omit<
  IHackakathonTask,
  'partner' | 'created_at'
> & { partner: string };

const getNewTask = (): IHackakathonTaskState => ({
  id: crypto.randomUUID(),

  title: 'Task title',
  description: 'Task description',
  url: '',
  logo: '',
  type: EHackathonTaskType.Default,
  partner: '',

  position: 0,
});

const getTasksState = (tasks: IHackakathonTask[]): IHackakathonTaskState[] =>
  tasks?.length
    ? [...tasks]
        .map((task) => ({ ...task, partner: task.partner?.id || '' }))
        .sort((a, b) => a.position - b.position)
    : [];

const getTasksForSaving = (tasks: IHackakathonTaskState[]) =>
  tasks.map((task, i) => ({
    ...task,
    partner:
      task.type === EHackathonTaskType.Partner
        ? task.partner || null
        : undefined,
    position: i,
  }));

export const Tasks: React.FC<ITasksProps> = ({
  offChainEventId,
  tasks,
  isEventVoteEnded,
  setIsLoading,
}) => {
  const dispatch = useAppDispatch();
  const formik = useFormik<IHackakathonTaskState[]>({
    initialValues: getTasksState(tasks),
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setIsLoading(true);
      dispatch(
        updateHackathonTasks(
          offChainEventId,
          getTasksForSaving(values) as IHackakathonTaskState[]
        )
      ).finally(() => {
        setIsLoading(false);
        formikHelpers.setSubmitting(false);
      });
    },
    enableReinitialize: true,
    validationSchema: getHackathonTasksValidationSchema(),
  });

  const addNewTask = () => formik.setValues((prev) => [...prev, getNewTask()]);

  const removeTask = (id: string) =>
    formik.setValues((prev) => prev.filter((prize) => prize.id !== id));

  return (
    <Box>
      <SettingsBox
        legend="Hackathon tasks"
        sx={{
          padding: '10px',
          width: '600px',
          height: 'fit-content',
          '.MuiFormGroup-root': { paddingBlock: '5px' },
        }}
      >
        {!formik.values.length && (
          <Typography sx={{ textAlign: 'center', mb: 1 }} variant="h6">
            There are no tasks
          </Typography>
        )}
        {formik.values.map((task, i) => (
          <Box
            sx={{ border: '1px solid black', padding: '5px', mb: 2 }}
            key={i + task.id}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>{task.id}</Typography>
              {!isEventVoteEnded && (
                <IconButton
                  sx={{ width: '15px', height: '15px' }}
                  onClick={() => removeTask(task.id)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box>
              <SettingsInput
                inputLabel="Task title"
                name="task_title"
                value={task.title}
                onChange={(e) =>
                  formik.setFieldValue(`${i}.title`, e.target.value)
                }
                error={!!formik.errors?.[i]?.title}
                helperText={formik.errors && formik.errors?.[i]?.title}
                InputProps={{ readOnly: isEventVoteEnded }}
              />
              <TaskDescription
                i={i}
                formik={
                  formik as unknown as FormikProps<IHackakathonTaskState[]>
                }
                isEventVoteEnded={isEventVoteEnded}
              />
              <SettingsInput
                inputLabel="Task url"
                name="task_url"
                value={task.url}
                onChange={(e) =>
                  formik.setFieldValue(`${i}.url`, e.target.value)
                }
                error={!!formik.errors?.[i]?.url}
                helperText={formik.errors && formik.errors?.[i]?.url}
                InputProps={{ readOnly: isEventVoteEnded }}
              />
              <TaskLogo
                i={i}
                formik={
                  formik as unknown as FormikProps<IHackakathonTaskState[]>
                }
                isEventVoteEnded={isEventVoteEnded}
              />
              <TaskPrizeType
                i={i}
                formik={
                  formik as unknown as FormikProps<IHackakathonTaskState[]>
                }
                isEventVoteEnded={isEventVoteEnded}
              />
            </Box>
          </Box>
        ))}
        {formik.values.length < TASKS_LIMIT && (
          <PrimaryButton
            disabled={formik.isSubmitting || isEventVoteEnded}
            sx={{ mb: 2 }}
            onClick={addNewTask}
          >
            Add
          </PrimaryButton>
        )}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <PrimaryButton
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              formik.isSubmitting ||
              isEventVoteEnded
            }
            sx={{ flex: 1 }}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </PrimaryButton>
          <PrimaryButton
            disabled={!formik.dirty || formik.isSubmitting || isEventVoteEnded}
            sx={{ flex: 1 }}
            onClick={formik.handleReset}
          >
            Reset
          </PrimaryButton>
        </Box>
      </SettingsBox>
    </Box>
  );
};
