import { EProjectType } from '@pitchtalk/contract-api-js/dist/interfaces';
import isNull from 'lodash/isNull';
import PitchTalkServiceAPI from 'services/api-contract/PitchTalkServiceAPI';
import WalletService from 'services/api-contract/WalletService';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { useParams } from 'services/router';
import { selectEventById } from 'store/selectors/events';
import { addEventProject, addEventReferee } from 'store/thunks/events';

export const useControls = () => {
  const dispatch = useAppDispatch();
  const { eventId = '' } = useParams();
  const event = useAppSelector((state) =>
    selectEventById(state, eventId)
  );

  const addProject = async (projectId: string, projectType: EProjectType) => {
    await dispatch(addEventProject(eventId, projectId, projectType));
    console.log('addProject')
    // const tx = PitchTalkServiceAPI.addEventProject(
    //   eventId,
    //   projectId,
    //   projectType
    // );
    // await WalletService.signTransactions(tx);
  };
  const removeProject = async (projectId: string, receiverId: string) => {
    console.log('removeProject')
    // const tx = PitchTalkServiceAPI.removeEventProject(
    //   eventId,
    //   projectId,
    //   isNull(event?.fee) || !receiverId ? undefined : receiverId
    // );
    // await WalletService.signTransactions(tx);
  };

  const addReferee = async (accountId: string) => {
    await dispatch(addEventReferee(eventId, accountId));

    // const tx = PitchTalkServiceAPI.addEventJudge(eventId, accountId);
    // await WalletService.signTransactions(tx);
  };
  const removeReferee = async (accountId: string) => {
    // const tx = PitchTalkServiceAPI.removeEventJudge(eventId, accountId);
    // await WalletService.signTransactions(tx);
  };

  return {
    addProject,
    removeProject,
    addReferee,
    removeReferee,
  };
};
