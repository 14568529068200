import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { millisecondsToMinutes, minutesToMilliseconds } from 'date-fns';
import format from 'date-fns/format';
import { useFormik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import { fetcher } from 'services/api/utils';
import { useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { Loader, LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { DEFAULT_DATE_FORMAT } from 'shared/constants';
import {
  IEvent,
  IEventProjectParticipation,
  IEventSchedule,
} from 'shared/interfaces/backendInterfaces';
import { isEventVoteEnded as checkIsEventVoteEnded } from 'shared/utils/eventsUtils';
import { getEventScheduleCheckpointValidationSchema } from 'shared/utils/validationsUtils';
import useSWR, { useSWRConfig } from 'swr';
import { ValidationError } from 'yup';

import { saveEventCheckpoints } from './api';
import { ScheduleCheckPoint } from './components/ScheduleCheckPoint';
import { EventEndedNotification } from '../components/EventEndedNotification';

const emptyCheckpoint: IEventSchedule = {
  id: crypto.randomUUID(),
  title: '',
  description: '',
  start_date: new Date(),
  duration: millisecondsToMinutes(10 * 60 * 1000),
  project_id: null,
  video_url: null,
};

export const validateSchema = (
  values: Record<string, IEventSchedule>,
  startDate?: Date
) =>
  Object.entries(values).reduce<Record<string, FormikErrors<IEventSchedule>>>(
    (acc, [id, checkpoint], ind, arr) => {
      const now = Date.now();
      try {
        const prevDate = arr?.[ind - 1]?.[1]?.start_date
          ?.setSeconds(0, 0)
          .valueOf();

        const prevEnd =
          prevDate +
            minutesToMilliseconds(arr?.[ind - 1]?.[1]?.duration || 0) ||
          new Date(startDate || Date.now()).valueOf() ||
          now;

        getEventScheduleCheckpointValidationSchema(
          new Date(prevEnd)
        ).validateSync(checkpoint, { abortEarly: false });
      } catch (errors: unknown) {
        (errors as ValidationError)?.inner?.forEach((validationError) => {
          acc[id] = {
            ...acc[id],
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [validationError.path!]: validationError.message,
          };
        });
      }
      return acc;
    },
    {}
  );

const getCorrectSchedule = (
  schedule?: IEventSchedule[]
): Record<string, IEventSchedule> => {
  if (!schedule?.length) return {};
  try {
    return schedule.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          ...item,
          duration: millisecondsToMinutes(item.duration),
        },
      }),
      {}
    );
  } catch (error) {
    return {};
  }
};

export const EventSchedule: React.FC = () => {
  const { eventId = '' } = useParams();

  const { mutate } = useSWRConfig();

  const { data: event, isLoading: isEventLoading } = useSWR<IEvent>(
    eventId ? `event/${eventId}` : null,
    fetcher
  );

  const { data: eventSchedule, isLoading: isEventScheduleLoading } = useSWR<
    IEventSchedule[]
  >(eventId ? `event/${eventId}/schedule` : null, fetcher);

  const { data: eventProjects, isLoading: isEventProjectsLoading } = useSWR<
    IEventProjectParticipation[]
  >(eventId ? `event/${eventId}/participants` : null, fetcher);

  const formik = useFormik<Record<string, IEventSchedule>>({
    initialValues: getCorrectSchedule(eventSchedule),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const schedule: IEventSchedule[] = Object.values(values).map((item) => ({
        ...item,
        id: undefined,
        start_date: new Date(
          new Date(item.start_date).setSeconds(0, 0).valueOf()
        ),
        duration: minutesToMilliseconds(item.duration),
      }));

      await saveEventCheckpoints(eventId, schedule).finally(() => {
        helpers.setSubmitting(false);
        if (eventId) mutate(`event/${eventId}/schedule`);
      });
    },
    onReset: (_, formikHelpers) =>
      formikHelpers.setValues(getCorrectSchedule(eventSchedule), false),
    validate: (values) => validateSchema(values, event?.start_date),
    enableReinitialize: true,
  });

  const addCheckpoint = () => {
    const lastInd = formik.values ? Object.keys(formik.values).length - 1 : 0;
    const previous = formik.values && Object.values(formik?.values)[lastInd];

    const checkPoint = { ...emptyCheckpoint };

    if (previous) {
      checkPoint.start_date = new Date(
        new Date(previous.start_date).valueOf() +
          minutesToMilliseconds(previous.duration)
      );
    }

    return formik.setValues((p) => ({
      ...p,
      [crypto.randomUUID()]: checkPoint,
    }));
  };

  const removeCheckpoint = (id: string) =>
    formik.setValues(omit(formik.values, id));

  if (isEventLoading || isEventScheduleLoading || isEventProjectsLoading)
    return <Loader />;

  if (!event) return <Typography variant="body1">Cannot find event</Typography>;
  if (!eventSchedule)
    return <Typography variant="body1">Cannot find event schedule</Typography>;

  const participantsIds = Object.values(formik.values)
    .map((item) => item.project_id)
    .filter((id) => !isUndefined(id)) as (string | number)[];

  const isEventVoteEnded = checkIsEventVoteEnded(event as IEvent);

  const isReadOnlyEvent = !!(isEventVoteEnded || event?.authorId);

  return (
    <Box>
      <EventEndedNotification event={event} />
      <Box sx={{ width: '800px', position: 'relative' }}>
        {formik.isSubmitting && <LoaderWithOverlay />}
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography>
              <b>Event start date </b>
            </Typography>
            <Typography>
              {format(new Date(event.start_date), DEFAULT_DATE_FORMAT)}
            </Typography>
          </Box>
          <Box
            sx={{
              maxHeight: '475px',
              overflowY: 'auto',
              paddingInline: '10px',
            }}
          >
            {Object.keys(formik.values).map((id, ind) => (
              <ScheduleCheckPoint
                id={id}
                ind={ind}
                key={id}
                formik={formik}
                startDate={event.start_date}
                endDate={event.end_date}
                removeCheckpoint={removeCheckpoint}
                eventProjects={eventProjects}
                participantsIds={participantsIds}
                isReadOnlyEvent={isReadOnlyEvent}
              />
            ))}
            {!isEventVoteEnded && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBlock: '10px',
                }}
              >
                <PrimaryButton
                  sx={{ width: '70%', height: '32px' }}
                  variant="contained"
                  disabled={formik.isSubmitting}
                  onClick={addCheckpoint}
                >
                  Add Checkpoint
                </PrimaryButton>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography>
              <b>Event end date</b>{' '}
            </Typography>
            <Typography>
              {format(new Date(event.end_date), DEFAULT_DATE_FORMAT)}
            </Typography>
          </Box>
        </Box>
        {!isReadOnlyEvent && (
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <PrimaryButton
              sx={{ width: '100%', height: '32px' }}
              variant="contained"
              disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </PrimaryButton>
            <PrimaryButton
              sx={{ width: '100%', height: '32px' }}
              variant="contained"
              disabled={formik.isSubmitting || !formik.dirty}
              type="reset"
              onClick={(e) => formik.handleReset(e)}
            >
              Cancel
            </PrimaryButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
