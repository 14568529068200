export interface IComment {
  id: string;

  comment: string;

  created_at: Date;

  user: string | IUser;
  event: string | IEvent;
}

// src\modules\event\hackathon-settings\interfaces\IHackathonSettings.interface.ts
export interface IHackathonSettings {
  id: string;

  event: string | IEvent;
  tasks: string[] | IEventTask[];
  prize_details: string[] | IPrizeDetails[];
}

// src\modules\event\hackathon-settings\prize-details\interfaces\IPrizeDetails.interface.ts
export interface IPrizeDetails {
  id: string;

  position: string;
  amount: string;

  description: string | null;

  order: number;

  hackathon_settings: string | IHackathonSettings;
}

// src\modules\event\hackathon-settings\tasks\interfaces\IEventTask.interface.ts
export interface IEventTask {
  id: string;

  title: string;
  description: string;

  url: string;
  logo: string;

  type: EEventTaskType;

  position: number;

  created_at: Date;

  partner: string | IPartner | null;
  hackathon_settings: string | IHackathonSettings;
}

export enum EEventAuthor {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum EEventAccess {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export interface IEventPrize {
  id: string;
  position: number;
  order: number;
  amount: string | null;
  description: string | null;
}

// src\modules\event\interfaces\IEvent.interface.ts
export interface IEvent {
  slug: string;

  is_public: boolean;
  publicRequest: boolean;

  location?: string;
  communicationChannel?: string;

  // payment_type: EEventPaymentType;

  eventTag: string;
  tags: string[] | ITag[];

  eventNews: boolean;

  participants_limit?: number | null;

  prizes: IEventPrize[];

  authorId: string | null;
  author?: IUser;

  id: string;
  on_chain_id?: number;

  is_active: boolean;

  name: string;
  logo: string;
  banner: string;
  web_url: string;
  partners: string;
  video_url: string;
  description: string;
  short_description: string;

  prize_pool: string;

  votes_limit?: number;

  type: EEventType;

  end_date: Date;
  start_date: Date;
  vote_end_date: Date;

  created_at: Date;
  updated_at: Date;

  projects: string[] | IProject[];

  participants: string[] | IEventProjectParticipation[];
  referees: string[] | IRefereeParticipation[];
  hackathon_settings: string | IHackathonSettings | null;
  social_links: string | IEventSocialMedia | null;
  likes: string[] | ILike[] | null;
  comments: string[] | IComment[] | null;
}

// src\modules\event\interfaces\IEventFindOptions.interface.ts
export interface IEventFindOptions {
  withParticipants?: boolean;
  withReferees?: boolean;
}

// src\modules\event\interfaces\IHasEvent.interface.ts
export interface IHasEvent {
  [key: number]: IHasEventItem;
}

// src\modules\event\interfaces\IHasEvent.interface.ts
export interface IHasEventItem {
  isActive: boolean;
  isExist: boolean;
}

// src\modules\event\interfaces\ILastEvents.interface.ts
export interface ILastEvents {
  [EEventType.Lecture]: ILastEventItem[];
  [EEventType.Hackathon]: ILastEventItem[];
  [EEventType.Tournament]: ILastEventItem[];
}

// src\modules\event\interfaces\ILastEvents.interface.ts
export interface ILastEventItem {
  id: string;
  on_chain_id?: number;

  name: string;
  logo: string;
  banner: string;
  prize_pool: string;

  referees_count: number;
  participants_count: number;
}

// src\modules\event\interfaces\ILiveEvent.interface.ts
export interface ILiveEvent {
  id: string;
  on_chain_id?: number;

  name: string;
  logo: string;
  banner: string;
  short_description: string;

  status: ELiveEventStatus;

  video_url?: string;
  end_date: Date;
  start_date: Date;
  vote_end_date: Date;
}

// src\modules\event\project-participation\interfaces\IEventProjectParticipation.interface.ts
export interface IEventProjectParticipation {
  id: string;
  project_id: string;
  eventId: string;
  status: EEventSubmissionStatus;
  project_type: string;
  video_url: string;
  repo_url: string;
  created_at: Date;
  updated_at: Date;
  participant: {
    id: string;
    name: string;
    description: string;
    authorId: string;
    banner: string;
    logo: string;
    created_at: Date;
    updated_at: Date;
    changes_available: boolean;
    posts_available: boolean;
    is_active: boolean;
    is_submission: boolean;
    project_url: string;
    slug: string;
    userId: string;
    walletId: string;
    likes: number;
    liked: boolean;
  };
  // votes?: IRefereeVote[];
  totalVotes: number;
  votes?: { logo: string; amount: number }[];
  event: IEvent;
}

// src\modules\event\project-participation\interfaces\IParticipationItem.interface.ts
export interface IParticipationItem {
  id: string;
  project_id: string;

  status: EEventSubmissionStatus;
  project_type: EProjectTypes;

  totalVotes: number;
  video_url: string;
  repo_url: string;
  likes: string[];

  event: string;
}

// src\modules\event\referee-participation\interfaces\IRefereeParticipation.interface.ts
export interface IRefereeParticipation {
  id: string;
  account_id: string;

  status: EEventSubmissionStatus;

  created_at: Date;
  updated_at: Date;

  votesGiven: number;
  votes?: IRefereeVote[];

  userId: string;
  eventId: string;
  event: string | IEvent;
}

// src\modules\event\referee-participation\interfaces\ISubmissionReferee.interface.ts
export interface ISubmissionReferee {
  event_id: string;
}

// src\modules\event\refereeVote\interfaces\RefereeVote.interface.ts
export interface IRefereeVote {
  id: string;
  created_at: Date;
  updated_at: Date;
  participantId: string;
  refereeId: string;
  voteScore: number;
}

// src\modules\event\schedule\interfaces\ISchedule.interface.ts
export interface IEventSchedule {
  id: string;

  title: string;
  description: string;
  video_url: string | null;

  start_date: Date;
  duration: number;

  project_id: string | null;

  event?: string | IEvent;
}

// src\modules\event\social-media\interfaces\IEventSocialMedia.interface.ts
export interface IEventSocialMedia {
  id: string;

  medium: string;
  github: string;
  discord: string;
  twitter: string;
  telegram: string;
  linkedin: string;
  near_social: string;

  event: string | IEvent;
}

// src\modules\fund\fundMemberProfile\interfaces\FundMemberProfile.interface.ts
export interface IFundMemberProfile {
  id: string;
  created_at: Date;
  updated_at: Date;

  userId: string;
  fundId: string;
  role: EFundRoles;
}

// src\modules\fund\interfaces\Fund.interface.ts
// export interface IFund {
//   id: string;
//   created_at: Date;
//   updated_at: Date;

//   name: string;
//   logo: string;
//   web_url: string;
//   is_active: boolean;
//   fundRequests: IFundRequest[];
// }

// src\modules\fundRequest\interfaces\FundRequest.interface.ts
export interface IFundRequest {
  id: string;
  fundId: string;
  projectId: string;
  created_at: Date;
  updated_at: Date;
}

// src\modules\fundRequest\interfaces\FundRequestCreate.interface.ts
export interface IFundRequestCreate {
  project?: IProject;
  fund?: IFund;
  projectId?: string;
  fundId?: string;
}

// src\modules\likes\interfaces\ILike.interface.ts
export interface ILike {
  id: number;
  createdAt: Date;
  onChainProjectId: string;
  user: string | IUser;
  event: string | IEvent;
  project: string | IProject;
  post: string | IPost;
}

// src\modules\metadata\interfaces\IMetadata.interface.ts
export interface IMetadata {
  id: string;

  hackers: number;
  investors: number;
  funded: number;
}

// src\modules\partners\interfaces\IPartner.interface.ts
export interface IPartner {
  id: string;
  image: string;

  url: string | null;
  name: string | null;
}

// src\modules\post\interfaces\Post.interface.ts
export interface IPost {
  id: string;
  title: string;
  logo: string;
  images: string[];
  documents: string[];
  videos: string[];
  author: string;
  projectId: string;
  roadmap: string;
  likes: string[] | ILike[];
  text: string;
  type: EPostType;
  is_active: boolean;
  visible: boolean;
  created_at: Date;
  updated_at: Date;
}

// src\modules\post\interfaces\PostCreate.interface.ts
export interface IPostCreate {
  title: string;
  logo: string;
  images: string[];
  documents: string[];
  videos: string[];
  text: string;
}

// src\modules\post\interfaces\PostDeleteOneParam.interface.ts
export interface IPostDeleteOneParam {
  id: string;
}

// src\modules\post\interfaces\PostGetOneParam.interface.ts
export interface IPostGetOneParam {
  id: string;
}

// src\modules\post\interfaces\PostGetOptions.interface.ts
export interface IPostGetOptions {
  relations?: string[];
  userId?: string;
  project?: string;
}

// src\modules\post\interfaces\PostRepository.interface.ts

// src\modules\project\documents\interfaces\IDocument.interface.ts
export interface IDocument {
  id: string;
  url: string;
  doc_type: EDocType;
  project: string | IProject;
  status?: EActionStatus;
  origin?: string;
}

// src\modules\project\interfaces\IFindOneOptions.interface.ts
export interface IFindOneOptions {
  withTags?: boolean;
  withAuthor?: boolean;
  withSocials?: boolean;
  withContacts?: boolean;
  withDocuments?: boolean;
  withTeam?: boolean;
  type?: EProjectTypes;
  withComments?: boolean;
}

// src\modules\project\interfaces\IProject.interface.ts
export interface IProject {
  id: string;
  author?: string | IUser;
  user?: IUser;
  authorId?: string;
  userId?: string;
  contact_links: string | IProjectContactLinks;
  social_links: IProjectSocialMedia;
  created_at: Date;
  updated_at: Date;
  is_active: boolean;

  name: string;
  banner: string;
  description: string;
  logo: string;
  project_url: string;
  events: string[] | IEvent[];
  tags: string[] | ITag[];
  slug: string | null;
  on_chain_id: string;
  // categories: string[];

  documents: string[] | IDocument[] | null;
  team: string[] | IProjectTeamMember[] | null;
  likes: string[] | ILike[];
  changes_available: boolean;
  posts_available: boolean;
  subscriptions?: ISubscription[];
  posts: string[] | IPost[];
  fundRequests?: IFundRequest[];
  roadmap: string[] | IRoadmap[];
  // draft: string
}

// src\modules\project\interfaces\IProjectFindManyOptions.interface.ts
export interface IProjectFindManyOptions {
  type?: EProjectTypes;
  search?: string;
  ids?: string[];
  name?: string;
  limit: number;
  page: number;
}

// src\modules\project\interfaces\IProjectRoadmap.interface.ts
export interface IProjectRoadmap {
  [key: number]: { [key in EQuarter]: IRoadmap[] };
}

// src\modules\project\project-contact-links\interfaces\IProjectContactLinks.interface.ts
export interface IProjectContactLinks {
  id: string;
  telegram: string;
  email: string;
  project?: string | IProject;
  projectDraft: string | IProject | null;
}

// src\modules\project\project-draft\interfaces\IProjectDraft.interface.ts
export interface IProjectDraft {
  id: string;

  name: string;
  logo?: string;
  banner?: string;
  description: string;
  projectUrl?: string;

  user: string | IUser;
  tags: string[] | ITag[];
  socialLinks: string | IProjectSocialMedia;
  contactLinks: string | IProjectContactLinks;
}

// src\modules\project\project-social-media\interfaces\IProjectSocialMedia.interface.ts
export interface IProjectSocialMedia {
  id: string;
  telegram: string;
  medium: string;
  discord: string;
  twitter: string;
  near_social: string;
  linkedin: string;
  github: string;
  youtube: string;
  // project: string | IProject;
  // team: string | IProjectTeamMember | null;
  projectDraft: string | IProject | null;
}

// src\modules\project\roadmap\interfaces\Roadmap.interface.ts
export interface IRoadmap {
  id: string;
  project?: IProject;
  projectId: string;
  order: number;
  title: string;
  description: string;
  year: number;
  quarter: EQuarter;
  link: string;
  isDone: boolean;
  created_at: Date;
  updated_at: Date;
}

// src\modules\project\roadmap\interfaces\RoadmapCreate.interface.ts
export interface IRoadmapCreate {
  order?: number;
  title: string;
  description?: string;
  year: number;
  quarter: EQuarter;
  link?: string;
  isDone?: boolean;
  projectId?: string;
}

// src\modules\project\roadmap\interfaces\RoadmapDeleteOneParam.interface.ts
export interface IRoadmapDeleteOneParam {
  id: string;
}

// src\modules\project\roadmap\interfaces\RoadmapGetOneParam.interface.ts
export interface IRoadmapGetOneParam {
  id: string;
}

// src\modules\project\roadmap\interfaces\RoadmapGetOptions.interface.ts
export interface IRoadmapGetOptions {
  relations?: string[];
  projectId?: string;
}

// src\modules\project\roadmap\interfaces\RoadmapRepository.interface.ts
// src\modules\project\team\interfaces\IProjectTeamMember.interface.ts
export interface IProjectTeamMember {
  id: string;

  position: string;
  first_name: string;
  last_name: string;
  email: string;

  logo?: string | null;

  userId: string | null;
  projectId: string;
  social_links?: string | IProjectSocialMedia | null;
  // project: string | IProject;
}

// src\modules\subscription\interfaces\Subscription.interface.ts
export interface ISubscription {
  id: string;
  created_at: Date;
  updated_at: Date;
  userId: string;
  projectId: string;
}

// src\modules\subscription\interfaces\SubscriptionCreate.interface.ts
export interface ISubscriptionCreate {
  userId: string;
  projectId: string;
}

// src\modules\subscription\interfaces\SubscriptionDeleteOneParam.interface.ts
export interface ISubscriptionDeleteOneParam {
  id: string;
}

// src\modules\subscription\interfaces\SubscriptionGetOneParam.interface.ts
export interface ISubscriptionGetOneParam {
  id: string;
}

// src\modules\subscription\interfaces\SubscriptionGetOptions.interface.ts
export interface ISubscriptionGetOptions {
  relations?: string[];
}

// src\modules\subscription\interfaces\SubscriptionRepository.interface.ts
export interface ISubscriptionSave {
  user: IUser;
  project: IProject;
}

// src\modules\subscription\interfaces\SubscriptionUpdateOneParam.interface.ts
export interface ISubscriptionUpdateOneParam {
  id: string;
}

// src\modules\tag\interfaces\ITag.interface.ts
export interface ITag {
  id: string;

  name: string;
  slug: string;
  is_default: boolean;

  created_at: Date;
  created_by: string | null;

  projects: string[] | IProject[];
  projectsDraft: string[] | IProject[];
}

// src\modules\user\interfaces\IUser.interface.ts
export interface IUser {
  email: string;

  verified_email: boolean;
  verified_kyc: boolean;
  wallets: IWallet[];
  provider: EProviders;
  external_id: string;
  password: string;
  display_name: string;
  picture: string;
  id: string;
  created_at: Date;
  updated_at: Date;
  role: EUserRoles;
  projects?: IProject[] | string[];
  project: IProject | string;
  likes: string[] | ILike[];
  comments: string[] | IComment[] | null;
  subscriptions?: ISubscription[];
  fund_profile: IFundMemberProfile | null;
  projectDraft: string | IProjectDraft | null;
}

// src\modules\user\interfaces\IUserCreate.interface.ts
export interface IUserCreate {
  provider: EProviders;
  external_id: string;
  display_name?: string;
  picture?: string;
  email: string;
  role?: EUserRoles;
}

// src\modules\wallet\interfaces\Wallet.interface.ts
export interface IWallet {
  id: string;
  blockchain: ESupportedBlockchains;
  provider: EProviders;
  wallet: string;
  userId: string;
  projectId: string;
  connected: boolean;
  created_at: Date;
  updated_at: Date;
  isGrantConnected: boolean;
}

// src\modules\wallet\interfaces\WalletController.interface.ts
// src\modules\wallet\interfaces\WalletCreate.interface.ts
export interface IWalletCreate {
  blockchain: ESupportedBlockchains;
  provider: EProviders;
  wallet: string;
  userId: string;
  user?: IUser;
}

// src\modules\wallet\interfaces\WalletDeleteOneParam.interface.ts
export interface IWalletDeleteOneParam {
  id: string;
}

export enum EConfigEnvironment {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

// src\common\enums\EDbType.enum.ts
export enum EDbType {
  postgres = 'postgres',
}

// src\common\enums\EEventSubmissionStatus.enum.ts
export enum EEventSubmissionStatus {
  APPROVED = 'APPROVED',
  APPROVED_PENDING = 'APPROVED_PENDING',
  OPEN = 'OPEN',
  REJECTED = 'REJECTED',
  INVITED = 'INVITED',
  INVITE_REJECTED = 'INVITE_REJECTED',
}

// src\common\enums\EPaginationHeaders.enum.ts
export enum EPaginationHeaders {
  PAGE_LIMIT = 'X-PAGE-LIMIT',
  PAGE_OFFSET = 'X-PAGE-OFFSET',
  CURRENT_PAGE = 'X-CURRENT-PAGE',
  TOTAL_PAGES = 'X-TOTAL-PAGES',
  TOTAL_RECORDS = 'X-TOTAL-RECORDS',
}

// src\common\enums\EPaginationTriggerFields.enum.ts
export enum EPaginationTriggerFields {
  ITEMS = 'items',
  META = 'meta',
}

// src\common\enums\EPostType.enum.ts
export enum EPostType {
  EVENT = 'EVENT',
  PROJECT = 'PROJECT',
  PITCHTALK = 'PITCHTALK',
  USER = 'USER',
}

// src\common\enums\EProviders.enum.ts
export enum EProviders {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  EMAIL = 'email',
  LOCAL = 'local',
  METAMASK = 'metamask',
  NEAR = 'near',
  OKX = 'okx',
  SOLANA = 'solana',
}

// src\common\enums\EQuarter.enum.ts
export enum EQuarter {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

// src\common\enums\EScope.enum.ts
export enum EScope {
  API_DOC = 'API_DOC',
  DB = 'DB',
  SECURITY = 'SECURITY',
  USER = 'USER',
  GOOGLE = 'GOOGLE',
}

// src\common\enums\ESupportedBlockchain.enum.ts
export enum ESupportedBlockchains {
  NEAR = 'NEAR',
  ETHEREUM = 'ETHEREUM',
  LINEA = 'LINEA',
  SOLANA = 'SOLANA',
}

// src\common\enums\EUserRoles.enum.ts
export enum EUserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
  USER = 'USER',
}

// src\common\errors\enums\EServiceErrorStatus.enum.ts
export enum EServiceErrorStatus {
  ERR_NOT_FOUND = 'ERR_NOT_FOUND',
  ERR_CONFLICT = 'ERR_CONFLICT',
  ERR_ACCESS_FORBIDDEN = 'ERR_ACCESS_FORBIDDEN',
  ERR_BAD_INPUT_DATA = 'ERR_BAD_INPUT_DATA',
  ERR_UNKNOWN = 'ERR_UNKNOWN',
  ERR_UNPROCESSABLE_ENTITY = 'ERR_UNPROCESSABLE_ENTITY',
}

// src\modules\event\enums\EEventFilter.enum.ts
export enum EEventsFilters {
  ALL = 'ALL',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
}

// src\modules\event\enums\EEventType.enum.ts
export enum EEventType {
  Lecture = 'Lecture',
  Hackathon = 'Hackathon',
  RoundTable = 'RoundTable',
  Tournament = 'Tournament',
}

// src\modules\event\enums\ELiveEventStatus.enum.ts
export enum ELiveEventStatus {
  LIVE = 'LIVE',
  CLOSEST_PAST = 'CLOSEST_PAST',
  CLOSEST_UPCOMING = 'CLOSEST_UPCOMING',
}

// src\modules\event\hackathon-settings\tasks\enums\EEventTaskType.enum.ts
export enum EEventTaskType {
  Default = 'Default',
  Partner = 'Partner',
}

// src\modules\event\refereeVote\enums\RefereeVoteSearchDirection.enum.ts
export enum ERefereeVoteSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\event\refereeVote\enums\RefereeVoteSearchSort.enum.ts
export enum ERefereeVoteSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\fund\enums\EFundRoles.enum.ts
export enum EFundRoles {
  FUND_HEAD = 'FUND_HEAD',
  FUND_MEMBER = 'FUND_MEMBER',
}

// src\modules\fund\enums\FundSearchDirection.enum.ts
export enum EFundSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\fund\enums\FundSearchSort.enum.ts
export enum EFundSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\fund\fundMemberProfile\enums\FundMemberProfileSearchDirection.enum.ts
export enum EFundMemberProfileSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\fund\fundMemberProfile\enums\FundMemberProfileSearchSort.enum.ts
export enum EFundMemberProfileSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\fundRequest\enums\FundRequestSearchDirection.enum.ts
export enum EFundRequestSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\fundRequest\enums\FundRequestSearchSort.enum.ts
export enum EFundRequestSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\jwt\enums\EJwtType.enum.ts
export enum EJwtType {
  ACCESS = 'ACCESS',
  REFRESH = 'REFRESH',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACTIVATION = 'ACTIVATION',
}

// src\modules\post\enums\PostSearchDirection.enum.ts
export enum EPostSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\post\enums\PostSearchSort.enum.ts
export enum EPostSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\project\documents\enums\EDocType.enum.ts
export enum EDocType {
  PitchDeck = 'PitchDeck',
  TechnicalDocs = 'TechnicalDocs',
  Whitepaper = 'Whitepaper',
  Tokenomics = 'Tokenomics',

  // TODO: remove
  Other = 'Other',
  Diagram = 'Diagram',
}

// src\modules\project\enums\EActionStatus.enum.ts
export enum EActionStatus {
  New = 'New',
  Refund = 'Refund',
  Active = 'Active',
  Failed = 'Failed',
  Updated = 'Updated',
  Removed = 'Removed',
  Resetting = 'Resetting',
}

// src\modules\project\enums\EProjectTypes.enum.ts
export enum EProjectTypes {
  OFF_CHAIN = 'OffChain',
  ON_CHAIN = 'OnChain',
}

// src\modules\project\enums\ESubmissionActions.enum.ts
export enum ESubmissionActions {
  SubmissionInit = 'SubmissionInit',
  SubmissionUpdated = 'SubmissionUpdated',
  SubmissionRejected = 'SubmissionRejected',
  SubmissionApproved = 'SubmissionApproved',
  ProjectCreated = 'ProjectCreated',
  ProjectRemoved = 'ProjectRemoved',
  ProjectChanges = 'ProjectChanges',
  ProjectChangesRejected = 'ProjectChangesRejected',
  ProjectChangesApproved = 'ProjectChangesApproved',
  WithdrawRequest = 'WithdrawRequest',
  WithdrawInProgress = 'WithdrawInProgress',
  WithdrawRejected = 'WithdrawRejected',
  WithdrawApproved = 'WithdrawApproved',
}

// src\modules\project\roadmap\enums\RoadmapSearchDirection.enum.ts
export enum ERoadmapSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\project\roadmap\enums\RoadmapSearchSort.enum.ts
export enum ERoadmapSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\subscription\enums\SubscriptionSearchDirection.enum.ts
export enum ESubscriptionSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\subscription\enums\SubscriptionSearchSort.enum.ts
export enum ESubscriptionSearchSort {
  CREATED_AT = 'created_at',
}

// src\modules\wallet\enums\WalletSearchDirection.enum.ts
export enum EWalletSearchDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// src\modules\wallet\enums\WalletSearchSort.enum.ts
export enum EWalletSearchSort {
  CREATED_AT = '',
}

export enum EFundMemberStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum EFundStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum EFundInviteStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FUND_HEAD = 'FUND_HEAD',
}

export interface IFundInvite {
  id: string;
  created_at: Date;
  updated_at: Date;
  email: string;
  userId?: string;
  fundId: string;
  user?: IUser;
  fund?: IFund;
  name?: string;
  position?: string;
  status: EFundInviteStatus;
}

export interface IFund {
  id: string;
  created_at: Date;
  updated_at: Date;
  color: string;
  name: string;
  logo: string;
  web_url: string;
  is_active: boolean;
  fundRequests: IFundRequest[];
  status: EFundStatus;
  fund_member_profiles?: IFundMemberProfile[];
  fundInvites: IFundInvite[];
}

export interface IFundMemberProfile {
  id: string;
  created_at: Date;
  updated_at: Date;
  email?: string;

  userId: string;
  fundId: string;

  user?: IUser;
  fund?: IFund;
  name?: string;
  position?: string;
  role: EFundRoles;
}

export interface IDeal {
  id: string;

  img: string;
  name: string;
  offer: string;
  webLink: string;
  description: string;
  connectLink: string;

  createdAt: Date;
  updatedAt: Date;

  tags: string[] | ITag[];
}
