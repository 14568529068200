import { ipfsHostStart, ipfsHostEnd } from 'services/config';
import { EMPTY_STRING } from 'shared/constants';

export const getCorrectIPFSLinks = <T extends Record<string, string>>(
  linksMap: T
): T => {
  const result: Partial<T> = {};
  for (const key in linksMap) {
    let value = EMPTY_STRING as T[Extract<keyof T, string>];
    if (
      (linksMap[key] && linksMap[key]?.startsWith('http')) ||
      linksMap[key]?.startsWith('blob')
    ) {
      value = linksMap[key];
    } else if (linksMap[key]) {
      value = `${ipfsHostStart}${linksMap[key]}${ipfsHostEnd}` as T[Extract<
        keyof T,
        string
      >];
    }
    result[key] = value;
  }
  return result as T;
};
