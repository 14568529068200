import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IPartner } from '@pitchtalk/contract-api-js/dist/interfaces';
import { APP_ROUTES, PARTNER_ID } from 'routes/routes';
import { Link } from 'services/router';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';

interface IPartnersTableProps {
  partners: IPartner[];
  removePartner: (partner: IPartner) => void;
}

const TABLE_COLUMNS = ['Id', 'Name', 'Partner url', 'Image', 'Action'];

const getTableRowData = (
  partner: IPartner,
  removePartner: (partner: IPartner) => void
) => [
  <Box
    sx={{
      borderRadius: '5px',
      textAlign: 'center',
      marginInline: '5px',
      paddingBlock: '2px',
      color: 'black',
    }}
  >
    {partner.id || 'No id'}
  </Box>,
  <Box>
    <Link
      to={APP_ROUTES.PARTNERS_BY_ID.replace(
        PARTNER_ID,
        partner?.id?.toString() || ''
      )}
    >
      {partner.name}
    </Link>
  </Box>,
  <Box>
    <a href={partner.url ? partner.url : ''} target="_blank">
      {partner.url || 'No url'}
    </a>
  </Box>,
  <Box>
    <img
      src={getCorrectIPFSLinks({ image: partner.image }).image}
      style={{ width: '60px', height: '60px' }}
    />
  </Box>,
  <Box>
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        removePartner(partner);
      }}
    >
      <CloseIcon />
    </IconButton>
  </Box>,
];

export const PartnersTable: React.FC<IPartnersTableProps> = ({
  partners,
  removePartner,
}) => {
  return (
    <TableContainer
      sx={{ maxHeight: '500px', overflowY: 'auto' }}
      component={Paper}
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_COLUMNS.map((data, ind) => (
              <TableCell key={ind}>{data}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {partners?.map((partner, ind) => (
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
              key={ind + (partner?.id || '') + partner.name}
            >
              {getTableRowData(partner, removePartner).map((data, ind) => (
                <TableCell key={ind}>{data}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
