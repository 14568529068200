import { API_ROUTES } from 'services/api/config';
import { fetchAPI } from 'services/api/ServerAPI';
import { parseErrorData, parseSuccessData } from 'services/api/utils';

const acceptRemove = async (investmentRoundId: string) =>
  fetchAPI
    .delete(`${API_ROUTES.getInvestment}/remove/${investmentRoundId}`)
    .then(parseSuccessData('Investment successfully removed'))
    .catch(parseErrorData('Investment remove error'));

const rejectRemove = async (investmentRoundId: string) =>
  fetchAPI
    .patch(`${API_ROUTES.getInvestment}/reject-remove/${investmentRoundId}`, {
      investmentRoundId,
    })
    .then(parseSuccessData('Investment successfully rejected to remove'))
    .catch(parseErrorData('Investment reject error'));

export { acceptRemove, rejectRemove };
