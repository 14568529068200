import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MAX_PERCENT, ONE_SECOND, SECONDS_30 } from 'shared/constants';
import { AppDispatch } from 'store/store';

import { useAppDispatch } from './redux-hooks';

export const useRefreshButton = (
  updateThunk: () => (dispatch: AppDispatch) => Promise<unknown>,
  fetchInterval = SECONDS_30
) => {
  const dispatch = useAppDispatch();
  const [refreshProgress, setRefreshProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const stepsToRefresh = fetchInterval / ONE_SECOND;
      setRefreshProgress((prev) => prev + MAX_PERCENT / stepsToRefresh);
    }, ONE_SECOND);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const refreshData = () => {
    dispatch(updateThunk());

    toast.clearWaitingQueue();
    toast.dismiss();
    toast.info('Refresh completed');
  };

  // FETCHING DATA WITH INTERVAL
  useEffect(() => {
    const fetchIntervalId = setInterval(() => {
      refreshData();
      setRefreshProgress(0);
    }, fetchInterval);
    return () => {
      clearInterval(fetchIntervalId);
    };
  }, []);

  return { refreshData, refreshProgress };
};
