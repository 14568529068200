import { Box, Typography } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from 'routes/routes';
import { fetcher } from 'services/api/utils';
import { Link } from 'services/router';
import BlockchainImg from 'shared/components/BlockchainImg/BlockchainImg';
import { SettingsBox } from 'shared/components/SettingsBox';
import { IUser } from 'shared/interfaces/backendInterfaces';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import useSWR from 'swr';

interface IEventAuthorProps {
  authorId: string;
}

export const EventAuthor: React.FC<IEventAuthorProps> = ({ authorId }) => {
  const { data: user, isLoading } = useSWR<IUser>(`users/${authorId}`, fetcher);

  if (isLoading)
    return <Typography variant="body1">Loading author data</Typography>;
  if (!user) return <Typography variant="body1">Cannot find author</Typography>;

  const projectId =
    typeof user.project === 'string'
      ? user?.project || ''
      : user?.project?.id || '';
  const projectName =
    typeof user.project === 'string'
      ? user?.project || ''
      : user?.project?.name || '';

  return (
    <SettingsBox
      legend="Event author info"
      sx={{
        padding: '10px',
        width: '500px',
        marginBottom: '20px',
        '.MuiFormGroup-root': { paddingBlock: '5px' },
      }}
    >
      <Box>
        <Typography variant="body1">User ID: {user.id}</Typography>
        <Typography variant="body1">External ID: {user.external_id}</Typography>
        <Typography variant="body1">Email: {user.email}</Typography>
        <Typography variant="body1">
          Verified Email:{' '}
          {user.verified_email ? (
            <span style={{ color: 'green' }}>Yes</span>
          ) : (
            <span style={{ color: 'red' }}>No</span>
          )}
        </Typography>
        <Typography variant="body1">
          KYC Verified:{' '}
          {user.verified_kyc ? (
            <span style={{ color: 'green' }}>Yes</span>
          ) : (
            <span style={{ color: 'red' }}>No</span>
          )}
        </Typography>
        <Typography variant="body1">
          <span>Wallets:</span>
          {(user?.wallets || [])?.map((wallet) => (
            <Box key={wallet.id} sx={{ display: 'flex', gap: 0.5 }}>
              <BlockchainImg blockchain={wallet.blockchain} />
              {wallet.provider || 'No provider'} - {wallet.wallet}
            </Box>
          ))}
        </Typography>
        <Typography variant="body1">Provider: {user.provider}</Typography>
        <Typography
          variant="body1"
          sx={{ display: 'inline-flex', alignItems: 'center', gap: 2 }}
        >
          Display Name:
          <img
            style={{ borderRadius: '50%', width: '32px', height: '32px' }}
            alt="User avatar"
            src={getCorrectIPFSLinks({ logo: user.picture }).logo}
          />
          {user.display_name}
        </Typography>
        <Typography variant="body1">
          Role: <b>{user.role}</b>
        </Typography>
        {projectId && (
          <Typography variant="body1">
            Project:{' '}
            <Link to={APP_ROUTES.PROJECTS + '/' + projectId}>
              {projectName}
            </Link>
          </Typography>
        )}
        <Typography variant="body1">
          Account Created: {user.created_at.toString()}
        </Typography>
      </Box>
    </SettingsBox>
  );
};
