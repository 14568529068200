import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { ITransaction } from '@pitchtalk/contract-api-js/dist/pitchtalk';

class Wallet {
  private signFunc?: (
    t: ITransaction[],
    callbackUrl?: string
  ) => Promise<void | FinalExecutionOutcome[]>;

  public createWalletService = (
    requestSignTransactions: (
      t: ITransaction[],
      callbackUrl?: string
    ) => Promise<void | FinalExecutionOutcome[]>
  ) => {
    this.signFunc = requestSignTransactions;
  };

  signTransactions = (
    t: ITransaction[],
    callbackUrl?: string
  ): Promise<void | FinalExecutionOutcome[]> =>
    this.signFunc
      ? this.signFunc(t, callbackUrl)
      : Promise.reject(new Error('Cannot find submission service'));
}

export default new Wallet();
