/* eslint-disable no-console */
import {
  IPartner,
  IPitchtalkMetadata,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { compose, createAsyncThunk } from '@reduxjs/toolkit';
import * as metaDataAPI from 'services/api-endpoints/metaDataAPI';
import * as partnersAPI from 'services/api-endpoints/partnersAPI';
import {
  SLICE_PREFIX,
  setPartnerById,
  setPartners,
  setPitchTalkMetaData,
} from 'store/slices/metaData';

// PARTNERS
export const getPartners = createAsyncThunk(
  SLICE_PREFIX + '/partners/getPartners',
  (_, { dispatch }) =>
    partnersAPI
      .fetchPartnersCount()
      .then((amount) =>
        partnersAPI.fetchPartners(amount).then(compose(dispatch, setPartners))
      )
);

export const addPartner = createAsyncThunk(
  SLICE_PREFIX + '/partners/addPartner',
  (partner: IPartner, { dispatch }) =>
    partnersAPI
      .fetchAddNewPartner(partner)
      .then((partner) => dispatch(setPartnerById(partner)))
);

export const editPartner = createAsyncThunk(
  SLICE_PREFIX + '/partners/editPartner',
  (partner: IPartner, { dispatch }) =>
    partnersAPI
      .fetchEditPartner(partner)
      .then(compose(dispatch, setPartnerById))
);

export const removePartner = createAsyncThunk(
  SLICE_PREFIX + '/partners/removePartner',
  (id: string, { dispatch }) =>
    partnersAPI.fetchRemovePartner(id).then(() => dispatch(getPartners()))
);

// PITCHTALK METADATA
export const getPitchTalkMetaData = createAsyncThunk(
  SLICE_PREFIX + '/pitchtalkMetaData/getPitchTalkMetaData',
  (_, { dispatch }) =>
    metaDataAPI
      .fetchPitchTalkMetaData()
      .then(compose(dispatch, setPitchTalkMetaData))
);

export const updatePitchTalkMetaData = createAsyncThunk(
  SLICE_PREFIX + '/pitchtalkMetaData/updatePitchTalkMetaData',
  (data: IPitchtalkMetadata) => metaDataAPI.fetchUpdatePitchTalkMetadata(data)
);
