import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'react-tooltip';
import { APP_ROUTES } from 'routes/routes';
import { API_ROUTES } from 'services/api/config';
import { fetcher } from 'services/api/utils';
import { pitchTalkAppUrl } from 'services/config';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { Link, useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { DEFAULT_TOKEN_META, PRECISION_DEFAULT } from 'shared/constants';
import { getAmountFormatted, roundToLow } from 'shared/utils/near';
import { selectTokens } from 'store/slices/tokensWhiteList';
import useSWR from 'swr';

import { acceptRemove, rejectRemove } from '../api';
import { IInvestmentRound } from '../types';

export const InvestmentPage: React.FC = (): JSX.Element => {
  const { investmentId } = useParams();
  const tokens = useAppSelector(selectTokens);

  const {
    data: investment,
    isLoading,
    mutate,
  } = useSWR<IInvestmentRound>(
    `${API_ROUTES.getInvestment}/${investmentId}`,
    fetcher
  );

  const onAcceptRemove = async (investmentId: string) => {
    await acceptRemove(investmentId);
    mutate();
  };

  const onRejectRemove = async (investmentId: string) => {
    await rejectRemove(investmentId);
    mutate();
  };

  const tokenMeta = investment
    ? tokens[investment?.tokenAddress]
    : DEFAULT_TOKEN_META;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!investment || !tokenMeta) {
    return (
      <Typography variant="h6">No investment details available</Typography>
    );
  }

  const formatAmount = (amount: string, decimals: number) =>
    roundToLow(+getAmountFormatted(amount, decimals), PRECISION_DEFAULT);

  return (
    <Box sx={{ margin: '20px' }}>
      <Paper elevation={3} sx={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          {investment.name}
        </Typography>
        <Typography
          sx={{ fontSize: 20 }}
          variant="caption"
          display="block"
          gutterBottom
        >
          {investment.description || 'No description available'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginTop: '20px',
            flexWrap: 'wrap',
          }}
        >
          <Box>
            <Typography variant="body1">
              <strong>Investment Id:</strong> {investment.id}{' '}
              {investment.isAvailable && (
                <>
                  <CheckIcon
                    data-tooltip-id={investment.id}
                    sx={{
                      width: 18,
                      height: 18,
                      color: 'green',
                    }}
                  />
                  <Tooltip id={investment.id}>
                    Project owner successfully created funding round, sended
                    tokens and activated it
                  </Tooltip>
                </>
              )}
            </Typography>
            <Typography variant="body1">
              <strong>Token Address:</strong> {investment.tokenAddress}
            </Typography>
            <Typography variant="body1">
              <strong>Min Amount:</strong>{' '}
              {formatAmount(investment.minAmount, tokenMeta.decimals)}{' '}
              {tokenMeta.symbol}
            </Typography>
            <Typography variant="body1">
              <strong>Max Amount:</strong>{' '}
              {formatAmount(investment.maxAmount, tokenMeta.decimals)}{' '}
              {tokenMeta.symbol}
            </Typography>
            <Typography variant="body1">
              <strong>Investment Limit:</strong>{' '}
              {formatAmount(investment.investmentLimit, tokenMeta.decimals)}{' '}
              {tokenMeta.symbol}
            </Typography>
            <Typography variant="body1">
              <strong>Start Date:</strong>{' '}
              {new Date(investment.startDate).toUTCString()}
            </Typography>
            <Typography variant="body1">
              <strong>End Date:</strong>{' '}
              {new Date(investment.endDate).toUTCString()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              <strong>Blockchain:</strong> {investment.blockchain}
            </Typography>
            <Typography variant="body1">
              <strong>Last Transaction Hash:</strong>{' '}
              {investment.lastTransactionHash || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <strong>Created At:</strong>{' '}
              {new Date(investment.createdAt).toUTCString()}
            </Typography>
            <Typography variant="body1">
              <strong>Updated At:</strong>{' '}
              {new Date(investment.updatedAt).toUTCString()}
            </Typography>
            <Typography variant="body1">
              <strong>Project ID:</strong> {investment.projectId}
            </Typography>
            <Typography variant="body1">
              <Link to={APP_ROUTES.PROJECTS + '/' + investment.projectId}>
                Project in Admin Panel
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link
                to={`http://${pitchTalkAppUrl}/projects/${investment.projectId}`}
                target="_blank"
              >
                Project in Pitchtalk
              </Link>
            </Typography>
          </Box>
          {investment.vesting && (
            <Box>
              <Typography variant="body1">
                <strong>Vesting id:</strong> {investment.vesting.id}
              </Typography>
              <Typography variant="body1">
                <strong>Vesting Token:</strong>{' '}
                {investment.vesting.tokenAddress}
              </Typography>
              <Typography variant="body1">
                <strong>Vesting Token Decimals:</strong>{' '}
                {investment.vesting.tokenDecimals}
              </Typography>
              <Typography variant="body1">
                <strong>Vesting Token Price:</strong>{' '}
                {formatAmount(
                  investment.vesting.price,
                  investment.vesting.tokenDecimals
                )}
              </Typography>
              <Typography variant="body1">
                <strong>Start Date:</strong>{' '}
                {new Date(investment.vesting.startDate).toUTCString()}
              </Typography>
              {investment.vesting.cliffEndDate && (
                <Typography variant="body1">
                  <strong>Cliff end Date:</strong>{' '}
                  {new Date(investment.vesting.cliffEndDate).toUTCString()}
                </Typography>
              )}
              <Typography variant="body1">
                <strong>Vesting end Date:</strong>{' '}
                {investment.vesting.benefitPlan.at(-1)?.endDate
                  ? new Date(
                      investment.vesting.benefitPlan.at(-1)?.endDate ||
                        new Date()
                    ).toUTCString()
                  : 'Cannot find vesting end date'}
              </Typography>
            </Box>
          )}
          {investment.requestToRemove && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <PrimaryButton onClick={() => onAcceptRemove(investment.id)}>
                Accept remove
              </PrimaryButton>
              <PrimaryButton onClick={() => onRejectRemove(investment.id)}>
                Reject remove
              </PrimaryButton>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
