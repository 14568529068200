import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { chatsReducer } from './slices/chat';
import { eventsReducer } from './slices/events';
import { fundsReducer } from './slices/funds';
import { fungibleTokensReducer } from './slices/fungibleTokens';
import { metaDataReducer } from './slices/metaData';
import { projectsReducer } from './slices/projects';
import { submissionReducer } from './slices/submission';
import { tagsReducer } from './slices/tags';
import { tokenPricesReducer } from './slices/tokenPrices';
import { tokensWhiteListReducer } from './slices/tokensWhiteList';
import { userReducer } from './slices/user';
import { vestingReducer } from './slices/vesting';

const rootReducer = combineReducers({
  projects: projectsReducer,
  submission: submissionReducer,
  tokensWhiteList: tokensWhiteListReducer,
  fungibleTokens: fungibleTokensReducer,
  fundsData: fundsReducer,
  user: userReducer,
  vesting: vestingReducer,
  tokenPrices: tokenPricesReducer,
  events: eventsReducer,
  tags: tagsReducer,
  metaData: metaDataReducer,
  chat: chatsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
