import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { memo } from 'react';

interface IEventsTableHeadProps {
  columns: string[];
}

export const EventsTableHead: React.FC<IEventsTableHeadProps> = memo(
  ({ columns }) => (
    <TableRow>
      {columns.map((data, ind) => (
        <TableCell align="center" key={ind}>
          {data}
        </TableCell>
      ))}
    </TableRow>
  )
);
