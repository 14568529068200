/* eslint-disable no-console */
import { SubmissionService } from '@pitchtalk/contract-api-js';
import {
  EWithdrawStatus,
  // IProject,
  ISubmissionFundModel,
} from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import { nodeUrl, subServiceContract as contractId } from 'services/config';
import { IProject } from 'shared/interfaces/backendInterfaces';

class SubService {
  private API: SubmissionService | null = null;

  public createService = (accountId: string) => {
    this.API = new SubmissionService(accountId, contractId, nodeUrl);
  };

  private withAsyncAPI = <T>(func: (API: SubmissionService) => Promise<T>) => {
    return this.API
      ? func(this.API)
      : Promise.reject(new Error('Cannot find submission service'));
  };

  private withAPI = <T>(func: (API: SubmissionService) => T) => {
    if (!this.API) {
      console.error(new Error('Cannot find submission service'));
      return [] as unknown as T;
    }
    return func(this.API);
  };

  // VIEW API METHODS
  getProjectsCount = () => this.withAsyncAPI((API) => API.getProjectsCount());

  getProjects = (params: { fromIndex: number; limit: number }) =>
    this.withAsyncAPI((API) => API.getProjects(params.fromIndex, params.limit));

  getWhiteListAccounts = () =>
    this.withAsyncAPI((API) => API.getWhiteListAccounts());

  getOwnerAccount = () => this.withAsyncAPI((API) => API.getOwner());

  getMetaData = () => this.withAsyncAPI((API) => API.getMetadata());

  getUserAccountMeta = (subProject: IProject) =>
    this.withAsyncAPI((API) => API.getAccount(subProject.authorId || ''));

  getProject = (authorId: string) =>
    this.withAsyncAPI((API) => API.getProject(authorId));

  getProjectById = (projectId: number) =>
    this.withAsyncAPI((API) => API.getProjectById(projectId));

  getFunds = (params: { fromIndex?: number; limit?: number }) =>
    this.withAsyncAPI((API) =>
      API.getSubmissionFunds(params.fromIndex, params.limit)
    );

  getFund = (accountId: string) =>
    this.withAsyncAPI((API) => API.getSubmissionFund(accountId));

  getFundsCount = () =>
    this.withAsyncAPI((API) => API.getSubmissionFundCount());

  // CHANGE METHODS
  addWhiteListAccounts = (accounts: string[]) =>
    this.withAPI((API) => API.addWhiteListAccounts(accounts));

  removeWhiteListAccounts = (accounts: string[]) =>
    this.withAPI((API) => API.removeWhiteListAccounts(accounts));

  approve = (authorId: string, comment?: string) =>
    this.withAPI((API) => API.approve(authorId, comment));

  approveFund = (accountId: string) =>
    this.withAPI((API) => API.approveSubmissionFund(accountId));

  reject = (authorId: string, comment?: string) =>
    this.withAPI((API) => API.reject(authorId, comment));

  rejectFund = (accountId: string) =>
    this.withAPI((API) => API.rejectSubmissionFund(accountId));

  updateFee = (amount: string) => this.withAPI((API) => API.updateFee(amount));

  updateJoinFeeAndToken = (amount: string, token: string) =>
    this.withAPI((API) => API.updateJoinFeeAndToken(amount, token));

  updateProjectId = (authorId: string, projectId: number) =>
    this.withAPI((API) => API.updateProjectId(authorId, projectId));

  withdrawVestingTokens = (authorId: string) =>
    this.withAPI((API) => API.withdrawVestingTokens(authorId));

  withdrawFee = (receiverId: string, amount: string) =>
    this.withAPI((API) => API.withdrawFee(receiverId, amount));

  updateWithdrawRequestStatus = (
    authorId: string,
    withdrawId: number,
    status: EWithdrawStatus,
    comment?: string
  ) =>
    this.withAPI((API) =>
      API.updateWithdrawRequestStatus(authorId, withdrawId, status, comment)
    );

  requestToResetProject = (accountId: string, comment?: string) =>
    this.withAPI((API) => API.requestToResetProject(accountId, comment));

  revertRequestToResetProject = (accountId: string, comment?: string) =>
    this.withAPI((API) => API.revertRequestToResetProject(accountId, comment));

  registerFund = (fund: ISubmissionFundModel) =>
    this.withAPI((API) => API.registerFund(fund));
}

export default new SubService();
