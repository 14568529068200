import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'shared/interfaces/backendInterfaces';

import { RootState } from '../store';

export interface IUserState {
  isOwner: boolean;
  isAdmin: boolean;
  user: IUser | null;
}

const initialState: IUserState = {
  isOwner: false,
  isAdmin: false,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsOwner: (state, { payload }: PayloadAction<boolean>) => {
      state.isOwner = payload;
    },
    setIsAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdmin = payload;
    },
    setUserData: (state, { payload }: PayloadAction<IUser | null>) => {
      state.user = payload;
    },
  },
});
export const { setIsOwner, setIsAdmin, setUserData } = userSlice.actions;

export const selectIsOwner = (state: RootState) => state.user.isOwner;
export const selectIsAdmin = (state: RootState) => state.user.isAdmin;

export const selectIsSignedInServer = (state: RootState) => !!state.user.user;

export const selectUserData = (state: RootState) => state.user.user;

export const userReducer = userSlice.reducer;
