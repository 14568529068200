import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikProps } from 'formik';
import { memo } from 'react';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';

import { EventPartnerPreview } from './EventPartnerPreview';
import { IEventServerState } from '../types';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { updateEventPartners, updateServerEvent } from 'store/thunks/events';
import { useParams } from 'react-router';

interface IEventPartnersProps {
  formik: FormikProps<IEventServerState>;
  isEventVoteEnded: boolean;
}

export const EventPartners: React.FC<IEventPartnersProps> = memo(
  ({ formik, isEventVoteEnded }) => {
    const { showModal } = useModalContext();

    const { eventId = '' } = useParams();
    const dispatch = useAppDispatch();

    const openPictureModal = () => {
      const image = formik.values.partner;
      showModal(EModals.UPLOAD_IMAGE_MODAL, {
        submitImage: (imageCid: string) => {
          formik.setFieldValue('partner', imageCid);
        },
        image,
      });
    };

    const handleUpdateEventPartners = () => {
      dispatch(updateEventPartners(eventId, formik.values.partners));
    }

    return (
      <SettingsBox
        legend="Server Event Partners"
        sx={{
          padding: '10px',
          width: '550px',
          height: 'fit-content',
          '.MuiFormGroup-root': { paddingBlock: '5px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '25px',
            '& .MuiFormGroup-root': {
              width: '100%',
            },
            '& .MuiFormControlLabel-root': {
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <SettingsInput
            inputLabel="Partner logo link"
            name="Partner logo link"
            value={formik.values.partner}
            onFocus={() => formik.setFieldTouched('partner', true)}
            onChange={(e) => formik.setFieldValue('partner', e.target.value)}
            boxStyles={{ flex: 1, width: '80%' }}
            InputProps={{ readOnly: isEventVoteEnded, disabled: true }}
            error={!!formik.errors?.partner}
            helperText={formik.errors?.partner}
            uploadButton={{
              showButton: true,
              disableButton: isEventVoteEnded,
              onClick: () => openPictureModal(),
            }}
          />
          <PrimaryButton
            sx={{ fontSize: '12px', height: '30px' }}
            onClick={() =>
              formik.setValues((prev) => ({
                ...prev,
                partners: [...prev.partners, formik.values.partner],
                partner: '',
              }))
            }
            disabled={
              formik.isSubmitting
              // !formik.dirty ||
              // !formik.isValid ||
              // !formik.values.partner ||
              // isEventVoteEnded
            }
          >
            Add
          </PrimaryButton>
          <PrimaryButton
            sx={{ fontSize: '12px', height: '30px' }}
            onClick={handleUpdateEventPartners}
            disabled={
              formik.isSubmitting
              // !formik.dirty ||
              // !formik.isValid ||
              // !formik.values.partner ||
              // isEventVoteEnded
            }
          >
            Update Partners
          </PrimaryButton>
        </Box>
        <Box>
          {formik.values.partners.map((partner, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBlock: '4px',
                borderBottom: '1px solid black',
                borderTop: '1px solid black',
              }}
            >
              <Typography
                sx={{
                  maxWidth: '436px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  WebkitLineClamp: 3,
                }}
              >
                {partner}
              </Typography>
              <Box>
                <EventPartnerPreview img={partner} />
                <CloseIcon
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      partners: prev.partners.filter((p) => p !== partner),
                    }))
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
      </SettingsBox>
    );
  }
);
