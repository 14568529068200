import { Project } from '@pitchtalk/contract-api-js';
import { Vesting, WithdrawType } from '@pitchtalk/contract-api-js/dist/core';
import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import {
  EventAccessFeeTypePayed,
  IEvent,
  ITeamMember,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import {
  Metadata,
} from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import { FormikProps } from 'formik';
import { CustomValidationErrors } from 'shared/utils/submissionServiceUtils';
// import { DiffChangesTypes } from 'shared/utils/whatChanged';
import { IEventCombined } from 'store/slices/events';
import { RootState } from 'store/store';
import { IProject, IProjectTeamMember } from './backendInterfaces';

export interface ICloseModal {
  closeModal: () => void;
}

export interface ISignOutModal extends ICloseModal {
  signOut: () => void;
  accountId: string;
}

export interface IConfirmModal extends ICloseModal {
  confirmAction: (...arg: any | undefined) => Promise<void> | void;
}

export interface IInfoModal extends ICloseModal {
  info: string | JSX.Element;
  subInfo?: string | JSX.Element;
  loadingSelector?: (state: RootState) => boolean;
  submitAction?: () => void;
  cancelAction?: () => void;
  closeAction?: () => void;
  closeDelay?: number;
}

export interface IWithdrawModal extends ICloseModal {
  withdrawType: WithdrawType;
  project?: Project;
  vesting?: Vesting;
  event?: IEventCombined;
  grantTokenId?: string;
  subMeta?: Metadata;
  eventMeta?: EventAccessFeeTypePayed;
  isWithdrawAll?: boolean;
}

export interface IEditFeeModal extends ICloseModal {
  tokens: { [key: string]: ITokenMetadata };
  metaData?: Metadata;
  updateFeeTokenData: (
    tokenId: string,
    amount: string,
    updateAmount: boolean
  ) => Promise<void>;
}

export interface IApproveSubModal extends ICloseModal {
  approve: (comment?: string) => Promise<void>;
  validationErrors: CustomValidationErrors;
  isActualData?: boolean;
}

export interface IDeclineSubModal extends ICloseModal {
  decline: (comment?: string) => Promise<void>;
  isActualData?: boolean;
}

export interface IUploadImageModal extends ICloseModal {
  submitImage: (imageCid: string) => void;
  image?: string;
  imageName?: string;
}

export interface IResettingSubModal extends ICloseModal {
  subProject: IProject;
}

export type IWhatChangedSubModal = ICloseModal

export interface ITeamMemberSettingsModal extends ICloseModal {
  teamMember: IProjectTeamMember;
  id: string;
  globalFormik: FormikProps<IProjectTeamMember[]>;
  highlightedFields?: string[];
  isControls?: boolean;
}

export interface IAuthServerModal extends ICloseModal {
  isClose?: boolean;
}

export enum EModals {
  SIGN_OUT_MODAL = 'SIGN_OUT_MODAL',
  INFO_MODAL = 'INFO_MODAL',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  WITHDRAW_MODAL = 'WITHDRAW_MODAL',
  EDIT_FEE_MODAL = 'EDIT_FEE_MODAL',
  APPROVE_SUB_MODAL = 'APPROVE_SUB_MODAL',
  DECLINE_SUB_MODAL = 'DECLINE_SUB_MODAL',
  RESETTING_SUB_MODAL = 'RESETTING_SUB_MODAL',
  WHAT_CHANGED_SUB_MODAL = 'WHAT_CHANGED_SUB_MODAL',
  TEAM_MEMBER_SETTINGS = 'TEAM_MEMBER_SETTINGS',
  AUTH_SERVER_MODAL = 'AUTH_SERVER_MODAL',
  UPLOAD_IMAGE_MODAL = 'UPLOAD_IMAGE_MODAL',
  EMPTY = 'EMPTY',
}
