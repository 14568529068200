import {
  EVENT_ID,
  FUND_ID,
  PARTICIPATION_ID,
  PARTNER_ID,
  PROJECT_ID,
  REFEREE_ID,
  TAG_ID,
} from 'routes/routes';
import { serverUrl } from 'services/config/index';

export const BASE_URL = serverUrl + '/v1/api/';

export const API_ROUTES = {
  // AUTH
  authLogin: 'auth/login',
  authRefresh: 'auth/tokens',
  // USER
  users: 'users',
  usersRole: 'users/role',
  userData: 'users/me',
  // EVENTS
  event: 'event',
  eventsHasEvents: 'event/hasEvents',
  eventOnChain: 'event/onChainId',
  eventSchedule: `event/${EVENT_ID}/schedule`,
  eventHackathon: `hackathon/event/${EVENT_ID}`,
  approveSubmissionProject: 'event/submission-project/approve',
  approveSubmissionJudge: 'event/submission-judge/approve',
  rejectSubmissionProject: 'event/submission-project/reject',
  // HACKATHON
  hackathonTasks: `event/tasks/${EVENT_ID}`,
  hackathonPrize: `event/prizeDetails/${EVENT_ID}`,
  // PROJECT EVENT PARTICIPATIONS:
  addParticipation: 'event/participation/force',
  addReferee: 'event/referee/force',
  eventSubmissionsProjects: `participation/event/${EVENT_ID}`,
  pendingEventParticipation: `participation/pending/${PARTICIPATION_ID}`,
  approveEventParticipation: `participation/approve/${PARTICIPATION_ID}`,
  rejectEventParticipation: `participation/reject/${PARTICIPATION_ID}`,
  eventReferee: `referee/event/${EVENT_ID}`,
  pendingReferee: `referee/pending/${REFEREE_ID}`,
  approveReferee: `referee/approve/${REFEREE_ID}`,
  rejectReferee: `referee/reject/${REFEREE_ID}`,
  // PROJECTS
  projects: 'projects',
  projectsCount: 'projects/count',
  projectsCreate: 'projects/create',
  projectsUpdate: `/projects/${PROJECT_ID}/update`,
  approveProjectSubmission: `/submissions/${PROJECT_ID}/approve`, // ???
  rejectProjectSubmission: `/submissions/${PROJECT_ID}/reject`, // ???
  // TAGS
  tags: 'tag',
  tagsCount: 'tag/count',
  tagsById: `tag/${TAG_ID}`,
  // PARTNERS
  partners: 'partners',
  partnersCount: 'partners/count',
  partnersById: `partners/${PARTNER_ID}`,
  // METADATA
  metadata: 'metadata',

  // ************************    V2    ************************ //
  // FUND
  getFund: `funds/${FUND_ID}`,
  getFunds: 'funds',
  approveFundSubmission: `funds/${FUND_ID}/approve`,
  rejectFundSubmission: `funds/${FUND_ID}/reject`,
  // EVENT
  getEvents: 'event',

  //DEALS
  getDeals: 'deals',

  // INVESTMENTS
  getInvestment: 'investment-round',
};
