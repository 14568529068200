import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export type IFungibleTokensState = { [key: string]: ITokenMetadata };

const initialState: IFungibleTokensState = {};

const tokenSlice = createSlice({
  name: 'fungibleTokens',
  initialState,
  reducers: {
    setFungibleTokens: (
      state,
      action: PayloadAction<{ [tokenId: string]: ITokenMetadata }>
    ) => ({ ...state, ...action.payload }),
    setFungibleToken: (
      state,
      action: PayloadAction<ITokenMetadata & { tokenId: string }>
    ) => {
      const { tokenId, ...rest } = action.payload;
      state[tokenId] = { ...state?.[tokenId], ...rest };
    },
  },
});

export const { setFungibleTokens, setFungibleToken } = tokenSlice.actions;
export const selectFungibleTokens = (state: RootState) => state.fungibleTokens;

export const fungibleTokensReducer = tokenSlice.reducer;
