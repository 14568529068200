import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { selectTags } from 'store/slices/tags';

import { theme } from '../theme';

interface ITagsSettingsProps {
  isReadOnly: boolean;
  selectedTags: string[];
  handleSelectTags: (event: SelectChangeEvent<string[]>) => void;
  errors?: string;
  isHighlighted?: boolean;
  max?: number;
}

const highlightedSx = {
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.success.main,
    },
  },
};

export const TagsSettings: React.FC<ITagsSettingsProps> = ({
  isReadOnly,
  selectedTags,
  handleSelectTags,
  errors,
  isHighlighted,
  max = 5,
}) => {
  const tags = useAppSelector(selectTags);

  return (
    <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
      <InputLabel id="tags-label">Tags</InputLabel>
      <Select
        labelId="tags-label"
        id="tags-label-select"
        multiple
        value={selectedTags}
        onChange={handleSelectTags}
        input={<OutlinedInput label="Tags" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selected.map((value) => {
              const label = tags.find((tag) => tag.id === value)?.name;
              return label ? (
                <Chip key={value + crypto.randomUUID()} label={label} />
              ) : null;
            })}
          </Box>
        )}
        MenuProps={{
          PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
        }}
        sx={{
          ...(isHighlighted ? highlightedSx : {}),
        }}
        readOnly={isReadOnly}
      >
        {tags.map(({ id, name }) => (
          <MenuItem
            key={id}
            value={id}
            disabled={selectedTags.length >= max && !selectedTags.includes(id)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
    </FormControl>
  );
};
