import { useEffect } from 'react';
import EStorageKeys from 'services/constants/localStorageKeys';
import * as LocalStorage from 'services/localStorage';
import { ToastService } from 'services/toast/ToastService';
import { IUser } from 'shared/interfaces/backendInterfaces';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { checkIsDefaultUser, checkIsModerator } from 'shared/utils/userUtils';
import { selectIsAdmin, selectIsOwner } from 'store/slices/user';
import { getServerUserData, logoutUser } from 'store/thunks/user';

import { useAppDispatch, useAppSelector } from './redux-hooks';

export const useAuth = () => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const isOwner = useAppSelector(selectIsOwner);
  const hasAccessWithWallet = isAdmin || isOwner;
  const { showModal } = useModalContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = LocalStorage.getItem(EStorageKeys.ACCESS_TOKEN);
    const refreshToken = LocalStorage.getItem(EStorageKeys.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      dispatch(getServerUserData()).then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const user = res?.payload as IUser;
        if (hasAccessWithWallet && !user) {
          showModal(EModals.AUTH_SERVER_MODAL, {});
        } else if (checkIsDefaultUser(user) || checkIsModerator(user)) {
          dispatch(logoutUser());
          ToastService.error('', 'You do not have access to admin panel');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccessWithWallet]);
};
