import { PutOptions, Web3Storage } from 'web3.storage';

import { EEnvironment, currentEnvironment, ipfsToken } from './../config';

interface IService {
  uploadFile: (
    file: File[],
    options?: PutOptions
  ) => Promise<string>;
}

class IPFSService implements IService {
  private _storage: Web3Storage;

  constructor(token: string) {
    this._storage = new Web3Storage({ token });
  }

  public async uploadFile(
    file: File[],
    options?: PutOptions
  ): Promise<string> {
    try {
      const data = new FormData()
      data.append('file', file[0])
      const response = await fetch('https://storage.hapilabs.one/upload.php', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: data,
      })

      const json: { web3cid: string }[] = await response.json();

      return json[0].web3cid
    } catch (error) {
      console.error(error);
      if (currentEnvironment === EEnvironment.TESTNET) {
        return 'bafkreiefm34mkqcdawk7nj2k5wmbilkp4pgdqiyzg5jwrbnvdn5g5odz3e'
      } else throw error;
    }
  }
}

export const ipfsService = new IPFSService(ipfsToken);