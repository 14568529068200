/* eslint-disable no-console */
import { parseData } from 'services/api/utils';
import { IUser } from 'shared/interfaces/backendInterfaces';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export const fetchLogin = (email: string, password: string) =>
  fetchAPI
    .post<{ accessToken: string; refreshToken: string }>(API_ROUTES.authLogin, {
      email,
      password,
    })
    .then(parseData);

export const refreshToken = () =>
  fetchAPI.get(API_ROUTES.authRefresh).then(parseData);

export const fetchUserData = () =>
  fetchAPI.get<IUser>(API_ROUTES.userData).then(parseData);
