import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';

import { EventSettings } from '../helpers';

interface IMetaDataSettingsProps {
  formik: FormikProps<EventSettings>;
  isReadOnly: boolean;
}

export const MetaDataSettings: React.FC<IMetaDataSettingsProps> = ({
  formik,
  isReadOnly,
}) => {
  const { showModal } = useModalContext();

  const openPictureModal = (type: 'banner' | 'logo') => {
    const image = formik.values[type];
    const eventName = formik.values.name;
    showModal(EModals.UPLOAD_IMAGE_MODAL, {
      submitImage: (imageCid: string) => {
        formik.setFieldValue(type, imageCid);
      },
      image,
      imageName: eventName,
    });
  };

  return (
    <SettingsBox
      legend="Event metadata"
      sx={{
        padding: '10px',
        width: '400px',
        marginBottom: '20px',
        '.MuiFormGroup-root': { paddingBlock: '5px' },
      }}
    >
      <SettingsInput
        inputLabel="Description"
        name="description"
        value={formik.values.description || ''}
        onFocus={() => formik.setFieldTouched('description', true)}
        onChange={(e) => formik.setFieldValue('description', e.target.value)}
        error={formik.touched?.description && !!formik.errors?.description}
        helperText={
          formik.touched?.description &&
          formik.errors &&
          formik.errors?.description
        }
        multiline
        rows={6}
        boxStyles={{
          alignItems: 'flex-start',
          '& .MuiOutlinedInput-input': { padding: '0 !important' },
        }}
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Prize pool"
        name="prize_pool"
        value={formik.values.prize_pool || ''}
        onFocus={() => formik.setFieldTouched('prize_pool', true)}
        onChange={(e) => formik.setFieldValue('prize_pool', e.target.value)}
        error={formik.touched?.prize_pool && !!formik.errors?.prize_pool}
        helperText={
          formik.touched?.prize_pool &&
          formik.errors &&
          formik.errors?.prize_pool
        }
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Short description"
        name="short_description"
        value={formik.values.short_description || ''}
        onFocus={() => formik.setFieldTouched('short_description', true)}
        onChange={(e) =>
          formik.setFieldValue('short_description', e.target.value)
        }
        error={
          formik.touched?.short_description &&
          !!formik.errors?.short_description
        }
        helperText={
          formik.touched?.short_description &&
          formik.errors &&
          formik.errors?.short_description
        }
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Logo"
        name="logo"
        value={formik.values.logo || ''}
        onFocus={() => formik.setFieldTouched('logo', true)}
        onChange={(e) => formik.setFieldValue('logo', e.target.value)}
        error={formik.touched?.logo && !!formik.errors?.logo}
        helperText={
          formik.touched?.logo && formik.errors && formik.errors?.logo
        }
        InputProps={{ disabled: true }}
        uploadButton={{
          showButton: true,
          disableButton: isReadOnly,
          onClick: () => openPictureModal('logo'),
        }}
      />
      <SettingsInput
        inputLabel="Banner"
        name="banner"
        value={formik.values.banner || ''}
        onFocus={() => formik.setFieldTouched('banner', true)}
        onChange={(e) => formik.setFieldValue('banner', e.target.value)}
        error={formik.touched?.banner && !!formik.errors?.banner}
        helperText={
          formik.touched?.banner && formik.errors && formik.errors?.banner
        }
        InputProps={{ disabled: true }}
        uploadButton={{
          showButton: true,
          disableButton: isReadOnly,
          onClick: () => openPictureModal('banner'),
        }}
      />
      <SettingsInput
        inputLabel="Web url"
        name="web_url"
        value={formik.values.web_url || ''}
        onFocus={() => formik.setFieldTouched('web_url', true)}
        onChange={(e) => formik.setFieldValue('web_url', e.target.value)}
        error={formik.touched?.web_url && !!formik.errors?.web_url}
        helperText={
          formik.touched?.web_url && formik.errors && formik.errors?.web_url
        }
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Video url"
        name="video_url"
        value={formik.values.video_url || ''}
        onFocus={() => formik.setFieldTouched('video_url', true)}
        onChange={(e) => formik.setFieldValue('video_url', e.target.value)}
        error={formik.touched?.video_url && !!formik.errors?.video_url}
        helperText={
          formik.touched?.video_url && formik.errors && formik.errors?.video_url
        }
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Event tag"
        name="tag"
        value={formik.values.tag || ''}
        onFocus={() => formik.setFieldTouched('tag', true)}
        onChange={(e) => formik.setFieldValue('tag', e.target.value)}
        error={formik.touched?.tag && !!formik.errors?.tag}
        helperText={formik.touched?.tag && formik.errors && formik.errors?.tag}
        InputProps={{ readOnly: isReadOnly }}
      />
      <SettingsInput
        inputLabel="Event location"
        name="location"
        value={formik.values.location || ''}
        onFocus={() => formik.setFieldTouched('location', true)}
        onChange={(e) => formik.setFieldValue('location', e.target.value)}
        error={formik.touched?.location && !!formik.errors?.location}
        helperText={
          formik.touched?.location && formik.errors && formik.errors?.location
        }
        InputProps={{ readOnly: isReadOnly }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.isNews}
            onChange={(event) =>
              formik.setFieldValue('isNews', event.target.checked)
            }
          />
        }
        label="Event News"
      />
    </SettingsBox>
  );
};
