import { toast, Slide, Theme } from 'react-toastify';
import { theme } from 'shared/components/theme';

const toastStyles = {
  theme: 'dark' as Theme,
  transition: Slide,
  closeOnClick: true,
  pauseOnHover: true,
  style: {
    background: 'black',
    boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
  },
};
const Info = ({
  link,
  msg,
  withWarn,
}: {
  link: string;
  msg: string | JSX.Element[];
  withWarn?: boolean;
}) => (
  <div>
    <div>{msg}</div>
    <div
      style={{
        fontFamily: 'Everett-Regular',
        color: 'rgb(255, 136, 17)',
        fontSize: '0.75rem',
      }}
    >
      {withWarn &&
        // eslint-disable-next-line quotes
        "*Reload the page, please, if data hasn't been updated immediately"}
    </div>
    {link ? (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontFamily: 'Everett-Regular',
          color: theme.palette.primary.dark,
          textDecoration: 'none',
        }}
      >
        <p>Open transaction</p>
      </a>
    ) : null}
  </div>
);

export class ToastService {
  static success(link: string, msg: string | JSX.Element[]) {
    toast.success(<Info link={link} msg={msg} withWarn />, toastStyles);
  }
  static error(link: string, msg: string | JSX.Element[]) {
    toast.error(<Info link={link} msg={msg} />, toastStyles);
  }
  static info(link: string, msg: string | JSX.Element[]) {
    toast.info(<Info link={link} msg={msg} withWarn />, toastStyles);
  }
}
