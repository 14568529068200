import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { theme } from 'shared/components/theme';

export const PrimaryButton = styled(Button)(() => ({
  color: 'white',
  height: '36px',
  padding: '0 22px',
  lineHeight: '16px',
  borderRadius: '3px',
  letterSpacing: '0.28px',
  fontSize: '1.1rem',
  fontFamily: 'Everett-Regular',
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&:disabled': {
    color: 'black',
    background: theme.palette.secondary.main,
  },
}));
