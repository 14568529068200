/* eslint-disable no-console */
import { PitchTalk } from '@pitchtalk/contract-api-js';
import {
  Documents,
  IFund,
  InvestmentDataArgs,
  ProjectArgs,
  SocialLinks,
  VestingDataArgs,
  WithdrawType,
} from '@pitchtalk/contract-api-js/dist/core';
import {
  IEventArgs,
  EProjectType,
  EPitchType,
  PitchArgsOnChain,
  // ITeam,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { nodeUrl, contract as contractId } from 'services/config';
import { FT_TRANSFER_GAS } from 'shared/constants';

class PTService {
  private API: PitchTalk | null = null;

  public createService = (accountId: string) => {
    this.API = new PitchTalk(accountId, contractId, nodeUrl);
  };

  private withAsyncAPI = <T>(func: (API: PitchTalk) => Promise<T>) => {
    return this.API
      ? func(this.API)
      : Promise.reject(new Error('Cannot find pitchtalk service'));
  };

  private withAPI = <T>(func: (API: PitchTalk) => T) => {
    if (!this.API) {
      console.error(new Error('Cannot find pitchtalk service'));
      return [] as unknown as T;
    }
    return func(this.API);
  };

  // API METHODS
  getProjectsCount = () => this.withAsyncAPI((API) => API.projectCount());

  getProjects = (params: { fromIndex: number; limit: number }) =>
    this.withAsyncAPI((API) => API.getProjects(params.fromIndex, params.limit));

  getProjectById = (projectId: number) =>
    this.withAsyncAPI((API) => API.getProjectById(projectId));

  getProjectByName = (name: string) =>
    this.withAsyncAPI((API) => API.getProjectByName(name));

  getIsProjectExist = (name: string) =>
    this.withAsyncAPI((API) => API.projectExists(name));

  getFunds = (params: { fromIndex?: number; limit?: number }) =>
    this.withAsyncAPI((API) => API.getFunds(params.fromIndex, params.limit));

  getFund = (accountId: string) =>
    this.withAsyncAPI((API) => API.getFund(accountId));

  getTotalGrantsForFunds = (accountId: string) =>
    this.withAsyncAPI((API) => API.getTotalGrantsByFundId(accountId));

  getFundsCount = () => this.withAsyncAPI((API) => API.getFundsCount());

  getWhiteListAccounts = () =>
    this.withAsyncAPI((API) => API.getWhiteListAccounts());

  getOwnerAccount = () => this.withAsyncAPI((API) => API.getOwnerAccount());

  getWhiteListTokens = () =>
    this.withAsyncAPI((API) => API.getWhiteListTokens());

  getStorageBalanceOf = (accountId: string, receiverId?: string) =>
    this.withAsyncAPI((API) => API.storageBalanceOf(accountId, receiverId));

  getStorageBalanceBounds = (receiverId?: string) =>
    this.withAsyncAPI((API) => API.storageBalanceBounds(receiverId));

  getAccountData = (receiverId?: string) =>
    this.withAsyncAPI((API) => API.getAccountData(receiverId));

  getVesting = (projectId: number) =>
    this.withAsyncAPI((API) => API.getVesting(projectId));

  // CHANGE METHODS
  addWhiteListAccounts = (accounts: string[]) =>
    this.withAPI((API) => API.addWhiteListAccounts(accounts));

  removeWhiteListAccounts = (accounts: string[]) =>
    this.withAPI((API) => API.removeWhiteListAccounts(accounts));

  updateFund = (fundId: Omit<IFund, 'is_active'>) =>
    this.withAPI((API) => API.updateFund(fundId));

  addNewToken = (tokenId: string, decimals: number) =>
    this.withAPI((API) => API.addNewToken(tokenId, decimals));

  updateFundStatus = (fundId: string, isActive: boolean) =>
    this.withAPI((API) => API.updateFundStatus(fundId, isActive));

  updateDonationsAvailable = (projectId: number, isAvailable: boolean) =>
    this.withAPI((API) => API.updateDonationsAvailable(projectId, isAvailable));

  updateInvestmentsAvailable = (projectId: number, isAvailable: boolean) =>
    this.withAPI((API) =>
      API.updateInvestmentsAvailable(projectId, isAvailable)
    );

  registerProject = (projectArgs: ProjectArgs, gas: string = FT_TRANSFER_GAS) =>
    this.withAPI((API) => API.registerProject(projectArgs, gas));

  updateProject = (
    params: {
      project_id: number;
      name?: string;
      description?: string;
      logo?: string;
      project_url?: string;
      social_links?: SocialLinks;
    },
    gas: string = FT_TRANSFER_GAS
  ) => this.withAPI((API) => API.updateProject(params, gas));

  getStorageBalanceTransaction = (receiverId?: string, accountId?: string) =>
    this.withAPI((API) =>
      API.getStorageBalanceTransaction(receiverId, accountId)
    );

  withdrawTokens = (
    withdrawType: WithdrawType,
    projectId: number,
    receiverId: string,
    amount: string,
    tokenId: string,
    eventId: number
  ) =>
    this.withAPI((API) =>
      API.withdrawTokens(
        withdrawType,
        projectId,
        receiverId,
        amount,
        tokenId,
        eventId
      )
    );

  updateInvestmentAndVestingData = (
    projectId: number,
    vestingData: VestingDataArgs,
    investmentData: InvestmentDataArgs,
    isInit: boolean
  ) =>
    this.withAPI((API) =>
      API.updateInvestmentAndVestingData(
        projectId,
        vestingData,
        investmentData,
        isInit
      )
    );

  updateProjectToken = (projectId: number, tokenId: string) =>
    this.withAPI((API) => API.updateProjectToken(projectId, tokenId));

  activateProject = (projectId: number, gas: string = FT_TRANSFER_GAS) =>
    this.withAPI((API) => API.activateProject(projectId, gas));

  deactivateProject = (projectId: number, gas: string = FT_TRANSFER_GAS) =>
    this.withAPI((API) => API.deactivateProject(projectId, gas));

  sendVestingTokens = (token: string, projectId: number, amount: string) =>
    this.withAPI((API) => API.sendVestingTokens(token, projectId, amount));

  payLockupStorageDeposit = (token: string, lockupAccountId: string) =>
    this.withAPI((API) => API.payLockupStorageDeposit(token, lockupAccountId));

  addProjectAttachment = (
    projectId: number,
    attachments: Documents,
    gas: string = FT_TRANSFER_GAS
  ) =>
    this.withAPI((API) =>
      API.addProjectAttachment(projectId, attachments, gas)
    );

  removeProjectAttachment = (
    projectId: number,
    attachments: string[],
    gas: string = FT_TRANSFER_GAS
  ) =>
    this.withAPI((API) =>
      API.removeProjectAttachment(projectId, attachments, gas)
    );

  getProjectAttachment = (projectId: number, attachmentId: string) =>
    this.withAPI((API) => API.getProjectAttachment(projectId, attachmentId));

  initNextPitch = (
    projectId: number,
    pitch: PitchArgsOnChain,
    gas: string = FT_TRANSFER_GAS
  ) => this.withAPI((API) => API.initNextPitch(projectId, pitch, gas));

  updatePitch = (
    projectId: number,
    stage: EPitchType,
    newPitch: PitchArgsOnChain,
    gas: string = FT_TRANSFER_GAS
  ) => this.withAPI((API) => API.updatePitch(projectId, stage, newPitch, gas));

  activatePitch = (
    projectId: number,
    pitchType: EPitchType,
    gas: string = FT_TRANSFER_GAS
  ) => this.withAPI((API) => API.activatePitch(projectId, pitchType, gas));

  deactivatePitch = (
    projectId: number,
    pitchType: EPitchType,
    gas: string = FT_TRANSFER_GAS
  ) => this.withAPI((API) => API.deactivatePitch(projectId, pitchType, gas));

  updateTeam = (projectId: number, team: any) =>
    this.withAPI((API) => API.updateTeam(projectId, team));

  // ************ EVENTS ************ //
  getEvent = (eventId: number) =>
    this.withAsyncAPI((API) => API.getEvent(eventId));

  getEventsCount = () => this.withAsyncAPI((API) => API.getEventsCount());

  getEvents = (params: {
    from_index?: number;
    limit?: number;
    ids?: number[];
  }) => this.withAsyncAPI((API) => API.getEvents(params));

  registerEvent = (event: IEventArgs) =>
    this.withAPI((API) => API.registerEvent(event));

  updateEvent = (event_id: number, event_data: IEventArgs) =>
    this.withAPI((API) => API.updateEvent(event_id, event_data));

  addEventProject = (
    event_id: number,
    project_id: string,
    project_type: EProjectType
  ) =>
    this.withAPI((API) =>
      API.addEventProject(event_id, project_id, project_type)
    );

  removeEventProject = (
    event_id: number,
    project_id: string,
    receiver_id?: string
  ) =>
    this.withAPI((API) =>
      API.removeEventProject(event_id, project_id, receiver_id)
    );

  addEventJudge = (event_id: number, account_id: string) =>
    this.withAPI((API) => API.addEventJudge(event_id, account_id));

  removeEventJudge = (event_id: number, account_id: string) =>
    this.withAPI((API) => API.removeEventJudge(event_id, account_id));

  updateEventStatus = (event_id: number, is_active: boolean) =>
    this.withAPI((API) => API.updateEventStatus(event_id, is_active));

  updateEventFee = (
    event_id: number,
    fee: { token_id: string; amount: string } | null
  ) => this.withAPI((API) => API.updateEventFee(event_id, fee));

  withdrawEventProjectFee = (
    event_id: number,
    project_id: number,
    receiver_id: string
  ) =>
    this.withAPI((API) =>
      API.withdrawEventProjectFee(event_id, project_id, receiver_id)
    );
}

export default new PTService();
