import withNavbar from './components/withNavBar';
import { Event } from './Event/Event';
import { EventActors } from './EventActors/EventActors';
import { EventFee } from './EventFee/EventFee';
import { EventSchedule } from './EventSchedule/EventSchedule';
import { EventServer } from './EventServer/EventServer';
import { EventsList } from './EventsList/EventsList';
import { EventSubmissions } from './EventSubmissions/EventSubmissions';
import { HackathonSettings } from './HackathonSettings/HackathonSettings';

const EventsListPage = withNavbar(EventsList);
const EventPage = withNavbar(Event);
const EventServerPage = withNavbar(EventServer);
const EventActorsPage = withNavbar(EventActors);
const EventFeePage = withNavbar(EventFee);
const EventSubmissionsPage = withNavbar(EventSubmissions);
const EventSchedulePage = withNavbar(EventSchedule);
const HackathonSettingsPage = withNavbar(HackathonSettings);

export {
  EventsListPage,
  EventPage,
  EventActorsPage,
  EventFeePage,
  EventSubmissionsPage,
  EventSchedulePage,
  EventServerPage,
  HackathonSettingsPage,
};
