import Typography from '@mui/material/Typography';
import { EEventSubmissionStatus } from '@pitchtalk/contract-api-js/dist/interfaces';
import { theme } from 'shared/components/theme';

export const SubmissionStatus: React.FC<{ status: EEventSubmissionStatus }> = ({
  status,
}) => (
  <>
    {status === EEventSubmissionStatus.APPROVED && (
      <Typography
        sx={{
          fontSize: '1.25rem',
          height: '36px',
          width: '125px',
          textAlign: 'center',
          border: `2px solid ${theme.palette.success.main}`,
          borderRadius: '5px',
        }}
      >
        Approved
      </Typography>
    )}
    {status === EEventSubmissionStatus.REJECTED && (
      <Typography
        sx={{
          fontSize: '1.25rem',
          height: '36px',
          textAlign: 'center',
          width: '125px',
          border: `2px solid ${theme.palette.error.main}`,
          borderRadius: '5px',
        }}
      >
        Rejected
      </Typography>
    )}
  </>
);
