import { IOffChainProject } from '@pitchtalk/contract-api-js/dist/interfaces';
import { PROJECT_ID } from 'routes/routes';
import { parseData } from 'services/api/utils';
import { IProject } from 'shared/interfaces/backendInterfaces';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export const fetchProjects = (limit: number, page = 0) =>
  fetchAPI.get<IProject[]>(`${API_ROUTES.projects}?limit=${limit}&page=${page}`)
    .then(parseData);

export const fetchProject = (id: string) =>
    fetchAPI.get<IProject>(`${API_ROUTES.projects}/${id}`)
      .then(parseData);

export const fetchProjectsCount = () =>
  fetchAPI.get<number>(API_ROUTES.projectsCount).then(parseData);

export const registerProject = (data: any) =>
  fetchAPI.post(API_ROUTES.projectsCreate, data).then(parseData);

export const fetchUpdateProject = (id: string, data: any) => 
  fetchAPI.post(API_ROUTES.projectsUpdate.replace(PROJECT_ID, id), data).then(parseData);

export const fetchApproveProjectSubmission = (
  projectId: string,
  comment?: string
) =>
  fetchAPI
    .post(API_ROUTES.approveProjectSubmission.replace(PROJECT_ID, projectId), {
      comment,
    })
    .then(parseData);

export const fetchRejectProjectSubmission = (
  projectId: string,
  comment?: string
) =>
  fetchAPI
    .post(API_ROUTES.rejectProjectSubmission.replace(PROJECT_ID, projectId), {
      comment,
    })
    .then(parseData);
