import {
  IPartner,
  IPitchtalkMetadata,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from 'shared/utils/reduxUtils';

import { RootState } from '../store';

export const SLICE_PREFIX = 'metaData';

export interface IMetaDataState {
  partners: IPartner[];
  pitchTalkMetaData: IPitchtalkMetadata | null;
  isLoading: boolean;
}

const initialState: IMetaDataState = {
  partners: [] as IPartner[],
  pitchTalkMetaData: null,
  isLoading: false,
};

const metaDataSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    setPartners: (state, { payload }: PayloadAction<IPartner[]>) => {
      state.partners = payload;
    },
    setPartnerById: (state, { payload }: PayloadAction<IPartner>) => {
      let partner = state.partners.find((partner) => partner.id === payload.id);
      if (partner) {
        partner = payload;
      } else {
        state.partners.push(payload);
      }
    },
    setPitchTalkMetaData: (
      state,
      { payload }: PayloadAction<IPitchtalkMetadata | null>
    ) => {
      state.pitchTalkMetaData = payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setPartners, setPartnerById, setPitchTalkMetaData } =
  metaDataSlice.actions;

export const selectPartners = (state: RootState) => state.metaData.partners;

export const selectPitchtalkMetaData = (state: RootState) =>
  state.metaData.pitchTalkMetaData;

export const selectPartnerById = createSelector(
  selectPartners,
  (_: RootState, id: string) => id,
  (partners: IPartner[], id: string): IPartner =>
    partners?.find((partner) => partner.id === id) ?? {
      id: '',
      name: '',
      url: '',
      image: '',
    }
);

export const selectMetaDataIsLoading = (state: RootState) =>
  state.metaData.isLoading;

export const metaDataReducer = metaDataSlice.reducer;
