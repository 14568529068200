import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import {
  EEventSubmissionStatus,
  EventAccessFeeTypePayed,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { IEvent } from 'shared/interfaces/backendInterfaces';
import {
  IEventProjectParticipation,
  IRefereeParticipation,
} from 'store/interfaces/events';

import { getAmountFormatted } from './near';

export const isEventStarted = (event?: IEvent | null): boolean =>
  !!(event?.start_date && new Date() > new Date(event.start_date));

export const isEventEnded = (event?: IEvent | null): boolean =>
  !!(event?.end_date && new Date() > new Date(event.end_date));

export const isEventVoteEnded = (event?: IEvent | null): boolean =>
  !!(event?.vote_end_date && new Date() > new Date(event.vote_end_date));

export const getEventFeeFormatted = (
  fee: EventAccessFeeTypePayed,
  tokenMeta: ITokenMetadata
) =>
  tokenMeta?.decimals
    ? getAmountFormatted(fee.amount, tokenMeta.decimals, 0) +
      ' ' +
      tokenMeta.symbol
    : 0;

export const sortEventSubmissionsByStatus = (
  a: IEventProjectParticipation | IRefereeParticipation,
  b: IEventProjectParticipation | IRefereeParticipation
) => {
  const getStatusNumb = (status: EEventSubmissionStatus) => {
    if (status === EEventSubmissionStatus.OPEN) {
      return 1;
    }
    if (status === EEventSubmissionStatus.APPROVED_PENDING) {
      return 2;
    }
    return 3;
  };

  return getStatusNumb(a.status) - getStatusNumb(b.status);
};

export const checkIsNewEventSubmissions = (
  submissions: IEventProjectParticipation[] | IRefereeParticipation[]
) =>
  submissions.some(
    (submission) => submission.status === EEventSubmissionStatus.OPEN
  );
