import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { SxProps } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { theme } from '../theme';

type SettingsDatePickerProps = {
  label: string;
  value: Date;
  onChange: (e: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  error?: boolean;
  helperText?: string | boolean;
  readOnly?: boolean;
  sx?: SxProps;
  isHighlighted?: boolean;
};

export const highlightedSx = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.success.main,
    },
  },
};

export const SettingsDatePicker: React.FC<SettingsDatePickerProps> = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  error,
  helperText,
  readOnly,
  sx,
  isHighlighted,
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormGroup>
        <FormControlLabel
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 0,
            ...sx,
            ...(isHighlighted ? highlightedSx : {}),
          }}
          control={
            <DateTimePicker
              value={value}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} error={error} helperText={helperText} />
              )}
              onChange={onChange}
              minDateTime={minDate ? new Date(minDate) : undefined}
              maxDateTime={maxDate ? new Date(maxDate) : undefined}
              readOnly={readOnly}
            />
          }
          label={label}
          labelPlacement="start"
        />
      </FormGroup>
    </LocalizationProvider>
  );
};
