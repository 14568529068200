import { memo } from 'react';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';

interface IEventDescriptionNewProps {
  onAdd: () => void;
  disabled: boolean;
}
export const EventDescriptionNew: React.FC<IEventDescriptionNewProps> = memo(
  ({ onAdd, disabled }) => (
    <PrimaryButton
      sx={{
        width: '100%',
        mt: 1,
        mb: 1,
        marginInline: 'auto',
        maxWidth: '29%',
        height: '30px',
      }}
      onClick={onAdd}
      disabled={disabled}
    >
      Add New Description Item
    </PrimaryButton>
  )
);
