/* eslint-disable no-console */
import {
  EProjectType,
  IEventScheduleCheckpoint,
  IHackakathonPrize,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { compose } from '@reduxjs/toolkit';
import { IServerEventArgs } from 'pages/EventsPage/EventServer/types';
import { IHackakathonTaskState } from 'pages/EventsPage/HackathonSettings/components/Tasks';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import * as eventsAPI from 'services/api-endpoints/eventsAPI';
import { ToastService } from 'services/toast/ToastService';
import {
  setEventRefereeSubmissions,
  setEventProjectsSubmissions,
  setServerEvent,
  updateEventsTableCount,
  setEvent,
  setEventHackathon,
  setEventSchedule,
  IEventCombined,
} from 'store/slices/events';
import { AppDispatch } from 'store/store';

import * as Args from './types';

export const getTableCount = () => eventsAPI.fetchEventsCount()
  // PTService.getEventsCount().then((count) => updateEventsTableCount(count));

// SUBMISSIONS
export const getEventProjectsSubmissions =
  (eventId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventProjectsSubmissions(eventId)
      .then((submissions) => ({ eventId, submissions }))
      .then(compose(dispatch, setEventProjectsSubmissions))
      .catch(console.error);

export const getEventRefereeSubmissions =
  (eventId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventRefereeSubmissions(eventId)
      .then((submissions) => ({ eventId, submissions }))
      .then(compose(dispatch, setEventRefereeSubmissions))
      .catch(console.error);

// APPROVE
export const approveEventProjectSubmission =
  (eventId: string, submissionId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .pendingEventParticipation(submissionId)
      .then(() => dispatch(getEventProjectsSubmissions(eventId)))
      .catch(console.error);

export const pendingEventRefereeSubmission =
  (eventId: string, refereeId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchPendingEventRefereeSubmission(refereeId)
      .then(() => dispatch(getEventRefereeSubmissions(eventId)))
      .catch(console.error);

export const addEventProject =
  (
    eventId: string,
    projectId: string | number,
    projectType: EProjectType
  ) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchAddEventParticipation(eventId, projectId, projectType)
      .then(() => dispatch(getEventProjectsSubmissions(eventId)))
      .catch(console.error);

export const addEventReferee =
  (eventId: string, accountId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchAddEventReferee(eventId, accountId)
      .then(() => dispatch(getEventRefereeSubmissions(eventId)))
      .catch(console.error);

// REJECT
export const rejectEventProjectSubmission =
  (eventId: string, submissionId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .rejectEventParticipation(submissionId)
      .then(() => dispatch(getEventProjectsSubmissions(eventId)))
      .catch(console.error);

export const rejectEventRefereeSubmission =
  (eventId: string, refereeId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchRejectEventRefereeSubmission(refereeId)
      .then(() => dispatch(getEventRefereeSubmissions(eventId)))
      .catch(console.error);

// CLOSE SUBMISSION REQUEST
export const approveRefereeSubmission =
  (eventId: string, refereeId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .approveRefereeSubmission(refereeId)
      .then(() => dispatch(getEventRefereeSubmissions(eventId)))
      .catch(console.error);

export const closeProjectSubmission =
  (eventId: string, submissionId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .approveEventParticipation(submissionId)
      .then(() => dispatch(getEventProjectsSubmissions(eventId)))
      .catch(console.error);

// EVENT
export const getServerEventById =
  (eventId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventById(eventId)
      .then(compose(dispatch, setServerEvent))
      .catch(console.error);

// export const getServerEventByOnChainId =
//   (eventId: number) => (dispatch: AppDispatch) =>
//     eventsAPI
//       .fetchEventByOnChainId(eventId)
//       .then(compose(dispatch, setServerEvent))
//       .catch(console.error);

export const createServerEvent =
  (event: IServerEventArgs) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchCreateEvent(event)
      .then(({ id }) => dispatch(getServerEventById(id)))
      .then(() => ToastService.success('', 'Event successfully created'))
      .catch(() => ToastService.error('', 'Event create error'));

export const updateServerEvent =
  (eventId: string, event: Partial<IServerEventArgs>) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchUpdateEvent(eventId, event)
      .then(({ id }) => dispatch(getServerEventById(id)))
      .then(() => ToastService.success('', 'Event successfully updated'))
      .catch(() => ToastService.error('', 'Event update error'));

export const updateEventPartners =
  (eventId: string, partners: string[]) => (dispatch: AppDispatch) =>
      eventsAPI
        .updateEventPartners(eventId, partners)
        .then(({ id }) => dispatch(getServerEventById(id)))
        .then(() => ToastService.success('', 'Event successfully updated'))
        .catch(() => ToastService.error('', 'Event update error'));

export const updateEventIsActive =
  (eventId: string, isActive: boolean) => (dispatch: AppDispatch) =>
    eventsAPI
      .updateEventIsActive(eventId, isActive)
      .then(({ id }) => dispatch(getServerEventById(id)))
      .then(() =>
        ToastService.success(
          '',
          `Event successfully updated. Event is ${isActive ? '' : 'NOT'} Active`
        )
      )
      .catch(() => ToastService.error('', 'Event update error'));

export const saveEventSchedule =
  (eventId: string, schedule: IEventScheduleCheckpoint[]) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchSaveEventSchedule(eventId, schedule)
      .then(() => dispatch(getServerEventById(eventId)));

// HACKATHON
export const updateHackathonTasks =
  (offChainEventId: string, tasks: IHackakathonTaskState[]) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchHackathonTasks(offChainEventId, tasks)
      .then(() => dispatch(getEventHackathon({ offChainEventId })));

export const updateHackathonPrizes =
  (offChainEventId: string, prizes: IHackakathonPrize[]) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchHackathonPrizeDetails(offChainEventId, prizes)
      .then(() => dispatch(getEventHackathon({ offChainEventId })));

export const getEventById = (eventId: string) => (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventById(eventId)
      .then((serverEvent) => dispatch(setEvent({ ...serverEvent as unknown as IEventCombined } ))
  );

export const getEventSchedule =
  ({ offChainEventId }: Args.IGetEventSchedule) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventSchedule(offChainEventId)
      .then((schedule) => dispatch(setEventSchedule({ eventId: offChainEventId, schedule })));

export const getEventHackathon =
  ({ offChainEventId }: Args.IGetEventHackathon) =>
  (dispatch: AppDispatch) =>
    eventsAPI
      .fetchEventHackathon(offChainEventId)
      .then((hackathon) => dispatch(setEventHackathon({ eventId: offChainEventId, hackathon })));
