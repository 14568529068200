import { IOffChainUserData } from '@pitchtalk/contract-api-js/dist/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface IChatData {
  moderators: IOffChainUserData[];
  isLoading: boolean;
}

const initialState: IChatData = {
  moderators: [],
  isLoading: false,
};

const chatSlice = createSlice({
  name: 'chatData',
  initialState,
  reducers: {
    setModerators: (state, action: PayloadAction<IOffChainUserData[]>) => {
      state.moderators = action.payload;
    },
    setChatIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setModerators, setChatIsLoading } = chatSlice.actions;

export const selectModerators = (state: RootState) => state.chat.moderators;
export const selectIsChatLoading = (state: RootState) => state.chat.isLoading;

export const chatsReducer = chatSlice.reducer;
