import {
  IEvent,
  IEventScheduleCheckpoint,
  // IServerEvent,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEventHackathon } from 'services/api-endpoints/eventsAPI';
import { IEventProjectParticipation, IEventTableItem, IRefereeParticipation } from 'store/interfaces/events';

export type IEventData = IEvent & {
  serverEvent: IServerEvent;
};

export interface IServerEvent {
  id: string;
  on_chain_id?: number;

  is_active: boolean;

  name: string;
  logo: string;
  banner: string;
  web_url: string;
  partners: string;
  video_url: string;
  description: string;
  short_description: string;

  prize_pool: string;

  votes_limit?: number;

  type: any;

  end_date: Date;
  start_date: Date;
  vote_end_date: Date;

  created_at: Date;
  updated_at: Date;

  schedule: string[] | any[];

  projects: string[] | any[];

  participants: string[] | IEventProjectParticipation[];
  referees: string[] | IRefereeParticipation[];
  hackathon_settings: string | any | null;
  social_links: string | any | null;
  likes: string[] | any[] | null;
  comments: string[] | any[] | null;
}
export type IEventCombined = Partial<IEvent & {
  serverEvent?: IServerEvent;
} & IServerEvent>

export interface IEventSubmissions {
  referee: IRefereeParticipation[];
  projects: IEventProjectParticipation[];
}

export interface IEventsData {
  events: {
    // data: Record<string, IEvent>;
    offChainData: Record<string, IEventCombined>;
    table: {
      count: number;
      data: IEventTableItem[];
      page: number;
      rowsPerPage: number;
      isLoading: boolean;
    };
  };
  submissions: Record<string, IEventSubmissions>;
  schedules: Record<string, IEventScheduleCheckpoint[]>;
  hackathons: Record<string, IEventHackathon>;
}

const initialState: IEventsData = {
  events: {
    // data: {} as Record<string, IEventCombined>,
    offChainData: {} as Record<string, IEventCombined>,
    table: {
      count: 0,
      data: [],
      page: 0,
      rowsPerPage: 10,
      isLoading: true,
    },
  },
  submissions: {} as Record<string, IEventSubmissions>,
  schedules: {} as Record<string, IEventScheduleCheckpoint[]>,
  hackathons: {} as Record<string, IEventHackathon>,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvent: (state, { payload }: PayloadAction<IEventCombined>) => {
      state.events.offChainData[payload?.id as string] = payload;
    },
    setEvents: (state, { payload }: PayloadAction<IEventCombined[]>) => {
      state.events.offChainData = payload.reduce(
        (acc, event) => ({ ...acc, [event?.id as string]: event }),
        { ...state.events.offChainData }
      ) as Record<string, Partial<IEvent & {
        serverEvent: IServerEvent;
    } & IServerEvent>>;
    },
    setServerEvent: (state, { payload }: PayloadAction<IEventCombined>) => {
      // state.events.data[payload.on_chain_id].serverEvent = payload;
      state.events.offChainData[payload.id as string] = payload;
    },
    setEventProjectsSubmissions: (
      state,
      action: PayloadAction<{
        eventId: string;
        submissions: [];
      }>
    ) => {
      state.submissions[action.payload.eventId] = {
        ...state.submissions[action.payload.eventId],
        projects: action.payload.submissions,
      };
    },
    setEventRefereeSubmissions: (
      state,
      action: PayloadAction<{
        eventId: string;
        submissions: IRefereeParticipation[];
      }>
    ) => {
      state.submissions[action.payload.eventId] = {
        ...state.submissions[action.payload.eventId],
        referee: action.payload.submissions,
      };
    },

    // Table
    updateEventsTableData: (
      state,
      action: PayloadAction<IEventTableItem[]>
    ) => {
      state.events.table.data = action.payload;
    },
    updateEventsTablePage: (state, { payload }: PayloadAction<number>) => {
      state.events.table.page = payload;
    },
    updateEventsTableCount: (state, { payload }: PayloadAction<number>) => {
      state.events.table.count = payload;
    },
    updateEventsTableRowsPerPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.events.table.rowsPerPage = payload;
    },
    updateEventsIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.events.table.isLoading = payload;
    },
    setEventSchedule: (
      state,
      action: PayloadAction<{
        eventId: string;
        schedule: IEventScheduleCheckpoint[];
      }>
    ) => {
      state.schedules[action.payload.eventId] = action.payload.schedule;
    },
    setEventHackathon: (
      state,
      action: PayloadAction<{ eventId: string; hackathon: IEventHackathon }>
    ) => {
      state.hackathons[action.payload.eventId] = action.payload.hackathon;
    },
  },
});

export const {
  setEvent,
  setEvents,
  setEventProjectsSubmissions,
  setEventRefereeSubmissions,
  setServerEvent,

  updateEventsTableData,
  updateEventsTableCount,
  updateEventsTablePage,
  updateEventsTableRowsPerPage,
  updateEventsIsLoading,

  setEventSchedule,
  setEventHackathon,
} = eventsSlice.actions;

export const eventsReducer = eventsSlice.reducer;
