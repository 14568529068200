/* eslint-disable no-console */
import { compose, createAsyncThunk } from '@reduxjs/toolkit';
import * as tagsAPI from 'services/api-endpoints/tagsAPI';
import { SLICE_PREFIX, setTags } from 'store/slices/tags';

export const getTags = createAsyncThunk(
  SLICE_PREFIX + '/getTags',
  (_, { dispatch }) =>
    tagsAPI.fetchTagsCount().then((amount) =>
      tagsAPI.fetchTags(amount)
        .then(compose(dispatch, setTags)).catch(console.error))
)

export const addTag = createAsyncThunk(
  SLICE_PREFIX + '/addTag',
  (name: string, { dispatch }) =>
    tagsAPI.fetchAddNewTag(name).then(() => dispatch(getTags()))
);

export const editTag = createAsyncThunk(
  SLICE_PREFIX + '/editTag',
  ({ id, name }: { id: string, name: string }, { dispatch }) =>
    tagsAPI.fetchEditTag(id, name).then(() => dispatch(getTags()))
);

export const removeTag = createAsyncThunk(
  SLICE_PREFIX + '/removeTag',
  (id: string, { dispatch }) =>
    tagsAPI.fetchRemoveTag(id).then(() => dispatch(getTags()))
);
