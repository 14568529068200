import { Backdrop, Box, Modal, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ipfsService } from 'services/ipfs';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { Loader } from 'shared/components/Loader/Loader';
import { IUploadImageModal } from 'shared/interfaces/modals';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { invalidUrl } from 'shared/utils/validationsUtils';
import YUP from 'shared/utils/yupUtils';

import { EMPTY_STRING } from '../../../constants';
import { PreviewImage } from '../../PreviewImage/PreviewImage';

const style = {
  position: 'absolute' as const,
  maxHeight: '95vh',
  minWidth: '365px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 64px',
  boxShadow: 24,
  borderRadius: '10px',
  overflowY: 'scroll',
};

const isImgUrl = async (url: string): Promise<boolean> => {
  return fetch(url, { method: 'HEAD' }).then((res) => {
    return !!res?.headers?.get('Content-Type')?.startsWith('image');
  });
};

const validationSchema = YUP.object({
  image: YUP.string().startWithHTTP().url(invalidUrl),
});

type ProjectPicturesForm = {
  image: string;
};

export const UploadImageModal: React.FC<IUploadImageModal> = ({
  closeModal,
  submitImage,
  image = EMPTY_STRING,
  imageName = EMPTY_STRING,
}) => {
  const correctPictures = getCorrectIPFSLinks({
    image,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [exitCheck, setExitCheck] = useState(false);

  const {
    errors,
    touched,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    values,
    isValid,
    setErrors,
  } = useFormik<ProjectPicturesForm>({
    initialValues: {
      image: correctPictures.image,
    },
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const [imageFile, setImageFile] = useState<File | null>();
  const [imageLink, setImageLink] = useState(correctPictures.image);
  const imageRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!imageFile) return;
    const imageUrl = URL.createObjectURL(imageFile);
    setImageLink(imageUrl);
  }, [imageFile]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const _imageName = imageName || imageFile?.name;
      const imageCid = imageFile
        ? await ipfsService.uploadFile([imageFile], {
            name: _imageName,
          })
        : EMPTY_STRING;

      submitImage(imageCid);
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreview = async () => {
    try {
      const url = values.image || EMPTY_STRING;
      const isValidImage = await isImgUrl(url);
      if (!isValidImage) throw new Error('Can`t upload data');
      const data = await fetch(url);
      const blob = await data.blob();
      const file = new File([blob], 'uploaded_file');

      setImageFile(file);
      setImageLink(values.image || EMPTY_STRING);
    } catch (error) {
      setErrors({
        image: 'Upload image Error',
      });
      console.error('error');
    }
  };

  const uploadFile = (inputRef: MutableRefObject<HTMLInputElement | null>) => {
    if (!inputRef?.current) return;
    inputRef.current.click();
  };

  const { imageError, disablePreviewButton } = getImagesMeta(
    errors,
    touched,
    values,
    correctPictures
  );

  const preventCloseModal = () => {
    if (exitCheck) closeModal();
    else setExitCheck(true);
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Box sx={style}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Box
              style={{
                fontSize: '1.125rem',
                color: 'white',
                marginBottom: '48px',
                textAlign: 'center',
                textTransform: 'uppercase',
              }}
            >
              Add the image
            </Box>
            <Box
              style={{
                display: 'flex',
                gap: '30px',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '25px',
                }}
              >
                <Typography>Project Image Link</Typography>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  name="project-banner"
                  accept="image/*"
                  ref={imageRef}
                  onChange={() => {
                    setImageFile(imageRef?.current?.files?.[0]);
                  }}
                />
                <Box
                  style={{
                    alignItems: 'center',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      placeholder="type url"
                      type="url"
                      value={values.image}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('image', true);
                        setFieldValue('image', e.target.value.trim());
                      }}
                      error={!!errors.image}
                      helperText={errors?.image && errors?.image}
                      InputProps={{
                        style: {
                          border: '1px solid #653EE2',
                          borderRadius: 1,
                          color: 'white',
                        },
                      }}
                    />
                    <PrimaryButton
                      disabled={
                        disablePreviewButton || values.image === imageLink
                      }
                      onClick={() => handlePreview()}
                    >
                      Preview
                    </PrimaryButton>
                  </Box>
                  {imageError && (
                    <Typography
                      style={{
                        position: 'absolute',
                        marginTop: 42,
                        top: 0,
                      }}
                    >
                      {errors?.image || EMPTY_STRING}
                    </Typography>
                  )}
                </Box>
                <Typography
                  sx={{
                    textAlign: 'center',
                    marginTop: '15px',
                  }}
                >
                  OR
                </Typography>
                <PrimaryButton
                  onClick={() => uploadFile(imageRef)}
                  style={{
                    margin: '20px 0 auto',
                    width: '100%',
                  }}
                >
                  Upload File
                </PrimaryButton>
              </Box>

              <Box>
                <PreviewImage image={imageLink} />
                <Box
                  sx={{
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {!exitCheck ? (
                    <>
                      <PrimaryButton
                        disabled={!isValid || isLoading || !imageFile}
                        onClick={handleSubmit}
                      >
                        Submit files
                      </PrimaryButton>
                      <PrimaryButton onClick={() => setExitCheck(true)}>
                        Cancel
                      </PrimaryButton>
                    </>
                  ) : (
                    <>
                      <Typography>Are you sure?</Typography>
                      <Box>
                        <PrimaryButton
                          style={{
                            width: '120px',
                            marginRight: '10px',
                          }}
                          onClick={() => setExitCheck(false)}
                        >
                          Continue
                        </PrimaryButton>
                        <PrimaryButton onClick={() => closeModal()}>
                          Close
                        </PrimaryButton>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

function getImagesMeta(
  errors: FormikErrors<ProjectPicturesForm>,
  touched: FormikTouched<ProjectPicturesForm>,
  values: ProjectPicturesForm,
  correctPictures: { image?: string }
): {
  imageError: any;
  disablePreviewButton: any;
} {
  const imageError = errors.image && touched.image;

  const disablePreviewButton =
    !!imageError ||
    !values.image ||
    !touched.image ||
    correctPictures.image === values.image;

  return {
    imageError,
    disablePreviewButton,
  };
}
