import Box from '@mui/material/Box';
import { memo } from 'react';
import { SettingsBox } from 'shared/components/SettingsBox';

import { EventDescriptionItem } from './EventDescriptionItem';
import { IEventDescription } from '../types';

interface IEventDescriptionPreviewProps {
  items: Record<string, IEventDescription>;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  editId: string;
  isEditDisabled: boolean;
}

export const EventDescriptionPreview: React.FC<IEventDescriptionPreviewProps> =
  memo(({ items, onEdit, onDelete, editId, isEditDisabled }) =>
    Object.keys(items).length ? (
      <SettingsBox
        legend="Description Preview"
        sx={{
          padding: '10px',
          width: 'fit-content',
          height: 'fit-content',
          maxWidth: '1167px',
          '& li': { listStyle: 'unset' },
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {Object.entries(items).map(([id, { text, title }]) => (
            <EventDescriptionItem
              key={id}
              id={id}
              title={title}
              text={text}
              onEdit={onEdit}
              onDelete={onDelete}
              isEditing={editId === id}
              isDisabled={isEditDisabled}
            />
          ))}
        </Box>
      </SettingsBox>
    ) : (
      <></>
    )
  );
