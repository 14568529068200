import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { EventTypes } from '@pitchtalk/contract-api-js/dist/interfaces';
import { FormikProps } from 'formik';
import { SELECT_MENU_PROPS } from 'shared/utils/themeUtils';

import { EventSettings } from '../helpers';

interface IEventTypeProps {
  formik: FormikProps<EventSettings>;
  isReadOnly: boolean;
}

const EVENT_TYPES = [
  { type: EventTypes.LECTURE, label: 'Lecture (Pitch Session)' },
  { type: EventTypes.ROUND_TABLE, label: 'Round table' },
  { type: EventTypes.TOURNAMENT, label: 'Tournament (DemoDay)' },
  { type: EventTypes.HACKATHON, label: 'Hackathon' },
];

export const EventType: React.FC<IEventTypeProps> = ({
  formik,
  isReadOnly,
}) => (
  <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
    <InputLabel id="event-type-label">Event type</InputLabel>
    <Select
      labelId="event-type-label"
      id="event-type-label-select"
      value={formik.values.type}
      onChange={(e) => formik.setFieldValue('type', e.target.value)}
      input={<OutlinedInput label="Event type" />}
      MenuProps={SELECT_MENU_PROPS}
      readOnly={isReadOnly}
    >
      {EVENT_TYPES.map(({ type, label }, i) => (
        <MenuItem key={type + label + i} value={type}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
