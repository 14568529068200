import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useRefreshButton } from 'services/hooks/useRefreshButton';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { AppDispatch } from 'store/store';

interface IRefreshButtonProps {
  updateThunk: () => (dispatch: AppDispatch) => Promise<unknown>;
}

const RefreshButton: React.FC<IRefreshButtonProps> = ({ updateThunk }) => {
  const { refreshData, refreshProgress } = useRefreshButton(updateThunk);

  return (
    <PrimaryButton onClick={() => refreshData()}>
      <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        Refresh manualy
        <CircularProgress
          color="secondary"
          size="1.25rem"
          value={refreshProgress}
          variant="determinate"
        />
      </p>
    </PrimaryButton>
  );
};

export default RefreshButton;
