import { fetchAPI } from 'services/api/ServerAPI';
import { ToastService } from 'services/toast/ToastService';
import { IEventSchedule } from 'shared/interfaces/backendInterfaces';

export const saveEventCheckpoints = (
  eventId: string,
  schedule: IEventSchedule[]
) =>
  fetchAPI
    .post(`event/${eventId}/schedule`, schedule)
    .then(() => ToastService.success('', 'Schedule successfully saved'))
    .catch(() => ToastService.error('', 'Schedule save error'));
