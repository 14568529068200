import Box from '@mui/material/Box';
import { EEventSubmissionStatus } from '@pitchtalk/contract-api-js/dist/interfaces';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';

interface ISubmissionControlsProps {
  onApprove: () => void;
  onReject: () => void;
  onCloseRequest: () => void;
  onTryAgain: () => void;
  isDisabled: boolean;
  status: EEventSubmissionStatus;
  isParticipantAdded: boolean;
}

export const SubmissionControls: React.FC<ISubmissionControlsProps> = ({
  onApprove,
  onReject,
  onCloseRequest,
  onTryAgain,
  isDisabled,
  status,
  isParticipantAdded,
}) => (
  <Box sx={{ display: 'flex', width: '100%', gap: '15px' }}>
    {status === EEventSubmissionStatus.OPEN && (
      <>
        <PrimaryButton disabled={isDisabled} onClick={onApprove}>
          Approve
        </PrimaryButton>
        <PrimaryButton disabled={isDisabled} onClick={onReject}>
          Reject
        </PrimaryButton>
      </>
    )}
    {status === EEventSubmissionStatus.APPROVED_PENDING &&
      isParticipantAdded && (
        <PrimaryButton disabled={isDisabled} onClick={onCloseRequest}>
          Close request
        </PrimaryButton>
      )}
    {status === EEventSubmissionStatus.APPROVED_PENDING &&
      !isParticipantAdded && (
        <PrimaryButton disabled={isDisabled} onClick={onTryAgain}>
          Try again
        </PrimaryButton>
      )}
  </Box>
);
