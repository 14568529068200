import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ITag } from '@pitchtalk/contract-api-js/dist/interfaces';
import { format } from 'date-fns';
import { isNull } from 'lodash';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { APP_ROUTES, EVENT_ID } from 'routes/routes';
import { pitchTalkAppUrl } from 'services/config';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { Link } from 'services/router';
import { DEFAULT_DATE_FORMAT } from 'shared/constants';
import { IEvent } from 'shared/interfaces/backendInterfaces';
import { selectTags } from 'store/slices/tags';

interface IEventsTableBodyProps {
  events: IEvent[];
  isLoading: boolean;
  columnsCount: number;
}
const MAX_TAGS_SHOW = 3;

const getTableRowData = (event: IEvent, tags: ITag[]) => [
  <Box
    sx={{
      borderRadius: '5px',
      textAlign: 'center',
      marginInline: '5px',
      paddingBlock: '2px',
      color: 'black',
      border: `2px solid ${event.is_active ? '#16AF4A' : '#E23E3E'}`,
    }}
  >
    <a
      href={`https://${pitchTalkAppUrl}/events/${event.id.toString()}`}
      target="_blank"
      data-tooltip-id={`event-${event.id}`}
      data-tooltip-content={event.id}
    >
      Id
    </a>
    <Tooltip clickable id={`event-${event.id}`} />
  </Box>,
  <Box>
    {event.publicRequest && (
      <Box sx={{ color: 'orange', fontWeight: 600 }}>Public Request</Box>
    )}
    <Box>{event.is_public ? 'Public' : 'Private'}</Box>
  </Box>,
  <Box>
    <Link to={APP_ROUTES.EVENTS_BY_ID.replace(EVENT_ID, event.id.toString())}>
      {event.name}
    </Link>
  </Box>,
  <Box>{format(new Date(event?.start_date), DEFAULT_DATE_FORMAT)}</Box>,
  <Box>{format(new Date(event?.end_date), DEFAULT_DATE_FORMAT)}</Box>,
  <Box>{format(new Date(event?.vote_end_date), DEFAULT_DATE_FORMAT)}</Box>,
  <Box>Free</Box>,
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    {event?.tags?.length ? (
      event?.tags?.slice(0, MAX_TAGS_SHOW).map((value) => {
        const tag = tags.find((t) => t.id === value);
        return (
          tag && <Chip key={tag.id} sx={{ height: '28px' }} label={tag.name} />
        );
      })
    ) : (
      <span>None</span>
    )}
  </Box>,
  <Box>
    {isNull(event.participants_limit) ? 'Unlimited' : event.participants_limit}
  </Box>,
];

export const EventsTableBody: React.FC<IEventsTableBodyProps> = ({
  events,
  isLoading,
  columnsCount,
}) => {
  const tags = useAppSelector(selectTags);

  return (
    <>
      {isLoading
        ? Array(10)
            .fill(0)
            .map((_, ind) => (
              <TableRow key={ind}>
                <TableCell colSpan={columnsCount}>
                  <Skeleton variant="rounded" />
                </TableCell>
              </TableRow>
            ))
        : events.map((event, ind) => (
            <TableRow
              key={event.name + ind}
              sx={{
                position: 'relative',
                border: event.publicRequest
                  ? '2px solid orange'
                  : '1px solid transparent',
              }}
            >
              {getTableRowData(event, tags).map((data, ind) => (
                <TableCell align="center" key={ind}>
                  {data}
                </TableCell>
              ))}
            </TableRow>
          ))}
    </>
  );
};
