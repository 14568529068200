import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { EEventSubmissionStatus } from '@pitchtalk/contract-api-js';
import { EventTypes } from '@pitchtalk/contract-api-js/dist/interfaces/events';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { APP_ROUTES, EVENT_ID } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { NavLink, useLocation, useParams } from 'services/router';
import { Loader } from 'shared/components/Loader/Loader';
import { checkIsNewEventSubmissions } from 'shared/utils/eventsUtils';
import { getLinkStyles } from 'shared/utils/themeUtils';
import {
  selectEventById,
  selectEventSubmissions,
} from 'store/selectors/events';
import {
  getEventById,
  getEventProjectsSubmissions,
  getEventRefereeSubmissions,
} from 'store/thunks/events';

const EVENTS_NAVBAR_LABELS = {
  [APP_ROUTES.EVENTS]: 'List of events',
  [APP_ROUTES.EVENTS_NEW]: 'Add new event',
  [APP_ROUTES.EVENTS_BY_ID]: 'View/Edit event',
};

const EVENTS_SUB_NAVBAR_LABELS = {
  [APP_ROUTES.EVENTS_BY_ID]: 'General Settings',
  [APP_ROUTES.EVENTS_BY_ID_SERVER]: 'Server Event Settings',
  [APP_ROUTES.EVENTS_BY_ID_SERVER_HACKATHON]: 'Hackathon Settings',
  [APP_ROUTES.EVENTS_BY_ID_ACTORS]: 'Participants settings',
  [APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS]: 'Submissions',
  [APP_ROUTES.EVENTS_BY_ID_SCHEDULE]: 'Schedule',
  [APP_ROUTES.EVENTS_BY_ID_FEE]: 'Fee',
};

const SubNavBar: React.FC<{ eventId: string; isNewSubmissions: boolean }> = ({
  eventId,
  isNewSubmissions,
}) => {
  const event = useAppSelector((state) => selectEventById(state, eventId));
  if (!event) return <></>;

  const replaceEventId = (route: string) =>
    route.replace(EVENT_ID, eventId.toString());

  return (
    <List
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        '& a': {
          fontFamily: 'Everett-Regular',
          fontSize: '1.125rem',
          color: 'black',
        },
        '& li:first-of-type': { paddingLeft: 0 },
      }}
    >
      <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
        <NavLink
          end
          to={
            event ? replaceEventId(APP_ROUTES.EVENTS_BY_ID) : location.pathname
          }
          style={getLinkStyles}
        >
          {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID]}
        </NavLink>
      </ListItem>
      <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
        <NavLink
          end
          to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_SERVER)}
          style={getLinkStyles}
        >
          {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID_SERVER]}
        </NavLink>
      </ListItem>
      {event && (
        <>
          {event.event_type === EventTypes.HACKATHON && (
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink
                end
                to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_SERVER_HACKATHON)}
                style={getLinkStyles}
              >
                {
                  EVENTS_SUB_NAVBAR_LABELS[
                    APP_ROUTES.EVENTS_BY_ID_SERVER_HACKATHON
                  ]
                }
              </NavLink>
            </ListItem>
          )}
          <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
            <NavLink
              end
              to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_ACTORS)}
              style={getLinkStyles}
            >
              {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID_ACTORS]}
            </NavLink>
          </ListItem>
          <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
            {isNewSubmissions ? (
              <Badge color="primary" variant="dot">
                <NavLink
                  end
                  to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS)}
                  style={getLinkStyles}
                >
                  {
                    EVENTS_SUB_NAVBAR_LABELS[
                      APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS
                    ]
                  }
                </NavLink>
              </Badge>
            ) : (
              <NavLink
                end
                to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS)}
                style={getLinkStyles}
              >
                {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS]}
              </NavLink>
            )}
          </ListItem>
          <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
            <NavLink
              end
              to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_SCHEDULE)}
              style={getLinkStyles}
            >
              {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID_SCHEDULE]}
            </NavLink>
          </ListItem>
          {/* {!isNull(event.fee) && (
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink
                end
                to={replaceEventId(APP_ROUTES.EVENTS_BY_ID_FEE)}
                style={getLinkStyles}
              >
                {EVENTS_SUB_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID_FEE]}
              </NavLink>
            </ListItem>
          )} */}
        </>
      )}
    </List>
  );
};

const withNavbar =
  <T,>(Component: React.FC<T>) =>
  (props: JSX.IntrinsicAttributes & T) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { eventId = '' } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const isEditPage = !!(eventId && !location.pathname.includes('new'));
    const [isNewSubmissions, setIsNewSubmissions] = useState(false);
    const { projectsSubmissions, refereeSubmissions } = useAppSelector(
      (state) =>
        selectEventSubmissions(state, {
          eventId,
          subStatus: EEventSubmissionStatus.APPROVED,
        })
    );
    const event = useAppSelector((state) => selectEventById(state, eventId));

    useEffect(() => {
      if (eventId) {
        setIsLoading(true);
        dispatch(getEventById(eventId)).finally(() => setIsLoading(false));
      }
    }, [dispatch, eventId]);

    useEffect(() => {
      if (event?.id) {
        dispatch(getEventProjectsSubmissions(event.id));
        dispatch(getEventRefereeSubmissions(event.id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    useEffect(() => {
      if (!isUndefined(eventId)) {
        const isNewSubmissions =
          checkIsNewEventSubmissions(projectsSubmissions) ||
          checkIsNewEventSubmissions(refereeSubmissions);

        setIsNewSubmissions(isNewSubmissions);
      }
    }, [projectsSubmissions.length, refereeSubmissions.length, eventId]);

    return (
      <Box sx={{ marginInline: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }} component="nav">
          <List
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              '& a': {
                fontFamily: 'Everett-Regular',
                fontSize: '1.125rem',
                color: 'black',
              },
              '& li:first-of-type': { paddingLeft: 0 },
            }}
          >
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink end to={APP_ROUTES.EVENTS} style={getLinkStyles}>
                {EVENTS_NAVBAR_LABELS[APP_ROUTES.EVENTS]}
              </NavLink>
            </ListItem>
            <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
              <NavLink end to={APP_ROUTES.EVENTS_NEW} style={getLinkStyles}>
                {EVENTS_NAVBAR_LABELS[APP_ROUTES.EVENTS_NEW]}
              </NavLink>
            </ListItem>
            {isEditPage && (
              <ListItem sx={{ width: 'fit-content', marginRight: '10px' }}>
                <NavLink
                  end
                  to={APP_ROUTES.EVENTS_BY_ID.replace(EVENT_ID, eventId)}
                  style={() => getLinkStyles({ isActive: true })}
                >
                  {EVENTS_NAVBAR_LABELS[APP_ROUTES.EVENTS_BY_ID]}
                </NavLink>
              </ListItem>
            )}
          </List>
        </Box>
        <Box>
          <SubNavBar eventId={eventId} isNewSubmissions={isNewSubmissions} />
          {/* {eventId && !event?.serverEvent && (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.25rem',
                color: theme.palette.warning.main,
              }}
            >
              Please register event in the server
            </Typography>
          )} */}
          {isLoading ? <Loader /> : <Component {...props} />}
        </Box>
      </Box>
    );
  };

export default withNavbar;
