import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { SxProps } from '@mui/material/styles';

type SettingsBoxProps = {
  children: JSX.Element | React.ReactNode;
  disabled?: boolean;
  legend?: string;
  sx?: SxProps;
};

export const SettingsBox: React.FC<SettingsBoxProps> = (props): JSX.Element => {
  return (
    <FormControl
      sx={{
        '& .MuiTextField-root input': { padding: '5px 8px' },
        '& .MuiTextField-root': { width: '270px' },
        border: '1.5px solid black',
        borderRadius: '25px',
        padding: '11px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...props.sx,
      }}
      disabled={props.disabled}
      component="fieldset"
    >
      <FormLabel component="legend">{props.legend ?? ''}</FormLabel>
      {props.children}
    </FormControl>
  );
};
