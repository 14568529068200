import PreviewIcon from '@mui/icons-material/Preview';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import { memo, useState } from 'react';
import { theme } from 'shared/components/theme';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';

interface IEventPartnerPreviewProps {
  img: string;
}
export const EventPartnerPreview: React.FC<IEventPartnerPreviewProps> = memo(
  ({ img }) => {
    const [openPreview, setOpenPreview] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
      <ClickAwayListener onClickAway={() => setOpenPreview(false)}>
        <Tooltip
          title={
            <>
              <Box
                sx={
                  isLoaded
                    ? { maxWidth: '300px', maxHeight: '300px' }
                    : { width: '300px', height: '300px' }
                }
              >
                <img
                  src={getCorrectIPFSLinks({ img }).img}
                  alt="Partner Logo"
                  style={{ width: '100%', height: '100%' }}
                  onLoad={() => setIsLoaded(true)}
                />
              </Box>
            </>
          }
          open={openPreview}
          onClose={() => setOpenPreview(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom"
        >
          <PreviewIcon
            onClick={() => setOpenPreview(true)}
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    );
  }
);
