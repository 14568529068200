import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  ESocialLinksKeys,
  ESocialUserLinksKeys,
} from '@pitchtalk/contract-api-js/dist/core';
import { useFormik } from 'formik';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import {
  SettingsInput,
  SettingsInputProps,
} from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';
import { readOnlyChange } from 'shared/constants';
import { ValueOf } from 'shared/interfaces';
import { ITeamMemberSettingsModal } from 'shared/interfaces/modals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 64px',
  boxShadow: 24,
  borderRadius: '10px',
  outline: 'none',
};

const ESettingsKeys = {
  walletId: 'walletId',
  first_name: 'first_name',
  last_name: 'last_name',
  position: 'position',
  logo: 'logo',
  socials_linkedin: 'social_links.linkedin',
  socials_near_social: 'social_links.near_social',
  socials_twitter: 'social_links.twitter',
} as const;

const SettingsInputStyled = (props: SettingsInputProps) => (
  <SettingsInput
    {...props}
    inputStyles={{
      '.MuiOutlinedInput-root': {
        color: theme.palette.input.light,
        background: theme.palette.input.dark,
      },
    }}
    color="info"
    size="small"
    boxStyles={{ mb: 2 }}
  />
);

export const TeamMemberSettingsModal: React.FC<ITeamMemberSettingsModal> = ({
  closeModal,
  id,
  teamMember,
  globalFormik,
  highlightedFields,
  isControls,
}): JSX.Element => {
  const formik = useFormik({
    initialValues: teamMember,
    onSubmit: (values) => {
      globalFormik.setValues({ ...globalFormik.values, [id]: values });
      closeModal();
    },
  });

  const handleChange =
    (key: ValueOf<typeof ESettingsKeys>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      isControls ? formik.setFieldValue(key, e.target.value) : readOnlyChange;

  const handleTouch = (key: ValueOf<typeof ESettingsKeys>) => () =>
    isControls ? formik.setFieldTouched(key, true) : readOnlyChange;

  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box sx={style}>
          <SettingsInputStyled
            inputLabel="Wallet id"
            name={ESettingsKeys.walletId}
            value={id}
            onChange={readOnlyChange}
            InputProps={{ readOnly: true }}
            isHighlighted={highlightedFields?.includes(ESettingsKeys.walletId)}
          />
          <SettingsInputStyled
            inputLabel="First name"
            name={ESettingsKeys.first_name}
            value={formik.values.first_name}
            onFocus={handleTouch(ESettingsKeys.first_name)}
            onChange={handleChange(ESettingsKeys.first_name)}
            error={formik.touched.first_name && !!formik.errors.first_name}
            helperText={
              formik.touched.first_name &&
              formik.errors &&
              formik.errors.first_name
            }
            isHighlighted={highlightedFields?.includes(
              ESettingsKeys.first_name
            )}
          />
          <SettingsInputStyled
            inputLabel="Last name"
            name={ESettingsKeys.last_name}
            value={formik.values.last_name}
            onFocus={handleTouch(ESettingsKeys.last_name)}
            onChange={handleChange(ESettingsKeys.last_name)}
            error={formik.touched.last_name && !!formik.errors.last_name}
            helperText={
              formik.touched.last_name &&
              formik.errors &&
              formik.errors.last_name
            }
            isHighlighted={highlightedFields?.includes(ESettingsKeys.last_name)}
          />
          <SettingsInputStyled
            inputLabel="Position"
            name={ESettingsKeys.position}
            value={formik.values.position}
            onFocus={handleTouch(ESettingsKeys.position)}
            onChange={handleChange(ESettingsKeys.position)}
            error={formik.touched.position && !!formik.errors.position}
            helperText={
              formik.touched.position && formik.errors && formik.errors.position
            }
            isHighlighted={highlightedFields?.includes(ESettingsKeys.position)}
          />
          <SettingsInputStyled
            inputLabel="Image"
            name={ESettingsKeys.logo}
            value={formik.values.logo || ''}
            onFocus={handleTouch(ESettingsKeys.logo)}
            onChange={handleChange(ESettingsKeys.logo)}
            error={formik.touched.logo && !!formik.errors.logo}
            helperText={
              formik.touched.logo && formik.errors && formik.errors.logo
            }
            isHighlighted={highlightedFields?.includes(ESettingsKeys.logo)}
          />
          <Box>
            <SettingsInputStyled
              inputLabel="Linkedin"
              name={ESettingsKeys.socials_linkedin}
              value={(formik.values.social_links as any)?.linkedin || ''}
              onFocus={handleTouch(ESettingsKeys.socials_linkedin)}
              onChange={handleChange(ESettingsKeys.socials_linkedin)}
              error={
                (formik.touched.social_links as any)?.linkedin &&
                !!(formik.errors.social_links as any)?.linkedin
              }
              helperText={
                (formik.touched.social_links as any)?.linkedin &&
                formik.errors &&
                (formik.errors.social_links as any)?.linkedin
              }
              isHighlighted={highlightedFields?.includes(
                ESocialUserLinksKeys.LINKEDIN
              )}
            />
            <SettingsInputStyled
              inputLabel="Near social"
              name={ESettingsKeys.socials_near_social}
              value={(formik.values.social_links as any)?.near_social || ''}
              onFocus={handleTouch(ESettingsKeys.socials_near_social)}
              onChange={handleChange(ESettingsKeys.socials_near_social)}
              error={
                (formik.touched.social_links as any)?.near_social &&
                !!(formik.errors.social_links as any)?.near_social
              }
              helperText={
                (formik.touched.social_links as any)?.near_social &&
                formik.errors &&
                (formik.errors.social_links as any)?.near_social
              }
              isHighlighted={highlightedFields?.includes(
                ESocialLinksKeys.NEAR_SOCIAL
              )}
            />
            <SettingsInputStyled
              inputLabel="Twitter"
              name={ESettingsKeys.socials_twitter}
              value={(formik.values.social_links as any)?.twitter || ''}
              onFocus={handleTouch(ESettingsKeys.socials_twitter)}
              onChange={handleChange(ESettingsKeys.socials_twitter)}
              error={
                (formik.touched.social_links as any)?.twitter &&
                !!(formik.errors.social_links as any)?.twitter
              }
              helperText={
                (formik.touched.social_links as any)?.twitter &&
                formik.errors &&
                (formik.errors.social_links as any)?.twitter
              }
              isHighlighted={highlightedFields?.includes(
                ESocialLinksKeys.TWITTER
              )}
            />
          </Box>
          {isControls && (
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <PrimaryButton onClick={() => formik.handleSubmit()}>
                Save
              </PrimaryButton>

              <PrimaryButton onClick={() => closeModal()}>Cancel</PrimaryButton>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
