import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { EProjectType } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useState } from 'react';
import { APP_ROUTES, PROJECT_ID } from 'routes/routes';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { NavLink, useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { theme } from 'shared/components/theme';
import { IEventProjectParticipation } from 'shared/interfaces/backendInterfaces';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { selectEventById } from 'store/selectors/events';
import { selectOffChainProjectsAllData } from 'store/slices/projects';

import { useControls } from '../useControls';

interface IProjectsListProps {
  participants: IEventProjectParticipation[];
  maxProjectsLimit: number | null;
  isReadOnly: boolean;
}

type NewProjectType = {
  label: string;
  id: string;
  type: EProjectType;
};

export const ProjectsList: React.FC<IProjectsListProps> = ({
  participants,
  maxProjectsLimit,
  isReadOnly,
}) => {
  const { eventId = '' } = useParams();
  const event = useAppSelector((state) => selectEventById(state, eventId));
  const projectsList = useAppSelector(selectOffChainProjectsAllData);

  const { addProject, removeProject } = useControls();

  const participantsIds = Object.keys(participants);

  const isProjectsLimit = maxProjectsLimit
    ? participantsIds.length >= maxProjectsLimit
    : false;

  // const projectsList = [...projects];
  const isEventFree = true;

  const projectsListItems = projectsList.map((project) => ({
    label: project.name,
    id: project.id.toString(),
    type: EProjectType.OffChain,
  }));

  const [newProject, setNewProject] = useState<NewProjectType | null>(null);

  return (
    <Box sx={{ width: '600px' }}>
      <Typography sx={{ textAlign: 'center', fontSize: '1.25rem' }}>
        Projects
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        Projects amount: {participantsIds.length}
        {maxProjectsLimit ? '/' + maxProjectsLimit : ''}
      </Typography>
      {!isReadOnly && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
            mb: 3,
            gap: '10px',
          }}
        >
          <FormControl sx={{ flex: 1 }} size="small">
            <Autocomplete
              id="project-participating-label"
              isOptionEqualToValue={(
                option: NewProjectType | null,
                value: NewProjectType | null
              ) => option?.id === value?.id}
              options={projectsListItems}
              value={newProject}
              onChange={(_, newProject: NewProjectType | null) =>
                setNewProject(newProject)
              }
              getOptionLabel={(option) => option.label}
              getOptionDisabled={(option) =>
                participantsIds.includes(option.id.toString()) ||
                (!isEventFree && option.type === EProjectType.OffChain)
              }
              groupBy={(option) => option.type}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Projects" />
              )}
            />
          </FormControl>
          <PrimaryButton
            onClick={() =>
              newProject && addProject(newProject.id, newProject.type)
            }
            disabled={isProjectsLimit || !newProject}
          >
            Add project
          </PrimaryButton>
        </Box>
      )}
      <Box
        sx={{ maxHeight: '500px', overflowY: 'auto', paddingInline: '10px' }}
      >
        {participants.map((participant) => {
          const project = projectsList.find((p) => p.id === participant.id);
          const isCanRemoveProject = participant.totalVotes === 0;
          const { logo } = getCorrectIPFSLinks({ logo: project?.logo || '' });

          if (!project) return null;

          const author = (project?.contact_links as any)?.email;

          return (
            <Box
              key={participant.id}
              sx={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                padding: '10px',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '10px',
                mb: 1,
              }}
            >
              <img
                style={{ width: '50px', height: '50px' }}
                src={logo}
                alt="Cannot resolve logo"
              />
              <Box>
                <NavLink
                  target="_blank"
                  to={APP_ROUTES.PROJECT_BY_ID.replace(
                    PROJECT_ID,
                    participant.id.toString()
                  )}
                >
                  <Typography>{project?.name}</Typography>
                </NavLink>
                <Typography
                  sx={{
                    maxWidth: '450px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {author}
                </Typography>
                <Typography
                  sx={{
                    maxWidth: '450px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  Votes: {participant.totalVotes}
                </Typography>
              </Box>
              {isCanRemoveProject && !isReadOnly && (
                <CloseIcon
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                  }}
                  onClick={() => removeProject(participant.id, '')}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
