import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useAppSelector } from 'services/hooks/redux-hooks';
import {
  selectEventsTableCount,
  selectEventsTableLoading,
} from 'store/selectors/events';

export const EventsEmpty = memo(() => {
  const count = useAppSelector(selectEventsTableCount);
  const isLoading = useAppSelector(selectEventsTableLoading);

  return !count && !isLoading ? (
    <Typography
      sx={{ width: '100%', fontSize: '1.25rem', textAlign: 'center' }}
    >
      There are no events, you can create one,
    </Typography>
  ) : null;
});
