import { ITag } from '@pitchtalk/contract-api-js/dist/interfaces';
import { TAG_ID } from 'routes/routes';
import {
  parseErrorData,
  parseSuccessData,
  parseData,
} from 'services/api/utils';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export const fetchTags = (limit: number) =>
  fetchAPI
    .get<ITag[]>(`${API_ROUTES.tags}?limit=${limit}`)
    .then(parseData)

export const fetchTagsCount = () =>
  fetchAPI
    .get<number>(API_ROUTES.tagsCount)
    .then(parseData)

export const fetchAddNewTag = (name: string) =>
  fetchAPI
    .post(API_ROUTES.tags, { name })
    .then(parseSuccessData('Tag successfully added'))
    .catch(parseErrorData('Tag add error'));

export const fetchEditTag = (id: string, name: string) =>
  fetchAPI
    .patch(API_ROUTES.tagsById.replace(TAG_ID, id), { name })
    .then(parseSuccessData('Tag successfully edit'))
    .catch(parseErrorData('Tag edit error'));

export const fetchRemoveTag = (id: string) =>
  fetchAPI
    .delete(API_ROUTES.tagsById.replace(TAG_ID, id))
    .then(parseSuccessData('Tag successfully removed'))
    .catch(parseErrorData('Tag remove error'));
