import Box from '@mui/material/Box';
import { fetcher } from 'services/api/utils';
import { useParams } from 'services/router';
import { Loader } from 'shared/components/Loader/Loader';
import {
  IEvent,
  IEventProjectParticipation,
} from 'shared/interfaces/backendInterfaces';
import { isEventVoteEnded } from 'shared/utils/eventsUtils';
import useSWR from 'swr';

import { IRefereeParticipation, JudgesList } from './components/JudgesList';
import { ProjectsList } from './components/ProjectsList';
import { EventEndedNotification } from '../components/EventEndedNotification';

export const EventActors: React.FC = () => {
  const { eventId = '' } = useParams();

  const { data: event, isLoading: isEventLoading } = useSWR<IEvent>(
    eventId ? `event/${eventId}` : null,
    fetcher
  );

  const { data: eventProjects, isLoading: isLoadingEventProjects } = useSWR<
    IEventProjectParticipation[]
  >(eventId ? `event/${eventId}/participants` : null, fetcher);

  const { data: eventReferees, isLoading: isLoadingEventReferees } = useSWR<
    IRefereeParticipation[]
  >(eventId ? `event/${eventId}/referees` : null, fetcher);

  if (isEventLoading) return <Loader />;

  return (
    <Box>
      <EventEndedNotification event={event} />
      <Box sx={{ display: 'flex', width: '1250px', gap: '50px' }}>
        {isLoadingEventProjects ? (
          <Loader />
        ) : (
          <ProjectsList
            participants={eventProjects || []}
            maxProjectsLimit={event?.participants_limit || 50}
            isReadOnly={isEventVoteEnded(event)}
          />
        )}
        {isLoadingEventReferees ? (
          <Loader />
        ) : (
          <JudgesList
            judges={eventReferees || []}
            votesLimit={event?.votes_limit || 100}
            participants={eventProjects || []}
            isReadOnly={isEventVoteEnded(event)}
          />
        )}
      </Box>
    </Box>
  );
};
