/* eslint-disable no-console */
import { compose } from '@reduxjs/toolkit';
import * as authAPI from 'services/api-endpoints/authAPI';
import EStorageKeys from 'services/constants/localStorageKeys';
import * as LocalStorage from 'services/localStorage';
import { setUserData } from 'store/slices/user';
import { AppDispatch } from 'store/store';

export const getServerUserData = () => (dispatch: AppDispatch) =>
  authAPI
    .fetchUserData()
    .then(compose(dispatch, setUserData))
    .catch(console.error);

export const loginUser =
  (email: string, password: string) => (dispatch: AppDispatch) =>
    authAPI
      .fetchLogin(email, password)
      .then(({ accessToken, refreshToken }) => {
        LocalStorage.setItem(EStorageKeys.ACCESS_TOKEN, accessToken);
        LocalStorage.setItem(EStorageKeys.REFRESH_TOKEN, refreshToken);
        return dispatch(getServerUserData());
      });

export const logoutUser = () => (dispatch: AppDispatch) => {
  LocalStorage.removeItem(EStorageKeys.ACCESS_TOKEN);
  LocalStorage.removeItem(EStorageKeys.REFRESH_TOKEN);
  dispatch(setUserData(null));
};
