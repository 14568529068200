import { utils } from '@pitchtalk/contract-api-js';
import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import Big from 'big.js';
import { ESearchBarValues } from 'shared/interfaces';
export enum EDimensions {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  DESKTOP = 'DESKTOP',
  UNKNOWN = 'UNKNOWN',
}

export const NANO_SEC_TO_MS = 1000000;

export const ONE_YOCTO_NEAR = '0.000000000000000000000001';
export const FT_STORAGE_DEPOSIT_GAS =
  utils.format.parseNearAmount('0.00000000003');
export const FT_TRANSFER_GAS: string = utils.format.parseNearAmount(
  '0.00000000003'
) as string;
export const NEAR_PER_BYTE = '0.00001'; // 1E19 yoctoNear ||  1E19 * 1E-24 wNear
// TODO: clarify this amount!!!
export const MIN_TRANSACTION_DEPOSIT = 0.01;
export const YOCTO_IN_NEAR_DECIMALS = 24;
export const BASE = 10;

// As new Big() is immutable and it always return the new Big value instead of the current instance;
export const ZERO_BIG = new Big(0);
export const ZERO = 0;

export const MAX_WIDTH_1439 = '@media (max-width: 1439px)';
export const MAX_WIDTH_767 = '@media (max-width: 767px)';

export const DEBOUNCE_300 = 300;
export const DEBOUNCE_500 = 500;
export const DEBOUNCE_1000 = 1000;

export const SEARCH_TRIGGER_LENGTH = 2;
export const DEFAULT_PAGINATION_REQUEST = 10;

export const MIN_DURATION = 2;
export const MAX_DURATION = 120;

export enum PromiseStatus {
  FULFILLED = 'fulfilled',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export const WNEAR_SYMBOL = 'wNear';
export const NEAR_TOKEN_NAME = 'NEAR';

export const URL_CONST =
  // eslint-disable-next-line no-useless-escape
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const ONE_SECOND = 1000;
export const SECONDS_30 = ONE_SECOND * 30;
export const SECONDS_60 = ONE_SECOND * 60;

export const FIVE_SEC = 1000 * 5;

export const MIN_PERCENT = 0;
export const MAX_PERCENT = 100;

export const PRECISION_DEFAULT = 3;

export const DEFAULT_TOKEN_META: ITokenMetadata = {
  version: '',
  name: '',
  symbol: '',
  reference: '',
  decimals: YOCTO_IN_NEAR_DECIMALS,
  icon: '',
};

export interface ITokenBalance {
  balance: string;
  symbol: string;
  decimals: number;
}

export const emptyBalance: ITokenBalance = {
  balance: '0',
  symbol: '',
  decimals: YOCTO_IN_NEAR_DECIMALS,
};

export const NON_LINEAR_VESTING_START = 1200 * ONE_SECOND;
export const LINEAR_VESTING_START = 600 * ONE_SECOND;

export const readOnlyChange = () => void {};

export const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy HH:mm';

export const EMPTY_STRING = '';

export const ACCEPTABLE_DOCS = '.doc,.txt,.pdf';

export const searchTypes = [
  { value: ESearchBarValues.ID, label: 'by project_id' },
  { value: ESearchBarValues.NAME, label: 'by project_name' },
  { value: ESearchBarValues.ALL, label: 'all project' },
];
