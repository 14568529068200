import { EUserRoles, IUser } from 'shared/interfaces/backendInterfaces';

export const checkIsAdmin = (user?: IUser | null) =>
  user ? user.role === EUserRoles.ADMIN : false;
export const checkIsSuperAdmin = (user?: IUser | null) =>
  user ? user.role === EUserRoles.SUPER_ADMIN : false;
export const checkIsModerator = (user?: IUser | null) =>
  user ? user.role === EUserRoles.MODERATOR : false;

export const checkIsDefaultUser = (user?: IUser | null) =>
  user ? user.role === EUserRoles.USER : false;
