import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { WalletSelectorContextProvider } from 'providers/WalletsProvider';
import { Provider } from 'react-redux';
import { ModalProvider } from 'shared/providers/ModalProvider';

import 'react-toastify/dist/ReactToastify.css';
import '@near-wallet-selector/modal-ui/styles.css';

import { AppRoutes } from './routes';
import { theme } from './shared/components/theme';
import store from './store/store';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <WalletSelectorContextProvider>
          <ModalProvider>
            <AppRoutes />
          </ModalProvider>
        </WalletSelectorContextProvider>
      </Provider>
    </ThemeProvider>
  );
};
