/* eslint-disable @typescript-eslint/no-empty-interface */
import createTheme from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface Theme {}
  interface ThemeOptions {}
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
  interface Palette {
    input: Palette['primary'];
  }
  interface PaletteOptions {
    input: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Everett-Regular',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Everett-Regular',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Everett-Regular',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 1,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Everett-Regular',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#653EE2',
      dark: '#5133B1',
    },
    secondary: {
      main: '#C4C4C4',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
    success: {
      main: '#3EE24E',
    },
    input: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: 'rgb(51, 51, 51)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1440,
    },
  },
});
