import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useWalletSelector } from 'providers/WalletsProvider';
import { APP_ROUTES } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { useTransactionHash } from 'services/hooks/useTransactionHashes';
import { NavLink } from 'services/router';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { selectIsSignedInServer, selectUserData } from 'store/slices/user';
import { logoutUser } from 'store/thunks/user';

import { PrimaryButton } from '../DefaultButtons/PrimaryButton';

const HEADER_NAV = [
  { to: APP_ROUTES.MANAGEMENT, label: 'Management' },
  { to: APP_ROUTES.SUBMISSIONS, label: 'Submissions' },
  { to: APP_ROUTES.PROJECTS, label: 'Projects' },
  { to: APP_ROUTES.INVESTMENTS, label: 'Investments' },
  { to: APP_ROUTES.TRANSFERS, label: 'Transfers' },
  { to: APP_ROUTES.TOKENS, label: 'Tokens' },
  { to: APP_ROUTES.TAGS, label: 'Tags' },
  { to: APP_ROUTES.EVENTS, label: 'Events' },
  { to: APP_ROUTES.METADATA, label: 'Metadata' },
  { to: APP_ROUTES.CHAT, label: 'Chat' },
  { to: APP_ROUTES.DEALS, label: 'Deals' },
];

export const Header: React.FC = (): JSX.Element => {
  const { showModal } = useModalContext();
  const dispatch = useAppDispatch();
  const { openModal, isSignedIn, accountId, signOut } = useWalletSelector();
  const isSignedInServer = useAppSelector(selectIsSignedInServer);
  const adminServerData = useAppSelector(selectUserData);
  const isSigned = isSignedIn();
  useTransactionHash();

  const signOutServer = () => dispatch(logoutUser());

  return (
    <>
      <Box
        component="header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginInline: '20px',
          '& a': {
            fontFamily: 'Everett-Regular',
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <List
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& li:first-of-type': { paddingLeft: 0 },
          }}
        >
          {HEADER_NAV.map((nav, ind) => (
            <ListItem key={ind}>
              <NavLink
                to={nav.to}
                style={({ isActive }: { isActive: boolean }) => ({
                  textDecoration: isActive ? 'underline' : 'none',
                  fontFamily: isActive ? 'Everett-Medium' : 'Everett-Regular',
                })}
              >
                {nav.label}
              </NavLink>
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <PrimaryButton
            sx={{ height: '38px', width: '180px', fontSize: '0.9rem' }}
            variant="contained"
            onClick={
              isSignedInServer
                ? () =>
                    showModal(EModals.SIGN_OUT_MODAL, {
                      signOut: signOutServer,
                      accountId: adminServerData?.email || '',
                    })
                : () => showModal(EModals.AUTH_SERVER_MODAL, {})
            }
          >
            {isSignedInServer
              ? adminServerData?.display_name
              : 'Sign In To Server'}
          </PrimaryButton>
          <PrimaryButton
            sx={{ height: '38px', width: '160px', fontSize: '0.9rem' }}
            variant="contained"
            onClick={
              isSigned
                ? () =>
                    showModal(EModals.SIGN_OUT_MODAL, { signOut, accountId })
                : openModal
            }
          >
            {isSigned ? accountId : 'Connect wallet'}
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
};
