import { Typography } from '@mui/material';
import { theme } from 'shared/components/theme';
import { IEvent } from 'shared/interfaces/backendInterfaces';
import { isEventVoteEnded as checkIsEventVoteEnded } from 'shared/utils/eventsUtils';

interface IEventEndedNotificationProps {
  event?: IEvent | null;
}

export const EventEndedNotification: React.FC<IEventEndedNotificationProps> = ({
  event,
}) => {
  const isEventVoteEnded = checkIsEventVoteEnded(event);

  return isEventVoteEnded ? (
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: '1.25rem',
        color: theme.palette.warning.main,
      }}
    >
      The event vote phase ended, so event cannot be changed anymore
    </Typography>
  ) : null;
};
