import FormControl from '@mui/material/FormControl';
import { Editor } from '@tinymce/tinymce-react';
import { FormikProps } from 'formik';
import { useRef } from 'react';

import { IHackakathonTaskState } from './Tasks';
import { taskDescriptionEditorInitSettings } from '../constants';

interface ITaskDescriptionProps {
  i: number;
  formik: FormikProps<IHackakathonTaskState[]>;
  isEventVoteEnded: boolean;
}

export const TaskDescription: React.FC<ITaskDescriptionProps> = ({
  i,
  formik,
  isEventVoteEnded,
}) => {
  const editorRef = useRef<Editor | null>(null);
  const task = formik.values[i];

  return (
    <>
      <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
        <Editor
          onInit={(_, editor) =>
            (editorRef.current = editor as unknown as Editor)
          }
          onBlur={() => {
            const description = (editorRef.current as any)?.getContent();
            formik.setFieldValue(`${i}.description`, description);
          }}
          initialValue={task.description}
          init={taskDescriptionEditorInitSettings}
          disabled={isEventVoteEnded}
        />
      </FormControl>
    </>
  );
};
