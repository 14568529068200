import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { EActionStatus } from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import { useState } from 'react';
import {
  requestToReset,
  revertRequestToReset,
} from 'services/api-contract-endpoints/submissionAPI';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsInput } from 'shared/components/SettingsInput';
import { IResettingSubModal } from 'shared/interfaces/modals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 58px',
  boxShadow: 24,
  borderRadius: '10px',
};

export const ResettingSubModal: React.FC<IResettingSubModal> = ({
  closeModal,
  subProject,
}): JSX.Element => {
  const [comment, setComment] = useState<string>('');
  const isResetting = false //subProject.status === EActionStatus.Resetting;

  const onConfirmClick = () => {
    // !isResetting
    //   ? requestToReset(subProject.author_id, comment)
    //   : revertRequestToReset(subProject.author_id, comment);
    closeModal();
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <>
            <Typography
              sx={{
                fontFamily: 'Everett-Regular',
                fontSize: '1.125rem',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              {!isResetting
                ? 'You are going to make a request to reset'
                : 'You are going to revert a request to reset'}
            </Typography>
            {!isResetting && (
              <SettingsInput
                inputLabel="Comment: "
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={6}
                sx={{
                  '.MuiFormControlLabel-root': {
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  },
                }}
                inputStyles={{
                  '.MuiOutlinedInput-input': {
                    background: 'rgb(51, 51, 51)',
                    color: 'white',
                    padding: '4px 6px',
                  },
                  '.MuiOutlinedInput-root': { padding: '0px' },
                }}
              />
            )}
            <PrimaryButton
              sx={{ width: '100%', height: '42px', marginTop: '20px' }}
              variant="contained"
              onClick={onConfirmClick}
            >
              {!isResetting ? 'Reset request' : 'Revert resetting'}
            </PrimaryButton>
          </>
        </Box>
      </Modal>
    </div>
  );
};
