import EStorageKeys from 'services/constants/localStorageKeys';

export const STORAGE_EVENT = 'storage';

export const setItem = (key: EStorageKeys, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new Event(STORAGE_EVENT));
};

export const getItem = (key: EStorageKeys): Record<string, unknown> | null => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};

export const removeItem = (key: EStorageKeys) => {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event(STORAGE_EVENT));
};

export const setSessionItem = (key: EStorageKeys, value: unknown) =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const getSessionItem = (key: EStorageKeys): unknown | null => {
  try {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};
