import {
  BenefitPlan,
  BenefitPlanLinear,
  BenefitPlanNonLinear,
  BenefitPlanTypes,
  Vesting,
} from '@pitchtalk/contract-api-js/dist/core';
import Big, { BigSource } from 'big.js';
import { millisecondsToSeconds, secondsToMilliseconds } from 'date-fns';
import { wrapNearId } from 'services/config';
import {
  BASE,
  LINEAR_VESTING_START,
  MAX_PERCENT,
  MIN_PERCENT,
  NON_LINEAR_VESTING_START,
  ONE_SECOND,
  PRECISION_DEFAULT,
  YOCTO_IN_NEAR_DECIMALS,
  ZERO,
} from 'shared/constants';

import { getAmountFormatted, roundToLow } from './near';

export type VestingSettingsState = {
  vestingType: BenefitPlanTypes;
  ft_token_id: string;
  startDate: number;
  price: number;
  [BenefitPlanTypes.Linear]: { endDate: number };
  [BenefitPlanTypes.NonLinear]: { endDate: number; percentage: number }[];
};

export const getVestingType = (vestingPlan: BenefitPlan) =>
  vestingPlan.hasOwnProperty!(BenefitPlanTypes.NonLinear)
    ? BenefitPlanTypes.NonLinear
    : BenefitPlanTypes.Linear;

export const getVestingPlan = (vesting: VestingSettingsState) =>
  vesting.vestingType === BenefitPlanTypes.Linear
    ? getLinearVestingPlan(
        vesting[BenefitPlanTypes.Linear].endDate,
        vesting.startDate
      )
    : getNonLinearVestingPlan(
        vesting[BenefitPlanTypes.NonLinear],
        vesting.startDate
      );

const getLinearVestingPlan = (endDate: number, startDate: number) => ({
  [BenefitPlanTypes.Linear]: {
    duration: millisecondsToSeconds(endDate - startDate),
  },
});

const getNonLinearVestingPlan = (
  vesting: { endDate: number; percentage: number }[],
  startDate: number
) => ({
  [BenefitPlanTypes.NonLinear]: vesting.map(({ endDate, percentage }, i) => ({
    time_since_start: i === 0 ? 0 : millisecondsToSeconds(endDate - startDate),
    percentage,
  })),
});

export const getLinearVestingDuration = (plan: BenefitPlanLinear) =>
  plan[BenefitPlanTypes.Linear].duration * ONE_SECOND;

export const getNonLinearVestingDuration = (plan: BenefitPlanNonLinear) =>
  (plan[BenefitPlanTypes.NonLinear].at(-1)?.time_since_start ?? 0) * ONE_SECOND;

export const getVestingInitialStateValues = (
  vesting: Vesting | null | undefined
): VestingSettingsState => ({
  vestingType: vesting
    ? getVestingType(vesting.benefit_plan)
    : BenefitPlanTypes.Linear,
  ft_token_id: vesting ? vesting.ft_token_id : wrapNearId,
  startDate: vesting ? secondsToMilliseconds(vesting.start_sec) : Date.now(),
  price: vesting
    ? Number(
        roundToLow(
          +getAmountFormatted(vesting.price, YOCTO_IN_NEAR_DECIMALS, BASE),
          PRECISION_DEFAULT
        )
      )
    : ZERO,
  [BenefitPlanTypes.Linear]: {
    endDate:
      vesting &&
      getVestingType(vesting.benefit_plan) === BenefitPlanTypes.Linear
        ? secondsToMilliseconds(vesting.start_sec) +
          secondsToMilliseconds(
            (vesting.benefit_plan as BenefitPlanLinear)[BenefitPlanTypes.Linear]
              .duration
          )
        : Date.now() + LINEAR_VESTING_START,
  },
  [BenefitPlanTypes.NonLinear]:
    vesting &&
    getVestingType(vesting.benefit_plan) === BenefitPlanTypes.NonLinear
      ? (vesting.benefit_plan as BenefitPlanNonLinear)[
          BenefitPlanTypes.NonLinear
        ].map(({ percentage, time_since_start }) => ({
          endDate:
            secondsToMilliseconds(vesting!.start_sec) +
            secondsToMilliseconds(time_since_start),
          percentage,
        }))
      : [
          { endDate: ZERO, percentage: MIN_PERCENT },
          {
            endDate: Date.now() + NON_LINEAR_VESTING_START,
            percentage: MAX_PERCENT,
          },
        ],
});

export const getVestingTokenAmountByPrice = (
  invested: BigSource,
  price: BigSource,
  projectTokenDecimals: number
) =>
  Big(invested)
    .div(Big(BASE ** projectTokenDecimals))
    .mul(Big(BASE ** YOCTO_IN_NEAR_DECIMALS))
    .div(Big(price))
    .toFixed(YOCTO_IN_NEAR_DECIMALS)
    .toString();
