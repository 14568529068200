import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsInput } from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';
import { DEFAULT_TOKEN_META } from 'shared/constants';
import { IEditFeeModal } from 'shared/interfaces/modals';
import {
  getAmountFormatted,
  getAmountFormattedWithDecimals,
} from 'shared/utils/near';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 64px',
  boxShadow: 24,
  borderRadius: '10px',
};

export const EditFeeModal: React.FC<IEditFeeModal> = ({
  closeModal,
  tokens,
  metaData,
  updateFeeTokenData,
}): JSX.Element => {
  const [feeToken, setFeeToken] = useState(
    metaData?.fee_token || Object.keys(tokens)[0]
  );
  const currentFeeToken = metaData
    ? tokens[metaData.fee_token]
    : DEFAULT_TOKEN_META;
  const [feeAmount, setFeeAmount] = useState(
    Number(
      getAmountFormatted(metaData?.join_fee || 0, currentFeeToken.decimals)
    )
  );

  const onEdit = () => {
    const tokenMeta = tokens[feeToken];
    const amount = getAmountFormattedWithDecimals(
      feeAmount,
      tokenMeta.decimals
    );
    if (amount && Number(amount) > 0)
      updateFeeTokenData(feeToken, amount, feeToken === metaData?.fee_token);
  };

  const isTokenChanged =
    feeToken !== metaData?.fee_token && Number(metaData?.total_fee_balance) > 0;

  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: '1.5rem',
              textAlign: 'center',
              marginBottom: '10px',
            }}
            variant="h3"
          >
            Edit Submission Fee
          </Typography>
          <Typography
            sx={{
              fontSize: '1.15rem',
              textAlign: 'center',
              marginBottom: '30px',
            }}
            variant="h3"
          >
            <span> Current fee: </span>
            <span>
              {getAmountFormatted(
                metaData?.join_fee || 0,
                currentFeeToken.decimals
              )}{' '}
            </span>
            <span>{currentFeeToken.symbol} </span>
          </Typography>
          <Box
            sx={{
              marginBottom: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography>Currency:</Typography>
            <Select
              sx={{
                background: 'rgb(51, 51, 51)',
                color: 'white',
                '& svg': { fill: 'white' },
                '& .MuiOutlinedInput-input': {
                  width: '205px',
                  padding: '10px 25px 10px 20px',
                },
              }}
              value={feeToken}
              onChange={(e) => setFeeToken(e.target.value)}
              disabled={!Object.keys(tokens).length}
            >
              {Object.keys(tokens).map((token, ind) => (
                <MenuItem key={ind} value={token}>
                  {token}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '16px' }}>
            <SettingsInput
              inputLabel="Amount:"
              value={feeAmount}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                setFeeAmount(Number(e.target.value))
              }
              boxStyles={{ marginBottom: '16px' }}
              inputStyles={{
                '.MuiOutlinedInput-input': {
                  background: 'rgb(51, 51, 51)',
                  color: 'white',
                  width: '211px',
                  padding: '10px 25px 10px 20px',
                },
              }}
            />
          </Box>
          <PrimaryButton
            sx={{ width: '100%', height: '42px' }}
            variant="contained"
            disabled={isTokenChanged}
            onClick={onEdit}
          >
            Edit
          </PrimaryButton>
          {isTokenChanged && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                marginTop: '5px',
                textAlign: 'center',
              }}
            >
              *Please withdraw all tokens before change
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};
