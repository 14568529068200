import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { API_ROUTES } from 'services/api/config';
import { usePaginatedApi } from 'services/hooks/useAPI';
import { DEBOUNCE_300 } from 'shared/constants';
import {
  EEventAccess,
  EEventAuthor,
  IEvent,
} from 'shared/interfaces/backendInterfaces';

import { getEventsCount } from './api';
import { EventsTable } from './components/EventsTable';

interface SearchParams {
  value: string;
  type: Search;
}

type Search = 'id' | 'name' | null;
type Role = EEventAuthor.ADMIN | EEventAuthor.USER | null;
type Privacy = EEventAccess.PRIVATE | EEventAccess.PUBLIC | null;

const getSearchParams = (
  search: SearchParams,
  role: Role,
  privacy: Privacy,
  rows: number
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchParams: Record<string, any> = { limit: rows };
  if (role) searchParams.author = role;
  if (privacy) searchParams.access = privacy;
  if (privacy) searchParams.access = privacy;
  if (search.type === 'id') searchParams.id = search.value;
  if (search.type === 'name') searchParams.search = search.value;
  return searchParams;
};

export const EventsList: React.FC = () => {
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState(10);
  const [search, setSearch] = useState<SearchParams>({ value: '', type: null });
  const [role, setRole] = useState<Role>(null);
  const [privacy, setPrivacy] = useState<Privacy>(null);

  const searchParams = useMemo(
    () => getSearchParams(search, role, privacy, rows),
    [search, role, privacy, rows]
  );

  const {
    data: events,
    isLoading: isEventsLoading,
    page,
    setPage,
    mutate,
  } = usePaginatedApi<IEvent[]>({
    baseUrl: API_ROUTES.getEvents,
    search: searchParams,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((value: string) => {
      setSearch((prev) => ({ ...prev, value }));
      mutate();
    }, DEBOUNCE_300),
    []
  );

  useEffect(() => {
    (async () => {
      const count = await getEventsCount(searchParams);
      setCount(count);
    })();

    mutate();
  }, [searchParams, mutate]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const handleSearchTypeChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: Search
  ) => setSearch({ type: value, value: '' });

  const handleRoleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: Role
  ) => setRole(value);

  const handlePrivacyChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: Privacy
  ) => setPrivacy(value);

  const handleClearFilters = () => {
    setSearch({ value: '', type: null });
    setRole(null);
    setPrivacy(null);
    mutate();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
      component="div"
    >
      <FormControl>
        <Typography>Search event</Typography>
        <RadioGroup
          row
          value={search.type}
          onChange={(e) => handleSearchTypeChange(e, e.target.value as Search)}
        >
          <FormControlLabel value="id" control={<Radio />} label="By Id" />
          <FormControlLabel value="name" control={<Radio />} label="By Name" />
        </RadioGroup>
        <TextField
          size="small"
          value={search.value}
          onChange={handleSearchChange}
          placeholder={search.type === 'id' ? 'uuid' : 'name'}
        />
        <RadioGroup
          row
          value={role}
          onChange={(e) => handleRoleChange(e, e.target.value as Role)}
        >
          <FormControlLabel
            value={EEventAuthor.ADMIN}
            control={<Radio />}
            label="Admin"
          />
          <FormControlLabel
            value={EEventAuthor.USER}
            control={<Radio />}
            label="User"
          />
        </RadioGroup>
        <RadioGroup
          row
          value={privacy}
          onChange={(e) => handlePrivacyChange(e, e.target.value as Privacy)}
        >
          <FormControlLabel
            value={EEventAccess.PRIVATE}
            control={<Radio />}
            label="Private"
          />
          <FormControlLabel
            value={EEventAccess.PUBLIC}
            control={<Radio />}
            label="Public"
          />
        </RadioGroup>
        <Button variant="outlined" onClick={handleClearFilters} sx={{ mt: 2 }}>
          Clear
        </Button>
      </FormControl>
      <EventsTable
        count={count}
        events={events || []}
        isLoading={isEventsLoading}
        page={page}
        setPage={setPage}
        rows={rows}
        setRows={setRows}
      />
    </Box>
  );
};
