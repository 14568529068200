import { Vesting, Project } from '@pitchtalk/contract-api-js/dist/core';
import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import { Pitch } from '@pitchtalk/contract-api-js/dist/interfaces/pitch';
import Big from 'big.js';

export type Only<T, U> = { [P in keyof T]: T[P] } & Omit<
  { [P in keyof U]?: never },
  keyof T
>;

export type Either<T, U> = Only<T, U> | Only<U, T>;

export type ValueOf<T> = T[keyof T];

export type ContractExecutionError = { kind: { ExecutionError: string } };

export type PitchWithSettings = Pitch & { new?: boolean };

export type ProjectWithVesting = Project & { vesting: Vesting | null };

export interface TokenPrices {
  id: string;
  decimal: number;
  symbol: string;
  price: string;
  updatedAt: string;
}

export interface ITotalTokenModel extends ITokenMetadata {
  amount: Big;
  ft_token_id: string;
}

export enum ESearchBarValues {
  ID = 'id',
  NAME = 'name',
  ALL = 'all',
}

export interface IPreviewImageModel {
  banner: string;
  logo: string;
}
