import { Project, DocumentType } from '@pitchtalk/contract-api-js';
import {
  ESocialLinksKeys,
  ProjectArgs,
} from '@pitchtalk/contract-api-js/dist/core';
import { ITag } from '@pitchtalk/contract-api-js/dist/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { IProject, IProjectSocialMedia } from 'shared/interfaces/backendInterfaces';

export type GeneralSettingsState = {
  name: string;
  description: string;
  logo: string;
  banner: string;
  project_url: string;
  twitter: string;
  telegram: string;
  medium: string;
  discord: string;
  near_social: string;
  tags: string[];
};

export enum Attachments {
  TOKENOMICS = 'tokenomics',
  PITCHDECK = 'pitchdeck',
  WHITEPAPER = 'whitepaper',
}

export const AttachmentsDocTypes = {
  [Attachments.TOKENOMICS]: DocumentType.Tokenomics,
  [Attachments.PITCHDECK]: DocumentType.Diagram,
  [Attachments.WHITEPAPER]: DocumentType.Whitepaper,
};

export const DocTypesAttachments = {
  [DocumentType.Tokenomics]: Attachments.TOKENOMICS,
  [DocumentType.Diagram]: Attachments.PITCHDECK,
  [DocumentType.Whitepaper]: Attachments.WHITEPAPER,
  [DocumentType.Other]: Attachments.WHITEPAPER,
};

export const initialAttachments = {
  tokenomics: EMPTY_STRING,
  pitchdeck: EMPTY_STRING,
  whitepaper: EMPTY_STRING,
};

export const getSettingsFromProject = (project?: IProject) => ({
  name: project?.name ?? EMPTY_STRING,
  description: project?.description ?? EMPTY_STRING,
  logo: project?.logo ?? EMPTY_STRING,
  banner: project?.banner ?? EMPTY_STRING,
  project_url: project?.project_url ?? EMPTY_STRING,
  twitter: (project?.social_links as any)?.twitter ?? EMPTY_STRING,
  telegram: (project?.social_links as any)?.telegram ?? EMPTY_STRING,
  medium: (project?.social_links as any)?.medium ?? EMPTY_STRING,
  discord: (project?.social_links as any)?.discord ?? EMPTY_STRING,
  near_social: (project?.social_links as any)?.near_social ?? EMPTY_STRING,
  tags: project?.tags.map((tag: string | ITag): string => (tag as ITag)?.id || (tag as string)) || [],
});

export interface IProjectArgs {
  name: string;
  description?: string;
  logo?: string;
  banner?: string;
  projectUrl?: string;
  socialLinks?: Partial<IProjectSocialMedia>;
  tags?: string[];
}

export const getProjectSettingsForSaving = (
  settings: GeneralSettingsState
): IProjectArgs => ({
  name: settings.name,
  description: settings.description,
  logo: settings.logo,
  banner: settings.banner,
  projectUrl: settings.project_url,
  socialLinks: {
    [ESocialLinksKeys.TWITTER]: settings.twitter,
    [ESocialLinksKeys.TELEGRAM]: settings.telegram,
    [ESocialLinksKeys.MEDIUM]: settings.medium,
    [ESocialLinksKeys.DISCORD]: settings.discord,
    [ESocialLinksKeys.NEAR_SOCIAL]: settings.near_social,
  },
  tags: settings.tags,
});
