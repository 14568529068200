import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { EEventSubmissionStatus } from '@pitchtalk/contract-api-js/dist/interfaces';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { SettingsDatePicker } from 'shared/components/SettingsDatePicker';
import { SettingsInput } from 'shared/components/SettingsInput';
import { theme } from 'shared/components/theme';
import {
  IEventProjectParticipation,
  IEventSchedule,
} from 'shared/interfaces/backendInterfaces';
import { flexCenter, SELECT_MENU_PROPS } from 'shared/utils/themeUtils';

interface IScheduleCheckPointProps {
  formik: FormikProps<Record<string, IEventSchedule>>;
  id: string;
  ind: number;
  startDate: Date;
  endDate: Date;
  removeCheckpoint: (id: string) => void;
  eventProjects: IEventProjectParticipation[] | undefined;
  participantsIds: (number | string)[];
  isReadOnlyEvent: boolean;
}

export const ScheduleCheckPoint: React.FC<IScheduleCheckPointProps> = ({
  formik,
  id,
  ind,
  startDate,
  endDate,
  removeCheckpoint,
  eventProjects = [],
  participantsIds,
  isReadOnlyEvent,
}) => {
  const [selectedProject, setSelectedProject] = useState<string>('');
  const checkpoint = formik.values[id];

  const projects = eventProjects
    .filter((p) => p.status === EEventSubmissionStatus.APPROVED)
    .map((a) => a.project_id);

  useEffect(() => {
    setSelectedProject(checkpoint.project_id || '');
  }, [checkpoint.project_id]);

  return (
    <Box
      sx={{
        position: 'relative',
        '& .MuiTextField-root': { width: '400px' },
        '& .MuiTextField-root input': { padding: '7px 14px' },
        '& .MuiFormGroup-root': { paddingBlock: '5px' },
        paddingBottom: '5px',
        marginBottom: '10px',
      }}
    >
      <Box sx={{ ...flexCenter, gap: '10px' }}>
        <Typography sx={{ fontSize: '1.15rem' }}>
          Checkpoint {ind + 1}
        </Typography>
        {!isReadOnlyEvent && (
          <CloseIcon
            sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
            onClick={() => removeCheckpoint?.(id)}
          />
        )}
      </Box>
      <SettingsDatePicker
        label="Start date"
        value={checkpoint.start_date}
        onChange={(newTime: Date | null) => {
          if (newTime) formik.setFieldValue(`${id}.start_date`, newTime);
          formik.setFieldTouched(`${id}.start_date`, true, false);
        }}
        minDate={startDate}
        maxDate={endDate}
        error={!!formik.errors[id]?.start_date}
        helperText={formik.errors[id]?.start_date as string}
        sx={{ '& .MuiSvgIcon-root': { fill: theme.palette.primary.main } }}
        readOnly={isReadOnlyEvent}
      />
      <SettingsInput
        size="small"
        inputLabel="Duration in min"
        name="duration"
        value={checkpoint.duration || 0}
        onFocus={() => formik.setFieldTouched(`${id}.duration`, true)}
        onChange={(e) =>
          !isNaN(+e.target.value) &&
          formik.setFieldValue(`${id}.duration`, parseInt(e.target.value) || 0)
        }
        error={!!formik.errors[id]?.duration}
        helperText={formik.errors[id]?.duration}
        InputProps={{ readOnly: isReadOnlyEvent }}
      />
      <SettingsInput
        size="small"
        inputLabel="Title"
        name="title"
        value={checkpoint.title || ''}
        onFocus={() => formik.setFieldTouched(`${id}.title`, true)}
        onChange={(e) => formik.setFieldValue(`${id}.title`, e.target.value)}
        error={!!formik.errors[id]?.title}
        helperText={formik.errors[id]?.title}
        InputProps={{ readOnly: isReadOnlyEvent }}
      />
      <SettingsInput
        size="small"
        inputLabel="Description"
        name="description"
        value={checkpoint.description || ''}
        onFocus={() => formik.setFieldTouched(`${id}.description`, true)}
        onChange={(e) =>
          formik.setFieldValue(`${id}.description`, e.target.value)
        }
        error={!!formik.errors[id]?.description}
        helperText={formik.errors[id]?.description}
        multiline
        rows={4}
        boxStyles={{
          alignItems: 'flex-start',
          '& .MuiOutlinedInput-input': { padding: '0 !important' },
        }}
        InputProps={{ readOnly: isReadOnlyEvent }}
      />
      <SettingsInput
        size="small"
        inputLabel="Video url"
        name="video_url"
        value={checkpoint.video_url || ''}
        onFocus={() => formik.setFieldTouched(`${id}.video_url`, true)}
        onChange={(e) =>
          formik.setFieldValue(`${id}.video_url`, e.target.value)
        }
        error={!!formik.errors[id]?.video_url}
        helperText={formik.errors[id]?.video_url}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography>Project</Typography>
        <FormControl sx={{ width: '400px' }} size="small">
          <InputLabel id="project-participating-label">Project</InputLabel>
          <Select
            labelId="project-participating-label"
            id="project-participating-label-select"
            value={selectedProject}
            readOnly={isReadOnlyEvent}
            onChange={(e) => {
              formik.setFieldValue(`${id}.project_id`, e.target.value);
              setSelectedProject(e.target.value);
            }}
            input={<OutlinedInput label="Projects" />}
            MenuProps={SELECT_MENU_PROPS}
          >
            {projects.map((projectId) => {
              const project = eventProjects.find(
                (p) => p.project_id === projectId
              );

              if (!project) return <></>;

              return (
                <MenuItem
                  key={projectId + project?.participant.name}
                  value={projectId}
                  disabled={participantsIds.includes(projectId)}
                >
                  {project?.participant.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
