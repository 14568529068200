import { FormLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { fetchAPI } from 'services/api/ServerAPI';
import { fetcher, parseData } from 'services/api/utils';
import { useNavigate, useParams } from 'services/router';
import { ToastService } from 'services/toast/ToastService';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { IDeal, IProject } from 'shared/interfaces/backendInterfaces';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { invalidUrl, requiredField } from 'shared/utils/validationsUtils';
import YUP from 'shared/utils/yupUtils';
import useSWR from 'swr';

declare interface IDealCreate {
  img: string;
  name: string;
  offer: string;
  webLink: string;
  description: string;
  connectLink: string;
}

const emptyDeal: IDealCreate = {
  img: '',
  name: '',
  offer: '',
  webLink: '',
  description: '',
  connectLink: '',
}

export const getDealValidationSchema = (
) =>
  YUP.object({
    name: YUP.string()
      .required(requiredField('Deal name')),
    description: YUP.string()
      .required(requiredField('Deal description')),
    img: YUP.string().required(requiredField('Deal banner')),
    connectLink: YUP.string().connectLink().required(),
    offer: YUP.string()
      .required(requiredField('Deal offer')),
    webLink: YUP.string().startWithHTTP().url().required()
  });

export const EditDealPage: React.FC<{isEdit: boolean}> = ({ isEdit }): JSX.Element => {
  // const { requestSignTransactions } = useWalletSelector();
  const { dealId } = useParams();
  const navigate = useNavigate();
  const [editDeal, setIsEdit] = useState<boolean>(isEdit ? false : true);

  const { showModal, shouldConfirmModalShow } = useModalContext();
  
  const { data: deal, isLoading, mutate} = useSWR<IDeal>(`deals/${dealId}`, dealId ? fetcher : null)

  const formik = useFormik<IDealCreate>({
    initialValues: { ...emptyDeal, ...deal },
    validationSchema: getDealValidationSchema,
    onSubmit: async (data) => {
      try {
        formik.setSubmitting(true);

        let res;
        if (deal?.id) {
          const tags = deal?.tags?.length ? [...deal.tags] : []
          res = await fetchAPI.patch(`deals/${deal.id}`, { ...data, tags }).then(parseData)
        } else {
          res = await fetchAPI.post('deals', { ...data, tags: [] }).then(parseData)
          if (res.id) {
            navigate(`/deals/${res.id}`, { replace: true });
          }
        }

        ToastService.info('', 'Deal was updated')
      } catch (error) {
        console.error(error)
        ToastService.error('', 'Something went wrong')
      } finally {
        formik.setSubmitting(false);
        setIsEdit(false);
        mutate()
      }
    },
  });

  useEffect(() => {
    if (deal) formik.setValues({ ...deal });
  }, [deal]);

  const onFormikReset = () => {
    formik.resetForm()
    deal && formik.setValues({...deal})
  }

  const openPictureModal = () => {
    const image = formik.values.img || '';
    showModal(EModals.UPLOAD_IMAGE_MODAL, {
      submitImage: (imageCid: string) => {
        formik.setFieldValue('img', imageCid);
      },
      image,
    });
  };

  const { setFieldValue } = formik;
  const correctImages = getCorrectIPFSLinks({
    logo: formik?.values?.img || '',
  });

  const imageLink = correctImages.logo;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <SettingsBox legend="Deal settings"
          sx={{
            padding: '10px',
            width: '480px',
            height: 'fit-content',
            marginBottom: '20px',
            '.MuiFormGroup-root': { paddingBlock: '5px' },
          }}>
  
    <Box
      sx={{
      }}
    >
      <Box
        sx={{
          border: '1px solid rgb(101, 62, 226)',
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          // aspectRatio: '1.5 / 1',
          borderRadius: '3px',
          justifyContent: 'center',
        }}
      >
        {imageLink ? (
          <img
            style={{
              height: '100%',
            }}
            src={imageLink}
            onError={(e) => {
              (e.target as HTMLInputElement).onerror = null;
              (e.target as HTMLInputElement).src = '';
            }}
            alt="logo"
          />
        ) : (
          <Typography
            sx={{
              color: 'rgb(196, 196, 196)',
              fontSize: '0.875rem',
              textAlign: 'center',
              fontFamily: 'Everett-Regular',
              lineHeight: '20px',
            }}
          >
            Please upload a banner
          </Typography>
        )}
      </Box>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '6px 0',
          alignItems: 'center',
        }}
      >
        <FormLabel>Project banner</FormLabel>
        <PrimaryButton
          sx={{
            width: 'fit-content',
            fontSize: '12px',
            height: '30px',
          }}
          disabled={!editDeal}
          onClick={() => openPictureModal()}
        >
          Upload
        </PrimaryButton>
      </div>
    </Box>
      <SettingsInput
        inputLabel="Description"
            name="description"
        value={formik.values.description || ''}
        onFocus={() => formik.setFieldTouched('description', true)}
        onChange={(e) => formik.setFieldValue('description', e.target.value)}
        error={formik.touched?.description && !!formik.errors?.description}
        helperText={
          formik.touched?.description &&
          formik.errors &&
          formik.errors?.description
        }
        multiline
        rows={6}
        boxStyles={{
          alignItems: 'flex-start',
          '& .MuiOutlinedInput-input': { padding: '0 !important' },
        }}
        InputProps={{ readOnly: !editDeal }}
      />
      <SettingsInput
        inputLabel="Web url"
        name="webLink"
        value={formik.values.webLink || ''}
        onFocus={() => formik.setFieldTouched('webLink', true)}
        onChange={(e) => formik.setFieldValue('webLink', e.target.value)}
        error={formik.touched?.webLink && !!formik.errors?.webLink}
        helperText={
          formik.touched?.webLink &&
          formik.errors &&
          formik.errors?.webLink
        }
        InputProps={{ readOnly: !editDeal }}
      />
      <SettingsInput
        inputLabel="Name"
        name="name"
        value={formik.values.name || ''}
        onFocus={() => formik.setFieldTouched('name', true)}
        onChange={(e) =>
          formik.setFieldValue('name', e.target.value)
        }
        error={
          formik.touched?.name &&
          !!formik.errors?.name
        }
        helperText={
          formik.touched?.name &&
          formik.errors &&
          formik.errors?.name
        }
        InputProps={{ readOnly: !editDeal }}
      />
      <SettingsInput
        inputLabel="Offer"
        name="offer"
        value={formik.values.offer || ''}
        onFocus={() => formik.setFieldTouched('offer', true)}
        onChange={(e) =>
          formik.setFieldValue('offer', e.target.value)
        }
        error={
          formik.touched?.offer &&
          !!formik.errors?.offer
        }
        multiline
        rows={6}
        helperText={
          formik.touched?.offer &&
          formik.errors &&
          formik.errors?.offer
        }
        InputProps={{ readOnly: !editDeal }}
      />
      <SettingsInput
        inputLabel="Connect Link"
        name="connectLink"
        value={formik.values.connectLink || ''}
        onFocus={() => formik.setFieldTouched('connectLink', true)}
        onChange={(e) => formik.setFieldValue('connectLink', e.target.value)}
        error={formik.touched?.connectLink && !!formik.errors?.connectLink}
        helperText={
          formik.touched?.connectLink &&
          formik.errors &&
          formik.errors?.connectLink
        }
        boxStyles={{
          alignItems: 'flex-start',
          '& .MuiOutlinedInput-input': { padding: '0 !important' },
        }}
        InputProps={{ readOnly: !editDeal }}
      />
        </SettingsBox>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginLeft: '25px',
        }}
      >
        {editDeal ? (
          <>
            <PrimaryButton
              sx={{ marginTop: '12px', width: '200px' }}
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              onClick={() => formik.handleSubmit()}
            >
              {isEdit ? 'Confirm' : 'Save'}
            </PrimaryButton>
            {isEdit ? (
              <>
                <PrimaryButton
                  sx={{
                    marginTop: '12px',
                    width: '200px',
                    background: '#1314E8',
                    '&:hover': { background: '#0a0aad' },
                  }}
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setIsEdit((prev) => !prev);
                    onFormikReset()
                  }}
                >
                  Cancel
                </PrimaryButton>
              </>
            ) : null}
          </>
        ) : (
          <PrimaryButton
            sx={{ marginTop: '12px', width: '200px' }}
            disabled={formik.isSubmitting}
            onClick={() => setIsEdit((prev) => !prev)}
          >
            Edit Deal
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
};


