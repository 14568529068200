import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { EEventSubmissionStatus } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useState } from 'react';
import { APP_ROUTES, PROJECT_ID } from 'routes/routes';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { NavLink } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { theme } from 'shared/components/theme';
import { IEventProjectParticipation } from 'shared/interfaces/backendInterfaces';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { IEventCombined } from 'store/slices/events';
import { selectFunds } from 'store/slices/funds';
import { selectOffChainProjectsAllData } from 'store/slices/projects';

import { useControls } from '../useControls';

export interface IRefereeVote {
  id: string;
  created_at: Date;
  updated_at: Date;
  participantId: string;
  refereeId: string;
  voteScore: number;
}

export interface IRefereeParticipation {
  id: string;
  account_id: string;

  status: EEventSubmissionStatus;

  created_at: Date;
  updated_at: Date;

  votesGiven: number;
  votes?: IRefereeVote[];

  userId: string;
  eventId: string;
  event: string | IEventCombined;
}

interface IJudgesListProps {
  judges: IRefereeParticipation[];
  votesLimit: number;
  isReadOnly: boolean;
  participants: IEventProjectParticipation[];
}

type NewJudgeType = {
  name: string;
  account_id: string;
};

export const JudgesList: React.FC<IJudgesListProps> = ({
  judges,
  participants,
  votesLimit,
  isReadOnly,
}) => {
  const funds = useAppSelector(selectFunds);
  const projects = useAppSelector(selectOffChainProjectsAllData);

  const { addReferee, removeReferee } = useControls();

  const judgesListItems = funds.map((fund) => ({
    name: fund.name,
    account_id: fund.account_id,
  }));

  const [newJudge, setNewJudge] = useState<NewJudgeType | null>(null);

  return (
    <Box sx={{ width: '600px' }}>
      <Typography sx={{ textAlign: 'center', fontSize: '1.25rem' }}>
        Referee
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        Referees amount: {Object.keys(judges).length}
      </Typography>
      {!isReadOnly && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
            mb: 3,
            gap: '10px',
          }}
        >
          <FormControl sx={{ flex: 1 }} size="small">
            <Autocomplete
              disablePortal
              id="judges-participating-label"
              isOptionEqualToValue={(
                option: NewJudgeType | null,
                value: NewJudgeType | null
              ) => option?.account_id === value?.account_id}
              options={judgesListItems}
              value={newJudge}
              onChange={(_, newJudge: NewJudgeType | null) =>
                setNewJudge(newJudge)
              }
              getOptionLabel={(option) => option.name}
              getOptionDisabled={({ account_id }) =>
                Object.keys(judges).includes(account_id)
              }
              renderInput={(params) => (
                <TextField {...params} size="small" label="Judes" />
              )}
            />
          </FormControl>
          <PrimaryButton
            onClick={() => newJudge && addReferee(newJudge.account_id)}
            disabled={!newJudge}
          >
            Add judge
          </PrimaryButton>
        </Box>
      )}
      <Box
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          paddingInline: '10px',
        }}
      >
        {judges.map((judge) => {
          // const judge = getFundParticipant(funds, id);
          const isCanRemoveJudge = judge.votesGiven === 0;
          const { logo } = getCorrectIPFSLinks({
            logo: (judge as any)?.user?.picture || '',
          });
          return (
            <Box
              key={judge.id}
              sx={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                padding: '10px',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '10px',
                mb: 1,
              }}
            >
              <img
                style={{ width: '50px', height: '50px' }}
                src={logo}
                alt="Cannot resolve logo"
              />
              <Box sx={{ maxWidth: '465px', width: '100%' }}>
                <Typography>{judge?.userId || 'Unknown fund'}</Typography>
                <Typography>
                  {(judge as any)?.user?.display_name || 'Unknown fund'}
                </Typography>
                <Typography
                  sx={{
                    maxWidth: '450px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {(judge as any)?.user?.email || 'Unknown fund'}
                </Typography>
                <Typography>
                  Votes left: {votesLimit - judge.votesGiven}/{votesLimit}
                </Typography>
                {!!judge?.votes?.length && (
                  <Box
                    sx={{
                      width: '100%',
                      padding: '5px',
                      border: '1px solid black',
                      borderRadius: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography>Voted projects</Typography>
                      <Typography>Voted amount</Typography>
                    </Box>
                    {judge.votes.map(({ participantId, voteScore }) => {
                      const sub = participants.find(
                        (p) => p.id === participantId
                      );
                      const project = projects.find(
                        (p) => p.id === sub?.project_id
                      );
                      return (
                        <NavLink
                          to={APP_ROUTES.PROJECT_BY_ID.replace(
                            PROJECT_ID,
                            project?.id || ''
                          )}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              maxWidth: '300px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {project?.name}
                          </Typography>
                          <Typography>{voteScore}</Typography>
                        </NavLink>
                      );
                    })}
                  </Box>
                )}
              </Box>
              {isCanRemoveJudge && !isReadOnly && (
                <CloseIcon
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                  }}
                  onClick={() => removeReferee(judge.id)}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
