import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsInput } from 'shared/components/SettingsInput';
import { IDeclineSubModal } from 'shared/interfaces/modals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 58px',
  boxShadow: 24,
  borderRadius: '10px',
};

export const DeclineSubModal: React.FC<IDeclineSubModal> = ({
  closeModal,
  decline,
  isActualData,
}): JSX.Element => {
  const [comment, setComment] = useState<string>('');

  const onDecline = () => {
    decline(comment);
    closeModal();
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          {!isActualData ? (
            <>
              <Typography
                sx={{
                  fontFamily: 'Everett-Regular',
                  fontSize: '1.125rem',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Data is not actual, refresh before approving please
              </Typography>
              <PrimaryButton
                sx={{
                  marginInline: '25%',
                  width: '50%',
                  height: '32px',
                  marginTop: '20px',
                }}
                variant="contained"
                onClick={() => closeModal()}
              >
                Close
              </PrimaryButton>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: 'Everett-Regular',
                  fontSize: '1.125rem',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Decline
              </Typography>
              <SettingsInput
                inputLabel="Comment: "
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={6}
                sx={{
                  '.MuiFormControlLabel-root': { alignItems: 'flex-start' },
                }}
                inputStyles={{
                  '.MuiOutlinedInput-input': {
                    background: 'rgb(51, 51, 51)',
                    color: 'white',
                    padding: '4px 6px',
                  },
                  '.MuiOutlinedInput-root': { padding: '0px' },
                }}
              />
              <PrimaryButton
                sx={{ width: '100%', height: '42px', marginTop: '20px' }}
                variant="contained"
                onClick={onDecline}
              >
                Decline
              </PrimaryButton>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
