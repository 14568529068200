import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { FormikProps } from 'formik';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { PreviewImage } from 'shared/components/PreviewImage/PreviewImage';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';

import { IHackakathonTaskState } from './Tasks';

interface ITaskLogoProps {
  i: number;
  formik: FormikProps<IHackakathonTaskState[]>;
  isEventVoteEnded: boolean;
}

export const TaskLogo: React.FC<ITaskLogoProps> = ({
  formik,
  i,
  isEventVoteEnded,
}) => {
  const { showModal } = useModalContext();

  const openPictureModal = () => {
    const image = formik.values[i].logo;
    const projectName = formik.values[i].title;
    showModal(EModals.UPLOAD_IMAGE_MODAL, {
      submitImage: (imageCid) => {
        formik.setFieldValue(`${i}.logo`, imageCid);
      },
      image,
      imageName: projectName,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '6px 0',
          alignItems: 'center',
        }}
      >
        <FormLabel>Task logo</FormLabel>
        <PrimaryButton
          sx={{
            width: 'fit-content',
            fontSize: '12px',
            height: '30px',
          }}
          disabled={isEventVoteEnded}
          onClick={() => openPictureModal()}
        >
          Upload
        </PrimaryButton>
      </div>
      <PreviewImage image={formik.values[i].logo} />
    </Box>
  );
};
