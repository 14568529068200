import { IFund, IGrant } from '@pitchtalk/contract-api-js/dist/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface IFundsData {
  funds: IFund[];
  grants: { [key: string]: IGrant[] };
}

const initialState: IFundsData = {
  funds: [] as IFund[],
  grants: {},
};

const fundsSlice = createSlice({
  name: 'fundsData',
  initialState,
  reducers: {
    setFunds: (state, action: PayloadAction<IFund[]>) => {
      state.funds = action.payload;
    },
    setFundsGrant: (
      state,
      action: PayloadAction<{ accountId: string; grants: IGrant[] }>
    ) => {
      const { accountId, grants } = action.payload;
      state.grants[accountId] = grants;
    },
  },
});

export const { setFunds, setFundsGrant } = fundsSlice.actions;

export const selectFunds = (state: RootState) => state.fundsData.funds;
export const selectFundsGrant = (state: RootState) => state.fundsData.grants;

export const fundsReducer = fundsSlice.reducer;
