import { Vesting } from '@pitchtalk/contract-api-js/dist/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export type IVestingState = { [key: string]: Vesting | null };

const initialState: IVestingState = {};

const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers: {
    setVesting: (
      state,
      action: PayloadAction<Vesting & { projectId: string }>
    ) => {
      const { projectId, ...rest } = action.payload;
      state[projectId] = {
        ...state?.[projectId],
        ...rest,
      };
    },
  },
});

export const { setVesting } = vestingSlice.actions;

export const selectVesting = (state: RootState) => state.vesting;

export const selectVestingByProject = createSelector(
  [selectVesting, (_: RootState, projectId: string) => projectId],
  (vesting: IVestingState, projectId: string) => {
    return vesting[projectId] || null;
  }
);

export const vestingReducer = vestingSlice.reducer;
