import { EProjectType } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useState } from 'react';
import PitchTalkServiceAPI from 'services/api-contract/PitchTalkServiceAPI';
import WalletService from 'services/api-contract/WalletService';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { useParams } from 'services/router';
import {
  approveEventProjectSubmission,
  approveRefereeSubmission,
  closeProjectSubmission,
  pendingEventRefereeSubmission,
  rejectEventProjectSubmission,
  rejectEventRefereeSubmission,
} from 'store/thunks/events';

export const useControls = () => {
  const { eventId = '' } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const addProject = (projectId: number | string, projectType: EProjectType) =>
    {
      // return WalletService.signTransactions(
      //   PitchTalkServiceAPI.addEventProject(
      //     eventId,
      //     projectId.toString(),
      //     projectType
      //   )
      // );
    };
  const addJudge = (accountId: string) =>
    {
      // return WalletService.signTransactions(
      //   PitchTalkServiceAPI.addEventJudge(eventId, accountId)
      // );
    };

  const approveProject = (
    submissionId: string,
    projectId: number | string,
    projectType: EProjectType
  ) => {
    setIsLoading(true);
    dispatch(approveEventProjectSubmission(eventId, submissionId))
      // .then(() => addProject(projectId, projectType))
      .finally(() => setIsLoading(false));
  };
  const pendingReferee = (refereeId: string, accountId: string) => {
    setIsLoading(true);
    dispatch(pendingEventRefereeSubmission(eventId, refereeId))
      // .then(() => addJudge(accountId))
      .finally(() => setIsLoading(false));
  };

  const rejectProject = (submissionId: string) => {
    setIsLoading(true);
    dispatch(
      rejectEventProjectSubmission(eventId, submissionId)
    ).finally(() => setIsLoading(false));
  };
  const rejectReferee = (refereeId: string) => {
    setIsLoading(true);
    dispatch(rejectEventRefereeSubmission(eventId, refereeId)).finally(
      () => setIsLoading(false)
    );
  };

  const closeProjectSubmissionRequest = (submissionId: string) => {
    setIsLoading(true);
    dispatch(closeProjectSubmission(eventId, submissionId)).finally(
      () => setIsLoading(false)
    );
  };
  const approveRefereeSubmissionRequest = (refereeId: string) => {
    setIsLoading(true);
    dispatch(approveRefereeSubmission(eventId, refereeId)).finally(() =>
      setIsLoading(false)
    );
  };

  const onTryAgainProject = (
    projectId: string | number,
    projectType: EProjectType
  ) => {
    setIsLoading(true);
    // addProject(projectId, projectType).then(() => setIsLoading(false));
  };
  const onTryAgainReferee = (accountId: string) => {
    setIsLoading(true);
    // addJudge(accountId).then(() => setIsLoading(false));
  };

  return {
    approveProject,
    pendingReferee,
    rejectProject,
    rejectReferee,
    closeProjectSubmissionRequest,
    approveRefereeSubmissionRequest,
    onTryAgainProject,
    onTryAgainReferee,
    isLoading,
  };
};
