import {
  FinalExecutionOutcome,
  NetworkId,
  setupWalletSelector,
  Transaction,
} from '@near-wallet-selector/core';
import type { WalletSelector, AccountState } from '@near-wallet-selector/core';
import { setupModal } from '@near-wallet-selector/modal-ui';
import type { WalletSelectorModal } from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import myNearWalletIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';
import { setupNearWallet } from '@near-wallet-selector/near-wallet';
import nearWalletIconUrl from '@near-wallet-selector/near-wallet/assets/near-wallet-icon.png';
import { setupSender } from '@near-wallet-selector/sender';
import senderWalletIconUrl from '@near-wallet-selector/sender/assets/sender-icon.png';
import {
  getGas,
  getNearAmount,
  ITransaction,
} from '@pitchtalk/contract-api-js/dist/pitchtalk';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { map, distinctUntilChanged } from 'rxjs';
import { contract, networkId, myNearWalletUrl } from 'services/config';
import { parseTransactions } from 'shared/utils/transactionsUtils';

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  openModal: () => void;
  isSignedIn: () => boolean;
  requestSignTransactions: (
    t: ITransaction[],
    callbackUrl?: string
  ) => Promise<void | FinalExecutionOutcome[]>;
  accountId: string;
  signOut: () => Promise<void>;
}

const WalletSelectorContext = React.createContext<WalletSelectorContextValue>(
  {} as WalletSelectorContextValue
);

export const WalletSelectorContextProvider: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accountId, setAccountId] = useState<string>('');

  const syncAccountState = (
    currentAccountId: string | null,
    newAccounts: Array<AccountState>
  ) => {
    if (!newAccounts.length) {
      localStorage.removeItem('accountId');
      setAccountId('');

      return;
    }

    const validAccountId =
      currentAccountId &&
      newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId
      ? currentAccountId
      : newAccounts[0].accountId;

    localStorage.setItem('accountId', newAccountId);
    setAccountId(newAccountId);
  };

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: networkId as NetworkId,
      debug: true,
      modules: [
        setupNearWallet({ iconUrl: nearWalletIconUrl }),
        setupMyNearWallet({
          iconUrl: myNearWalletIconUrl,
          walletUrl: myNearWalletUrl,
        }),
        setupSender({ iconUrl: senderWalletIconUrl }),
      ],
    });
    const modalInstance = setupModal(selectorInstance, {
      contractId: contract,
    });
    const state = selectorInstance.store.getState();

    syncAccountState(localStorage.getItem('accountId'), state.accounts);
    window.selector = selectorInstance;
    window.modal = modalInstance;

    setSelector(selectorInstance);
    setModal(modalInstance);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        syncAccountState(accountId, nextAccounts);
      });

    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
  }, [selector, accountId]);

  const isSignedIn = useCallback(
    () => (selector && selector.isSignedIn()) || false,
    [selector]
  );
  const requestSignTransactions = useCallback(
    async (transactionsForSign: ITransaction[], callbackUrl?: string) => {
      if (!selector) return console.log('No wallet selected');

      const transactions: Transaction[] = transactionsForSign.map(
        (transaction: ITransaction) => ({
          signerId: accountId,
          receiverId: transaction.receiverId,
          actions: transaction.functionCalls.map((fc) => ({
            type: 'FunctionCall',
            params: {
              methodName: fc.methodName,
              args: fc.args || {},
              gas: getGas(fc.gas).toString(),
              deposit: getNearAmount(fc.amount).toString(),
            },
          })),
        })
      );

      const walletInstance = await selector.wallet();
      const result = await walletInstance.signAndSendTransactions({
        transactions,
        callbackUrl,
      });
      if (result) {
        parseTransactions(result);
      }
      return result;
    },
    [selector]
  );

  const openModal = useCallback(() => {
    if (!modal) return;

    modal.show();
  }, [modal]);

  const signOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();

      await wallet.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [selector]);

  if (!selector || !modal) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider
      value={{
        isSignedIn,
        accountId,
        openModal,
        requestSignTransactions,
        signOut,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  return useContext(WalletSelectorContext);
}
