import React, {
  useContext,
  useState,
  useCallback,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import EStorageKeys from 'services/constants/localStorageKeys';
import * as LocalStorage from 'services/localStorage';
import * as Modals from 'shared/components/Modals';
import {
  EModals,
  IConfirmModal,
  IEditFeeModal,
  ISignOutModal,
  IWithdrawModal,
  IInfoModal,
  IApproveSubModal,
  IDeclineSubModal,
  IWhatChangedSubModal,
  IResettingSubModal,
  ITeamMemberSettingsModal,
  IAuthServerModal,
  IUploadImageModal,
} from 'shared/interfaces/modals';

type IModalsProps = {
  [EModals.SIGN_OUT_MODAL]: ISignOutModal;
  [EModals.INFO_MODAL]: IInfoModal;
  [EModals.CONFIRM_MODAL]: IConfirmModal;
  [EModals.WITHDRAW_MODAL]: IWithdrawModal;
  [EModals.EDIT_FEE_MODAL]: IEditFeeModal;
  [EModals.APPROVE_SUB_MODAL]: IApproveSubModal;
  [EModals.DECLINE_SUB_MODAL]: IDeclineSubModal;
  [EModals.RESETTING_SUB_MODAL]: IResettingSubModal;
  [EModals.WHAT_CHANGED_SUB_MODAL]: IWhatChangedSubModal;
  [EModals.TEAM_MEMBER_SETTINGS]: ITeamMemberSettingsModal;
  [EModals.AUTH_SERVER_MODAL]: IAuthServerModal;
  [EModals.UPLOAD_IMAGE_MODAL]: IUploadImageModal;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
};

type IModals = {
  [EModals.SIGN_OUT_MODAL]: React.FC<ISignOutModal>;
  [EModals.INFO_MODAL]: React.FC<IInfoModal>;
  [EModals.CONFIRM_MODAL]: React.FC<IConfirmModal>;
  [EModals.WITHDRAW_MODAL]: React.FC<IWithdrawModal>;
  [EModals.EDIT_FEE_MODAL]: React.FC<IEditFeeModal>;
  [EModals.APPROVE_SUB_MODAL]: React.FC<IApproveSubModal>;
  [EModals.DECLINE_SUB_MODAL]: React.FC<IDeclineSubModal>;
  [EModals.RESETTING_SUB_MODAL]: React.FC<IResettingSubModal>;
  [EModals.WHAT_CHANGED_SUB_MODAL]: React.FC<IWhatChangedSubModal>;
  [EModals.TEAM_MEMBER_SETTINGS]: React.FC<ITeamMemberSettingsModal>;
  [EModals.AUTH_SERVER_MODAL]: React.FC<IAuthServerModal>;
  [EModals.UPLOAD_IMAGE_MODAL]: React.FC<IUploadImageModal>;
  [EModals.EMPTY]: any;
};

const MODALS: IModals = {
  [EModals.SIGN_OUT_MODAL]: Modals.SignOutModal,
  [EModals.INFO_MODAL]: Modals.InfoModal,
  [EModals.CONFIRM_MODAL]: Modals.ConfirmModal,
  [EModals.WITHDRAW_MODAL]: Modals.WithdrawModal,
  [EModals.EDIT_FEE_MODAL]: Modals.EditFeeModal,
  [EModals.APPROVE_SUB_MODAL]: Modals.ApproveSubModal,
  [EModals.DECLINE_SUB_MODAL]: Modals.DeclineSubModal,
  [EModals.RESETTING_SUB_MODAL]: Modals.ResettingSubModal,
  [EModals.WHAT_CHANGED_SUB_MODAL]: Modals.WhatChangedSubModal,
  [EModals.TEAM_MEMBER_SETTINGS]: Modals.TeamMemberSettingsModal,
  [EModals.AUTH_SERVER_MODAL]: Modals.AuthServerModal,
  [EModals.UPLOAD_IMAGE_MODAL]: Modals.UploadImageModal,
  [EModals.EMPTY]: null,
};

interface IInternalProviderState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IInternalProviderState = {
  modal: EModals.EMPTY,
  props: null,
};

type ModalProps<T extends EModals> = Omit<IModalsProps[T], 'closeModal'>;

interface IContextState {
  modal: EModals;
  props: IModalsProps[EModals];
  showModal: <T extends EModals>(modal: T, props: ModalProps<T>) => void;
  closeModal: () => void;
  shouldConfirmModalShow: boolean;
}

const initialContextState: IContextState = {
  modal: EModals.EMPTY,
  props: null,
  showModal: () => undefined,
  closeModal: () => undefined,
  shouldConfirmModalShow: true,
};

const ModalContext = createContext<IContextState>(initialContextState);
export const useModalContext = (): IContextState => useContext(ModalContext);

type ModalProviderProps = {
  children: JSX.Element;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({
  children,
}): JSX.Element => {
  const [state, setState] = useState<IInternalProviderState>(initialState);
  const [shouldShow, setShouldShow] = useState<boolean>(true);
  const { modal, props } = state;

  useEffect(() => {
    const getIsShouldShowModal = () => {
      const doNotShowModals = LocalStorage.getItem(
        EStorageKeys.DO_NOT_SHOW_MODALS
      );
      setShouldShow(!doNotShowModals);
    };

    getIsShouldShowModal();
    window.addEventListener(LocalStorage.STORAGE_EVENT, getIsShouldShowModal);
    return () => {
      window.removeEventListener(
        LocalStorage.STORAGE_EVENT,
        getIsShouldShowModal
      );
    };
  }, []);

  const closeModal = useCallback(() => {
    setState(initialState);
  }, []);

  const showModal = useCallback(
    <T extends EModals>(modal: T, props: ModalProps<T>) =>
      setState({ modal, props }),
    [setState]
  );

  const Component: IModalsProps[EModals] = modal && MODALS[modal];

  const contextValue = useMemo(
    () => ({
      ...state,
      showModal,
      closeModal,
      shouldConfirmModalShow: shouldShow,
    }),
    [state, shouldShow, showModal, closeModal]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {Component && <Component closeModal={closeModal} {...props} />}
    </ModalContext.Provider>
  );
};
