import { fetchAPI } from 'services/api/ServerAPI';
import { ToastService } from 'services/toast/ToastService';

import { EventSettings } from './helpers';

export const createNewEvent = (event: EventSettings) =>
  fetchAPI
    .post('event', {
      type: event.type,
      name: event.name,
      description: event.description,
      short_description: event.short_description,
      prize_pool: event.prize_pool,
      web_url: event.web_url,
      video_url: event.video_url,
      banner: event.banner,
      logo: event.logo,
      start_date: event.start_date,
      end_date: event.end_date,
      vote_end_date: event.vote_end_date,
      social_links: event.social_links,
      votes_limit: Number(event.votes_limit),
      location: event.location,
      eventTag: event.tag,
      eventNews: event.isNews,
      participants_limit: event.participants_limit,
      tags: event.tags.map((tag) => (typeof tag === 'string' ? tag : tag.id)),
      prizes: [],
      partners: '',
    })
    .then(() => ToastService.success('', 'Event successfully created'))
    .catch(() => ToastService.error('', 'Event create error'));

export const updateEvent = (eventId: string, event: EventSettings) =>
  fetchAPI
    .patch(`event/${eventId}`, {
      type: event.type,
      name: event.name,
      description: event.description,
      short_description: event.short_description,
      prize_pool: event.prize_pool,
      web_url: event.web_url,
      video_url: event.video_url,
      banner: event.banner,
      logo: event.logo,
      start_date: event.start_date,
      end_date: event.end_date,
      vote_end_date: event.vote_end_date,
      social_links: event.social_links,
      votes_limit: event.votes_limit,
      location: event.location,
      eventTag: event.tag,
      eventNews: event.isNews,
      participants_limit: event.participants_limit,
      tags: event.tags.map((tag) => (typeof tag === 'string' ? tag : tag.id)),
    })
    .then(() => ToastService.success('', 'Event successfully updated'))
    .catch(() => ToastService.error('', 'Event update error'));

export const acceptEventPublicRequest = (eventId: string) =>
  fetchAPI
    .post(`event/private/${eventId}/public-request-accept`)
    .then(() =>
      ToastService.success('', 'Request approved, event is now public')
    )
    .catch(() => ToastService.error('', 'Request approve error'));

export const rejectEventPublicRequest = (eventId: string) =>
  fetchAPI
    .post(`event/private/${eventId}/public-request-reject`)
    .then(() =>
      ToastService.success('', 'Request rejected, event is still private')
    )
    .catch(() => ToastService.error('', 'Request reject error'));
