import { providers } from '@pitchtalk/contract-api-js';
import { EPitchTalkContractChangeMethods } from '@pitchtalk/contract-api-js/dist/core';
import { ESubServiceChangeMethods } from '@pitchtalk/contract-api-js/dist/SubmissionService/methods';
import { explorerUrl } from 'services/config';
import { ToastService } from 'services/toast/ToastService';

enum TransactionType {
  ADMIN,
  FUND,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DEACTIVATE_PROJECT,
  ACTIVATE_PROJECT,
  ATTACHMENT,
  CREATE_PITCH,
  UPDATE_PITCH,
  DEACTIVATE_PITCH,
  ACTIVATE_PITCH,
  WITHDRAW,
  ADD_TOKEN,
  TRANSACTION,
}

enum StatusType {
  None,
  SuccessValue,
  Failure,
}

const methods: {
  [key in
    | (
        | keyof typeof EPitchTalkContractChangeMethods
        | keyof typeof ESubServiceChangeMethods
      )
    | 'FT_TRANSFER_CALL'
    | 'CONFIRM']: string;
} = {
  ...EPitchTalkContractChangeMethods,
  ...ESubServiceChangeMethods,
  FT_TRANSFER_CALL: 'ft_transfer_call',
  // Confirm method using for 2FA
  CONFIRM: 'confirm',
};

const PROPERTY_NAME = 'FunctionCall';

const detailsTransaction = (transaction: providers.FinalExecutionOutcome) => {
  const { hash } = transaction.transaction;
  let successStatus = Object.prototype.hasOwnProperty.call(
    transaction.status,
    'SuccessValue'
  );
  if (
    transaction.receipts_outcome.some((receipt) =>
      Object.prototype.hasOwnProperty.call(receipt.outcome.status, 'Failure')
    )
  ) {
    successStatus = false;
  }

  return {
    hash,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
  };
};

const getTransaction = (transactions: providers.FinalExecutionOutcome[]) => {
  const [transaction] = transactions.filter(
    (tx: providers.FinalExecutionOutcome) =>
      Object.values(methods).indexOf(
        tx.transaction.actions[0][PROPERTY_NAME].method_name
      ) !== -1
  );

  // TODO: Should be refactored
  let type = TransactionType.TRANSACTION;
  switch (
    transaction?.transaction?.actions?.[0]?.[PROPERTY_NAME]?.method_name
  ) {
    // ADMINS
    case methods.ADD_WHITELIST_ACCOUNTS:
    case methods.REMOVE_WHITELIST_ACCOUNTS:
      type = TransactionType.ADMIN;
      break;
    // FUND
    case methods.UPDATE_FUND:
    case methods.UPDATE_FUND_STATUS:
      type = TransactionType.FUND;
      break;
    // PROJECT
    case methods.REGISTER_PROJECT:
      type = TransactionType.CREATE_PROJECT;
      break;
    case methods.UPDATE_PROJECT:
    case methods.UPDATE_DONATIONS_AVAILABLE:
    case methods.UPDATE_INVESTMENTS_AVAILABLE:
    case methods.UPDATE_PROJECT_TOKEN:
    case methods.UPDATE_INVESTMENT_AND_VESTING_DATA:
      type = TransactionType.UPDATE_PROJECT;
      break;
    case methods.REMOVE_PROJECT_ATTACHMENT:
    case methods.ADD_PROJECT_ATTACHMENT:
      type = TransactionType.ATTACHMENT;
      break;
    case methods.ACTIVATE_PROJECT:
      type = TransactionType.ACTIVATE_PROJECT;
      break;
    case methods.DEACTIVATE_PROJECT:
      type = TransactionType.DEACTIVATE_PROJECT;
      break;
    // PITCH
    case methods.INIT_NEXT_PITCH:
      type = TransactionType.CREATE_PITCH;
      break;
    case methods.UPDATE_PITCH:
      type = TransactionType.UPDATE_PITCH;
      break;
    case methods.ACTIVATE_PITCH:
      type = TransactionType.ACTIVATE_PITCH;
      break;
    case methods.DEACTIVATE_PITCH:
      type = TransactionType.DEACTIVATE_PITCH;
      break;
    // WITHDRAW
    case methods.WITHDRAW_DONATIONS:
    case methods.WITHDRAW_INVESTMENTS:
    case methods.WITHDRAW_GRANTS:
      type = TransactionType.WITHDRAW;
      break;
    case methods.ADD_FT_TOKENS:
      type = TransactionType.ADD_TOKEN;
      break;
    case methods.CONFIRM:
    case methods.FT_TRANSFER_CALL:
    default:
      type = TransactionType.TRANSACTION;
  }
  return { type, transaction };
};

export const analyzeTransactions = (
  transactions: providers.FinalExecutionOutcome[]
): { type: TransactionType; status: StatusType; hash: string } => {
  const { type, transaction } = getTransaction(transactions);
  if (!transaction || type === TransactionType.TRANSACTION) {
    return { type, status: StatusType.None, hash: '' };
  }
  const { hash, status } = detailsTransaction(transaction);
  return { type, status, hash };
};

export const parseTransactions = (txs: providers.FinalExecutionOutcome[]) => {
  const result: {
    type: TransactionType;
    status: StatusType;
    hash: string;
  } = analyzeTransactions(txs);
  const href = `${explorerUrl}/transactions/${result.hash}`;
  switch (result.type) {
    // UNKNOWN
    case TransactionType.TRANSACTION:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Transaction success');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Transaction failed');
      } else {
        return ToastService.info('', 'Transaction done');
      }
    // ADMIN
    case TransactionType.ADMIN:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Admins was successfully saved');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Admin update error');
      }
      break;
    case TransactionType.FUND:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Fund was successfully saved');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Fund update error');
      }
      break;
    // PROJECT
    case TransactionType.CREATE_PROJECT:
    case TransactionType.UPDATE_PROJECT:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Project was saved');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Project update error');
      }
      break;
    case TransactionType.ATTACHMENT:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Attachments was saved');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Attachments saving error');
      }
      break;
    case TransactionType.DEACTIVATE_PROJECT:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Project was deactivated');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Project deactivate error');
      }
      break;
    case TransactionType.ACTIVATE_PROJECT:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Project was activated');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Project activate error');
      }
      break;
    // PITCH
    case TransactionType.CREATE_PITCH:
    case TransactionType.UPDATE_PITCH:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Pitch was saved');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Pitch saving error');
      }
      break;
    case TransactionType.ACTIVATE_PITCH:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Pitch was activated');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Pitch activate error');
      }
      break;
    case TransactionType.DEACTIVATE_PITCH:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Pitch was deactivated');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Pitch deactivate error');
      }
      break;
    // WITHDRAW
    case TransactionType.WITHDRAW:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(
          href,
          'Withdraw was successfully completed'
        );
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Withdraw wasn`t completed');
      }
      break;
    case TransactionType.ADD_TOKEN:
      if (result.status === StatusType.SuccessValue) {
        return ToastService.success(href, 'Tokens was successfully added');
      } else if (result.status === StatusType.Failure) {
        return ToastService.error(href, 'Tokens wasn`t added due to error');
      }
      break;
    default:
      break;
  }
};
