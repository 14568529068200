import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { useState } from 'react';
import { fetcher } from 'services/api/utils';
import { useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { Loader } from 'shared/components/Loader/Loader';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsDatePicker } from 'shared/components/SettingsDatePicker';
import { SettingsInput } from 'shared/components/SettingsInput';
import { TagsSettings } from 'shared/components/TagsSettings/TagsSettings';
import { IEvent } from 'shared/interfaces/backendInterfaces';
import { isEventVoteEnded as checkIsEventVoteEnded } from 'shared/utils/eventsUtils';
import useSWR from 'swr';

import { createNewEvent, updateEvent } from './api';
import { EventAccessType } from './components/EventAccessType';
import { EventAuthor } from './components/EventAuthor';
import { EventPrivateRequest } from './components/EventPrivateRequest';
import { EventType } from './components/EventType';
import { MaxProjectsAmount } from './components/MaxProjectsAmount';
import { MetaDataSettings } from './components/MetaDataSettings';
import { SocialMediaSettings } from './components/SocialMediaSettings';
import { EventSettings, getInitialEventSettings } from './helpers';
import { EventEndedNotification } from '../components/EventEndedNotification';

export const Event: React.FC = () => {
  const { eventId = '' } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: event, isLoading: isEventLoading } = useSWR<IEvent>(
    eventId ? `event/${eventId}` : null,
    fetcher
  );

  const formik = useFormik<EventSettings>({
    initialValues: getInitialEventSettings(event),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (!eventId) {
        await createNewEvent(values).finally(() => setIsLoading(false));
      } else {
        await updateEvent(eventId, values).finally(() => setIsLoading(false));
      }
    },
  });

  const isEventVoteEnded = checkIsEventVoteEnded(event);

  const isReadOnlyEvent = !!(isEventVoteEnded || isLoading);

  if (isEventLoading) return <Loader />;

  return (
    <Box>
      <EventEndedNotification event={event} />
      {!event?.is_public && event?.publicRequest && (
        <EventPrivateRequest eventId={event.id} />
      )}
      {event?.authorId && <EventAuthor authorId={event?.authorId} />}
      <Box sx={{ width: '1400px', display: 'flex', gap: '25px' }}>
        <SettingsBox
          legend="General Event Settings"
          sx={{
            padding: '10px',
            width: '480px',
            height: 'fit-content',
            marginBottom: '20px',
            '.MuiFormGroup-root': { paddingBlock: '5px' },
          }}
        >
          <Box>
            <EventType formik={formik} isReadOnly={isReadOnlyEvent} />
            <EventAccessType formik={formik} isReadOnly={isReadOnlyEvent} />
            <SettingsInput
              inputLabel="Name"
              name="name"
              value={formik.values.name}
              onFocus={() => formik.setFieldTouched('name', true)}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              error={formik.touched.name && !!formik.errors.name}
              helperText={
                formik.touched.name && formik.errors && formik.errors.name
              }
              InputProps={{ readOnly: isReadOnlyEvent }}
            />
            <SettingsDatePicker
              label="Start date"
              value={formik.values.start_date}
              onChange={(newTime: Date | null) => {
                if (newTime) formik.setFieldValue('start_date', newTime);
                formik.setFieldTouched('start_date', true, false);
              }}
              minDate={new Date()}
              error={!!formik.errors.start_date}
              helperText={formik.errors.start_date as string}
              readOnly={isReadOnlyEvent}
            />
            <SettingsDatePicker
              label="End date"
              value={formik.values.end_date}
              onChange={(newTime: Date | null) => {
                if (newTime) formik.setFieldValue('end_date', newTime);
                formik.setFieldTouched('end_date', true, false);
              }}
              minDate={formik.values.start_date}
              error={!!formik.errors.end_date}
              helperText={formik.errors.end_date as string}
              readOnly={isReadOnlyEvent}
            />
            <SettingsDatePicker
              label="Vote end date"
              value={formik.values.vote_end_date}
              onChange={(newTime: Date | null) => {
                if (newTime) formik.setFieldValue('vote_end_date', newTime);
                formik.setFieldTouched('vote_end_date', true, false);
              }}
              minDate={formik.values.end_date}
              error={!!formik.errors.vote_end_date}
              helperText={formik.errors.vote_end_date as string}
              readOnly={isReadOnlyEvent}
            />
            <SettingsInput
              inputLabel="Votes limit"
              name="votes_limit"
              value={formik.values.votes_limit || 0}
              onFocus={() => formik.setFieldTouched('votes_limit', true)}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                formik.setFieldValue(
                  'votes_limit',
                  parseInt(e.target.value) || 0
                )
              }
              error={
                formik.touched?.votes_limit && !!formik.errors?.votes_limit
              }
              helperText={
                formik.touched?.votes_limit &&
                formik.errors &&
                formik.errors?.votes_limit
              }
              InputProps={{ readOnly: isReadOnlyEvent }}
            />
            <Box>
              <MaxProjectsAmount formik={formik} isReadOnly={isReadOnlyEvent} />
              <TagsSettings
                isReadOnly={isEventVoteEnded}
                selectedTags={formik.values.tags.map((tag) =>
                  typeof tag === 'string' ? tag : tag.id
                )}
                handleSelectTags={(event) =>
                  formik.setFieldValue('tags', event.target.value)
                }
                errors={formik.errors.tags as string}
                max={3}
              />
            </Box>
          </Box>
        </SettingsBox>
        <MetaDataSettings formik={formik} isReadOnly={isReadOnlyEvent} />
        <Box sx={{ width: '400px' }}>
          <SocialMediaSettings formik={formik} isReadOnly={isReadOnlyEvent} />
        </Box>
      </Box>
      {!isReadOnlyEvent && (
        <Box>
          <PrimaryButton
            onClick={() => formik.handleSubmit()}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {eventId ? 'Update' : 'Create'} event
          </PrimaryButton>
        </Box>
      )}
    </Box>
  );
};
