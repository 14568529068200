import { providers } from '@pitchtalk/contract-api-js';
import { useWalletSelector } from 'providers/WalletsProvider';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { nodeUrl } from 'services/config';
import { ToastService } from 'services/toast/ToastService';
import { parseTransactions } from 'shared/utils/transactionsUtils';

const TRANSACTION_HASHES = 'transactionHashes';
const ERROR_CODE = 'errorCode';
const ERROR_MESSAGE = 'errorMessage';

export function useTransactionHash() {
  const navigate = useNavigate();
  const { accountId } = useWalletSelector();
  const location = useLocation();

  const cleanUrl = useCallback(
    (query: URLSearchParams) => {
      if (
        query.has(TRANSACTION_HASHES) ||
        query.has(ERROR_CODE) ||
        query.has(ERROR_MESSAGE)
      ) {
        query.delete(TRANSACTION_HASHES);
        query.delete(ERROR_CODE);
        query.delete(ERROR_MESSAGE);

        navigate({
          search: query.toString(),
        });
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!location.search || !accountId) return;

    const queryParams = new URLSearchParams(location.search);
    const transactions = queryParams?.get(TRANSACTION_HASHES);
    const errorCode = queryParams?.get(ERROR_CODE);
    const errorMessage = queryParams?.get(ERROR_MESSAGE);
    if (errorCode || errorMessage) {
      ToastService.error('', 'Unknown error');
      cleanUrl(queryParams);
      return;
    }

    if (transactions && accountId) {
      const provider = new providers.JsonRpcProvider(nodeUrl);
      try {
        Promise.all(
          transactions
            .split(',')
            .map((txHash) => provider.txStatus(txHash, accountId))
        ).then((res) => parseTransactions(res));
      } catch (e) {
        console.warn(`${e} error while loading tx`);
      }
    }

    cleanUrl(queryParams);
  }, [location.search, accountId, navigate, cleanUrl]);
}
