import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EUniqWithdrawType } from '@pitchtalk/contract-api-js/dist/core';
import DefaultTokenIcon from 'assets/images/icons/defaultToken-icon.svg';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { useParams } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { DEFAULT_TOKEN_META, WNEAR_SYMBOL } from 'shared/constants';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { isEventVoteEnded } from 'shared/utils/eventsUtils';
import { getAmountFormatted, roundToLow } from 'shared/utils/near';
import { selectEventById } from 'store/selectors/events';
import { selectTokens } from 'store/slices/tokensWhiteList';
import { selectIsOwner } from 'store/slices/user';

export const EventFee: React.FC = () => {
  const { eventId = '' } = useParams();
  const { showModal } = useModalContext();
  const isOwner = useAppSelector(selectIsOwner);
  const event = useAppSelector((state) => selectEventById(state, eventId));
  const tokens = useAppSelector(selectTokens);
  const fee = event?.fee || undefined;
  const feeTokenData = DEFAULT_TOKEN_META;
  // ? tokens[fee?.token_id]
  // : DEFAULT_TOKEN_META;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginBottom: '15px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '650px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ fontSize: '1.15rem' }}>
                Current Fee
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: '400px',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                <span>
                  {roundToLow(
                    +getAmountFormatted(
                      fee?.amount || '0',
                      feeTokenData?.decimals,
                      feeTokenData?.decimals
                    ),
                    3
                  )}
                </span>
                <span>{feeTokenData?.symbol || WNEAR_SYMBOL}</span>
                <img
                  src={feeTokenData?.icon || DefaultTokenIcon}
                  loading="lazy"
                  style={{ width: 30, height: 30 }}
                  alt="Fee token icon"
                />
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '650px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ fontSize: '1.15rem' }}>
                Total fee tokens
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: '400px',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                <span>
                  {roundToLow(
                    +getAmountFormatted(
                      fee?.total_fee || '0',
                      feeTokenData?.decimals,
                      feeTokenData?.decimals
                    ),
                    3
                  )}
                </span>
                <span>{feeTokenData?.symbol || WNEAR_SYMBOL}</span>
                <img
                  src={feeTokenData?.icon || DefaultTokenIcon}
                  loading="lazy"
                  style={{ width: 30, height: 30 }}
                  alt="Fee token icon"
                />
              </Typography>
            </Box>
            <PrimaryButton
              sx={{
                width: 'fit-content',
                paddingInline: '30px',
                height: '40px',
              }}
              disabled={
                !(Number(fee?.total_fee) > 0) ||
                !isOwner ||
                !isEventVoteEnded(event as any)
              }
              onClick={() =>
                showModal(EModals.WITHDRAW_MODAL, {
                  withdrawType: EUniqWithdrawType.EVENT_FEE,
                  isWithdrawAll: true,
                  eventMeta: fee,
                  event,
                })
              }
            >
              Withdraw
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
