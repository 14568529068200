import { fetchAPI } from 'services/api/ServerAPI';
import { parseData } from 'services/api/utils';

export const getEventsCount = async (search: Record<string, any>) => {
  const searchParams = new URLSearchParams();

  Object.keys(search).forEach((key) => {
    const value = search?.[key];
    if (value) searchParams.append(key, value.toString());
  });

  return fetchAPI
    .get<number>(`event/count?${searchParams.toString()}`)
    .then(parseData);
};
