import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Project } from '@pitchtalk/contract-api-js/dist/core';
import {
  EEventSubmissionStatus,
  EProjectType,
  IOffChainProject,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { useParams } from 'services/router';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { isEventVoteEnded } from 'shared/utils/eventsUtils';
import { getFundParticipant } from 'shared/utils/fundUtils';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import {
  selectEventById,
  selectEventParticipants,
  selectEventSubmissions,
} from 'store/selectors/events';
import { selectFunds } from 'store/slices/funds';
import {
  selectOffChainProjectsAllData,
  selectProjects,
} from 'store/slices/projects';
import { selectSubmissionProjects } from 'store/slices/submission';

import { SubmissionControls } from './components/SubmissionControls';
import { SubmissionId } from './components/SubmissionId';
import { SubmissionStatus } from './components/SubmissionStatus';
import { submissionBox, submissionsWrapper } from './styles';
import { useControls } from './useControls';
import { EventEndedNotification } from '../components/EventEndedNotification';
import { IProject } from 'shared/interfaces/backendInterfaces';

export const EventSubmissions: React.FC = () => {
  const { eventId = '' } = useParams();
  const event = useAppSelector((state) => selectEventById(state, eventId));
  const funds = useAppSelector(selectFunds);
  // const offChainProjects = useAppSelector(selectOffChainProjectsOrigins);
  const allProjects = useAppSelector(selectOffChainProjectsAllData);
  const onChainSubProjects = useAppSelector(selectSubmissionProjects);
  const { projectsSubmissions, refereeSubmissions } = useAppSelector((state) =>
    selectEventSubmissions(state, { eventId })
  );
  const { projects, judges } = useAppSelector((state) =>
    selectEventParticipants(state, eventId)
  );

  const {
    approveProject,
    pendingReferee,
    rejectProject,
    rejectReferee,
    closeProjectSubmissionRequest,
    approveRefereeSubmissionRequest,
    onTryAgainProject,
    onTryAgainReferee,
    isLoading,
  } = useControls();

  const isProjectsLimit = event?.max_project_count
    ? Object.keys(projects).length >= event.max_project_count
    : false;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {/* <EventEndedNotification /> */}
      {!projectsSubmissions.length && !refereeSubmissions.length ? (
        <Typography sx={{ textAlign: 'center', fontSize: '1.25rem' }}>
          There are no submissions
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '1250px',
            gap: '50px',
          }}
        >
          {isLoading && <LoaderWithOverlay />}
          <Box sx={{ width: '600px' }}>
            <Typography sx={{ textAlign: 'center', fontSize: '1.25rem' }}>
              Projects
            </Typography>
            <Box sx={submissionsWrapper}>
              {projectsSubmissions.map((submission) => {
                const project = allProjects.find(
                  (project) => project.id === submission.project_id
                );
                const { logo } = getCorrectIPFSLinks({
                  logo: project?.logo || '',
                });

                if (!project) return null;

                const author = ((project as IProject)?.contact_links as any)
                  ?.email;

                return (
                  <Box key={submission.id} sx={submissionBox}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        color: 'black',
                        width: '100%',
                      }}
                      component="a"
                      href={project?.project_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={logo}
                        alt="Cannot resolve logo"
                      />
                      <Box>
                        <Typography>
                          <b>Type: </b>
                          <span>{submission.project_type}</span>
                        </Typography>
                        <Typography>
                          <b>Id: </b>
                          <span>{submission.project_id}</span>
                        </Typography>
                        <Typography>
                          <b>Name: </b>
                          <span>{project.name}</span>
                        </Typography>
                        <Typography>
                          <b>Author: </b>
                          <span>{author}</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <SubmissionControls
                        status={submission.status}
                        isParticipantAdded={
                          submission.status ===
                          EEventSubmissionStatus.APPROVED_PENDING
                        }
                        isDisabled={
                          isEventVoteEnded(event as any) || isProjectsLimit
                        }
                        onApprove={() =>
                          approveProject(
                            submission.id,
                            submission.project_id,
                            submission.project_type
                          )
                        }
                        onTryAgain={() =>
                          onTryAgainProject(
                            submission.project_id,
                            submission.project_type
                          )
                        }
                        onCloseRequest={() =>
                          closeProjectSubmissionRequest(submission.id)
                        }
                        onReject={() => rejectProject(submission.id)}
                      />
                      <SubmissionStatus status={submission.status} />
                    </Box>
                    <SubmissionId id={submission.id} />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ width: '600px' }}>
            <Typography sx={{ textAlign: 'center', fontSize: '1.25rem' }}>
              Judges
            </Typography>
            <Box sx={submissionsWrapper}>
              {refereeSubmissions.map((submission: any) => {
                const judge = getFundParticipant(funds, submission.account_id);

                const logo = getCorrectIPFSLinks({
                  logo: submission?.user?.picture || '',
                }).logo;

                return (
                  <Box
                    key={submission.id + submission?.user?.id}
                    sx={submissionBox}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        color: 'black',
                        width: '100%',
                      }}
                      component="a"
                      // href={judge?.web_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={logo}
                        alt="Cannot resolve logo"
                      />
                      <Box>
                        <Typography>
                          <b>Fund Account: </b>
                          <span>{submission?.user?.email}</span>
                        </Typography>
                        <Typography>
                          <b>Fund Name: </b>
                          <span>{submission?.user?.display_name}</span>
                        </Typography>
                        <Typography>
                          <b>ID: </b>
                          <span>{submission?.user?.id}</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <SubmissionControls
                        status={submission.status}
                        isParticipantAdded={
                          submission.status ===
                          EEventSubmissionStatus.APPROVED_PENDING
                        }
                        onApprove={() =>
                          pendingReferee(submission.id, submission.account_id)
                        }
                        onReject={() => rejectReferee(submission.id)}
                        onCloseRequest={() =>
                          approveRefereeSubmissionRequest(submission.id)
                        }
                        onTryAgain={() =>
                          onTryAgainReferee(submission.account_id)
                        }
                        isDisabled={isEventVoteEnded(event as any)}
                      />
                      <SubmissionStatus status={submission.status} />
                    </Box>
                    <SubmissionId id={submission.id} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
