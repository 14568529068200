import Typography from '@mui/material/Typography';
import { theme } from 'shared/components/theme';

export const SubmissionId: React.FC<{ id: string }> = ({ id }) => (
  <Typography
    sx={{
      position: 'absolute',
      color: theme.palette.grey[700],
      background: 'white',
      top: 0,
      left: 5,
      transform: 'translateY(-50%)',
    }}
  >
    {id}
  </Typography>
);
