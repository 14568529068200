import { Typography } from '@mui/material';
import { DealsPage } from 'pages/DealsPage';
import { EditDealPage } from 'pages/DealsPage/EditDealPage';
import {
  EventActorsPage,
  EventFeePage,
  EventPage,
  EventsListPage,
  EventSubmissionsPage,
  EventSchedulePage,
  EventServerPage,
  HackathonSettingsPage,
} from 'pages/EventsPage';
import { InvestmentsPage } from 'pages/InvestmentsPage';
import {
  PartnerPage,
  PartnersListPage,
  PitchTalkMetaDataPage,
} from 'pages/MetaDataPage';
import { useWalletSelector } from 'providers/WalletsProvider';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import FTService from 'services/api-contract/FTServiceAPI';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import SubService from 'services/api-contract/SubmissionServiceAPI';
import Wallet from 'services/api-contract/WalletService';
import { useAuth } from 'services/hooks/useAuth';
import { Header } from 'shared/components/Header';
import { Loader } from 'shared/components/Loader/Loader';
import { fetchAdmins, initApplication } from 'store/thunks/app';

import { APP_ROUTES, DEAL_ID } from './routes';
import { useAppDispatch, useAppSelector } from '../services/hooks/redux-hooks';
import { Navigate, Route, Router, Routes } from '../services/router';
import {
  selectIsAdmin,
  selectIsOwner,
  selectUserData,
  setIsAdmin,
  setIsOwner,
} from '../store/slices/user';
import { InvestmentPage } from 'pages/InvestmentsPage/InvestmentPage';

// TODO: NEED REFACTORING
const Management = lazy(() =>
  import('../pages/ManagementPage').then((module) => ({
    default: module.ManagementPage,
  }))
);
const SubmissionManagement = lazy(() =>
  import('../pages/SubmissionManagement').then((module) => ({
    default: module.SubmissionManagement,
  }))
);
// const SubmissionPage = lazy(() =>
//   import('../pages/SubmissionManagement/SubmissionsPage').then((module) => ({
//     default: module.SubmissionPage,
//   }))
// );
const FundsSubmissionsPage = lazy(() =>
  import('../pages/SubmissionManagement/FundsSubmissionsPage').then(
    (module) => ({
      default: module.FundsSubmissionsPage,
    })
  )
);
const SubmissionInstitutionalsPage = lazy(() =>
  import('../pages/SubmissionManagement/InstitutionalsPage').then((module) => ({
    default: module.InstitutionalsPage,
  }))
);
// const SubmissionList = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionsListPage'
//   ).then((module) => ({
//     default: module.SubmissionsListPage,
//   }))
// );
// const SubProjectGeneral = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/GeneralSettings'
//   ).then((module) => ({
//     default: module.SubmissionProject,
//   }))
// );
// const SubProjectFinancial = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/FinancialPage'
//   ).then((module) => ({
//     default: module.FinancialPage,
//   }))
// );
// const SubProjectPitches = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/PitchesPage'
//   ).then((module) => ({
//     default: module.PitchesPage,
//   }))
// );
// const SubProjectLogs = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/Logs'
//   ).then((module) => ({
//     default: module.Logs,
//   }))
// );
// const SubProjectWithdraws = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/WithdrawPage'
//   ).then((module) => ({
//     default: module.WithdrawRequestsPage,
//   }))
// );
// const SubTeamPage = lazy(() =>
//   import(
//     '../pages/SubmissionManagement/SubmissionsPage/SubmissionProject/pages/TeamPage'
//   ).then((module) => ({
//     default: module.TeamPage,
//   }))
// );

const AdminsPage = lazy(() =>
  import('../pages/ManagementPage/AdminsPage').then((module) => ({
    default: module.AdminsPage,
  }))
);
const InstitutionalsPage = lazy(() =>
  import('../pages/ManagementPage/InstitutionalsPage').then((module) => ({
    default: module.InstitutionalsPage,
  }))
);
const FundPage = lazy(() =>
  import('../pages/ManagementPage/FundPage').then((module) => ({
    default: module.FundPage,
  }))
);
const SubmissionFeePage = lazy(() =>
  import('../pages/ManagementPage/SubmissionFeePage').then((module) => ({
    default: module.SubmissionFeePage,
  }))
);

const Projects = lazy(() =>
  import('../pages/ProjectsPage').then((module) => ({
    default: module.ProjectsPage,
  }))
);
const ProjectsList = lazy(() =>
  import('../pages/ProjectsPage/ProjectsListPage').then((module) => ({
    default: module.ProjectsListPage,
  }))
);
const NewProject = lazy(() =>
  import('../pages/ProjectsPage/NewProjectPage').then((module) => ({
    default: module.NewProjectPage,
  }))
);
const EditProject = lazy(() =>
  import('../pages/ProjectsPage/EditProjectPage').then((module) => ({
    default: module.EditProjectPage,
  }))
);
const EditPitches = lazy(() =>
  import('../pages/ProjectsPage/EditProjectPage/EditPitchesPage').then(
    (module) => ({
      default: module.EditPitchesPage,
    })
  )
);

// const FinancialPage = lazy(() =>
//   import('../pages/ProjectsPage/FinancialPage').then((module) => ({
//     default: module.FinancialPage,
//   }))
// );
const TeamPage = lazy(() =>
  import('../pages/ProjectsPage/TeamPage').then((module) => ({
    default: module.TeamPage,
  }))
);

const Transfers = lazy(() =>
  import('../pages/TransfersPage').then((module) => ({
    default: module.TransfersPage,
  }))
);

const Tokens = lazy(() =>
  import('../pages/TokensPage').then((module) => ({
    default: module.TokensPage,
  }))
);

const Tags = lazy(() =>
  import('../pages/TagsPage').then((module) => ({ default: module.TagsPage }))
);

const ChatSettings = lazy(() =>
  import('../pages/ChatSettingsPage').then((module) => ({
    default: module.ChatSettingsPage,
  }))
);

const Welcome = lazy(() =>
  import('../pages/WelcomePage').then((module) => ({
    default: module.WelcomePage,
  }))
);

export const AppRoutes: React.FC = () => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const isOwner = useAppSelector(selectIsOwner);
  const adminServerData = useAppSelector(selectUserData);
  const [isLoading, setIsLoading] = useState(true);
  const hasAccess = isAdmin || isOwner;
  const { accountId, requestSignTransactions } = useWalletSelector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // INIT APPLICATION
    FTService.createService();
    PTService.createService(accountId);
    // SubService.createService(accountId);
    Wallet.createWalletService(requestSignTransactions);

    // TODO: Move admins and userId to redux store
    dispatch(fetchAdmins())
      .then(({ ownerId, admins }) => {
        dispatch(setIsOwner(ownerId === accountId));
        dispatch(setIsAdmin(admins.includes(accountId)));
      })
      .then(() => {
        // TODO_BACK
        adminServerData && dispatch(initApplication());
        setIsLoading(false);
      })
      .catch(console.log);
  }, [accountId, dispatch, adminServerData]);

  useAuth();

  if (isLoading) return <Loader size="9rem" />;

  return (
    <>
      <Suspense fallback={<Loader size="9rem" />}>
        <Router>
          {hasAccess ? <Header /> : null}
          <Routes>
            {hasAccess && adminServerData ? (
              <>
                <Route
                  path={APP_ROUTES.WELCOME}
                  element={<Navigate to={APP_ROUTES.MANAGEMENT} />}
                />
                <Route path={APP_ROUTES.MANAGEMENT} element={<Management />}>
                  <Route index element={<AdminsPage />} />
                  <Route
                    path={APP_ROUTES.INSTITUTIONALS}
                    element={<InstitutionalsPage />}
                  />
                  <Route path={APP_ROUTES.FUND_BY_ID} element={<FundPage />} />
                  <Route
                    path={APP_ROUTES.SUBMISSION_FEE}
                    element={<SubmissionFeePage />}
                  />
                </Route>
                <Route
                  path={APP_ROUTES.SUBMISSIONS}
                  element={<SubmissionManagement />}
                >
                  <Route
                    path={APP_ROUTES.SUBMISSIONS_FUNDS}
                    element={<FundsSubmissionsPage />}
                  />
                </Route>
                <Route path={APP_ROUTES.PROJECTS} element={<Projects />}>
                  <Route index element={<ProjectsList />} />
                  <Route
                    path={APP_ROUTES.PROJECT_BY_ID}
                    element={<EditProject />}
                  />
                  <Route
                    path={APP_ROUTES.PROJECT_BY_ID_PITCHES}
                    element={<EditPitches />}
                  />
                  <Route
                    path={APP_ROUTES.PROJECT_BY_ID_TEAM}
                    element={<TeamPage />}
                  />
                  <Route
                    path={APP_ROUTES.PROJECT_NEW}
                    element={<NewProject />}
                  />
                </Route>
                <Route
                  path={APP_ROUTES.INVESTMENTS}
                  element={<InvestmentsPage />}
                />
                <Route
                  path={APP_ROUTES.INVESTMENTS_BY_ID}
                  element={<InvestmentPage />}
                />
                <Route path={APP_ROUTES.EVENTS} element={<EventsListPage />} />
                <Route path={APP_ROUTES.EVENTS_NEW} element={<EventPage />} />
                <Route path={APP_ROUTES.EVENTS_BY_ID} element={<EventPage />} />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_SERVER}
                  element={<EventServerPage />}
                />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_SERVER_HACKATHON}
                  element={<HackathonSettingsPage />}
                />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_ACTORS}
                  element={<EventActorsPage />}
                />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_SUBMISSIONS}
                  element={<EventSubmissionsPage />}
                />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_FEE}
                  element={<EventFeePage />}
                />
                <Route
                  path={APP_ROUTES.EVENTS_BY_ID_SCHEDULE}
                  element={<EventSchedulePage />}
                />

                <Route path={APP_ROUTES.TRANSFERS} element={<Transfers />} />
                <Route path={APP_ROUTES.TOKENS} element={<Tokens />} />
                <Route path={APP_ROUTES.TAGS} element={<Tags />} />
                <Route path={APP_ROUTES.CHAT} element={<ChatSettings />} />
                <Route path={APP_ROUTES.DEALS}>
                  <Route index element={<DealsPage />} />
                  <Route
                    path={'create'}
                    element={<EditDealPage isEdit={false} />}
                  />
                  <Route path={DEAL_ID} element={<EditDealPage isEdit />} />
                </Route>
                <Route path={APP_ROUTES.METADATA}>
                  <Route index element={<PitchTalkMetaDataPage />} />
                  <Route
                    path={APP_ROUTES.PARTNERS}
                    element={<PartnersListPage />}
                  />
                  <Route
                    path={APP_ROUTES.PARTNERS_NEW}
                    element={<PartnerPage />}
                  />
                  <Route
                    path={APP_ROUTES.PARTNERS_BY_ID}
                    element={<PartnerPage />}
                  />
                </Route>
                <Route
                  path={APP_ROUTES.DEFAULT}
                  element={<div>404 Page Not Found</div>}
                />
              </>
            ) : (
              <Route
                path={APP_ROUTES.DEFAULT}
                element={
                  hasAccess && !adminServerData ? (
                    <Typography
                      color="error"
                      sx={{ textAlign: 'center', fontSize: '2rem', m: 3 }}
                    >
                      No Access to the server, please login
                    </Typography>
                  ) : (
                    <Welcome />
                  )
                }
              />
            )}
          </Routes>
        </Router>
      </Suspense>
      <ToastContainer />
    </>
  );
};
