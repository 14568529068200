import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { useParams } from 'services/router';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import { EMPTY_STRING } from 'shared/constants';
import { isEventVoteEnded as checkIsEventVoteEnded } from 'shared/utils/eventsUtils';
import {
  selectEventById,
  selectEventHackathonPrizes,
  selectEventHackathonTasks,
} from 'store/selectors/events';
import { getEventHackathon } from 'store/thunks/events';

import { Prizes } from './components/Prizes';
import { Tasks } from './components/Tasks';
import { EventEndedNotification } from '../components/EventEndedNotification';

export const HackathonSettings: React.FC = () => {
  const { eventId = '' } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const event = useAppSelector((state) => selectEventById(state, eventId));

  const tasks = useAppSelector((state) =>
    selectEventHackathonTasks(state, eventId)
  );
  const prizes = useAppSelector((state) =>
    selectEventHackathonPrizes(state, eventId)
  );

  useEffect(() => {
    if (!event?.id) return;
    setIsLoading(true);
    dispatch(
      getEventHackathon({
        // eventId: event.event_id,
        offChainEventId: event.id,
      })
    ).finally(() => setIsLoading(false));
  }, [dispatch, event, event?.id, event?.serverEvent?.id]);

  const isEventVoteEnded = checkIsEventVoteEnded(event as any);

  return (
    <Box>
      {/* <EventEndedNotification /> */}
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          width: '1075px',
          position: 'relative',
        }}
      >
        {isLoading && <LoaderWithOverlay />}
        <Prizes
          // eventId={event?.id || EMPTY_STRING}
          offChainEventId={event?.id || EMPTY_STRING}
          prizes={prizes}
          isEventVoteEnded={isEventVoteEnded}
          setIsLoading={setIsLoading}
        />
        <Tasks
          // eventId={event?.id || EMPTY_STRING}
          offChainEventId={event?.id || EMPTY_STRING}
          tasks={tasks}
          isEventVoteEnded={isEventVoteEnded}
          setIsLoading={setIsLoading}
        />
      </Box>
    </Box>
  );
};
