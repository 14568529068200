import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { IPartner } from '@pitchtalk/contract-api-js/dist/interfaces';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'services/hooks/redux-hooks';
import { DEBOUNCE_300 } from 'shared/constants';
import { ESearchBarValues } from 'shared/interfaces';
import { EModals } from 'shared/interfaces/modals';
import { useModalContext } from 'shared/providers/ModalProvider';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';
import { selectPartners, selectMetaDataIsLoading } from 'store/slices/metaData';
import {
  getPartners,
  removePartner as removePartnerThunk,
} from 'store/thunks/metaData';

import { PartnersTable } from './components/PartnersTable';

// TODO: Move searchBar to hook or component
const searchTypes = [
  { value: ESearchBarValues.NAME, label: 'By partner name' },
  { value: ESearchBarValues.ALL, label: 'All partners' },
];

export const PartnersList: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const partners = useAppSelector(selectPartners);
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState<{
    value: string;
    type: ESearchBarValues;
  }>({ value: '', type: ESearchBarValues.ALL });
  const [searchedPartners, setSearchedPartners] = useState<IPartner[]>([]);

  useEffect(() => {
    dispatch(getPartners());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchedPartners([...partners].reverse());
  }, [partners]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findPartner = useCallback(
    debounce(async (searchValue: string) => {
      if (search.type === ESearchBarValues.NAME) {
        setSearchedPartners(
          [
            ...partners.filter((partner) =>
              partner.name
                ? partner.name.toLowerCase().includes(searchValue.toLowerCase())
                : false
            ),
          ].reverse()
        );
      } else {
        setSearchedPartners([...partners].reverse());
      }
    }, DEBOUNCE_300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.type]
  );

  useEffect(() => {
    setSearch((prev) => ({ ...prev, value: '' }));
    setSearchedPartners([...partners].reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.type]);

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch((prev) => ({ ...prev, value: event.target.value }));
    findPartner(event.target.value);
  };

  const onSearchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch((prev) => ({
      ...prev,
      type: event.target.value as ESearchBarValues,
    }));
  };

  const removePartner = useCallback(
    (partner: IPartner) =>
      showModal(EModals.INFO_MODAL, {
        info: (
          <Typography sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
            Are you sure to remove next partner?
          </Typography>
        ),
        subInfo: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginInline: 'auto',
              mt: 2,
            }}
          >
            <Box>{partner.name}</Box>
            <Box>
              <img
                src={getCorrectIPFSLinks({ image: partner.image }).image}
                style={{ width: '75px', height: '75px' }}
              />
            </Box>
          </Box>
        ),
        submitAction: () =>
          dispatch(removePartnerThunk(partner?.id || '')).then(() =>
            closeModal()
          ),
        loadingSelector: selectMetaDataIsLoading,
        cancelAction: () => void {},
        closeDelay: 0,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
      component="div"
    >
      <FormControl>
        <Typography>Search partner</Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <RadioGroup
            defaultValue={searchTypes[1].value}
            onChange={onSearchTypeChange}
          >
            {searchTypes.map((option, ind) => (
              <FormControlLabel
                key={ind}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {search.type !== ESearchBarValues.ALL && (
            <TextField
              sx={{ '& .MuiOutlinedInput-input': { padding: '10px' } }}
              value={search.value}
              onChange={onSearchValueChange}
            />
          )}
        </Box>
      </FormControl>
      {partners.length ? (
        searchedPartners.length ? (
          <PartnersTable
            partners={searchedPartners}
            removePartner={removePartner}
          />
        ) : (
          <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h5">
            Cannot find partner
          </Typography>
        )
      ) : (
        <Typography>There are no partners</Typography>
      )}
    </Box>
  );
};
