import { keyStores } from '@pitchtalk/contract-api-js';

import { IConfig } from './config';
import development from './env-defaults/development';
import mainnet from './env-defaults/mainnet';
import testnet from './env-defaults/testnet';

export enum EEnvironment {
  TESTNET = 'testnet',
  MAINNET = 'mainnet',
  DEVELOPMENT = 'development',
}

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.MAINNET]: mainnet,
  [EEnvironment.TESTNET]: testnet,
  [EEnvironment.DEVELOPMENT]: development,
};

export const currentEnvironment: EEnvironment =
  (process.env.REACT_APP_NEAR_ENV as EEnvironment) || EEnvironment.DEVELOPMENT;

export const {
  networkId,
  keyStore,
  nodeUrl,
  walletUrl,
  helperUrl,
  explorerUrl,
  contract,
  subServiceContract,
  pitchTalkSocialLinks,
  pitchTalkAppUrl,
  wrapNearId,
  myNearWalletUrl,
  jumboApiUrl,
  ipfsToken,
  ipfsHostEnd,
  ipfsHostStart,
  serverUrl,
}: IConfig = {
  ...environments[currentEnvironment],
  pitchTalkSocialLinks: {
    twitter: process.env.REACT_APP_TWITTER_URL || '',
    medium: process.env.REACT_APP_MEDIUM_URL || '',
    telegram: process.env.REACT_APP_TELEGRAM_URL || '',
    discord: process.env.REACT_APP_DISCORD_URL || '',
  },
  pitchTalkAppUrl:
    process.env.REACT_APP_PITCHTALK_APP_URL || 'pitchtalk.stage.hapi.farm',
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
  myNearWalletUrl:
    environments[currentEnvironment] === environments.mainnet
      ? 'https://app.mynearwallet.com/'
      : 'https://testnet.mynearwallet.com/',
  ipfsToken: process.env.REACT_APP_IPFS_TOKEN || '',
};
