import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import { FormikProps } from 'formik';
import { EEventAccess } from 'shared/interfaces/backendInterfaces';
import { SELECT_MENU_PROPS } from 'shared/utils/themeUtils';

import { EventSettings } from '../helpers';

interface IEventAccessTypeProps {
  formik: FormikProps<EventSettings>;
  isReadOnly: boolean;
}

const EVENT_ACCESS_TYPES = [
  { type: EEventAccess.PUBLIC, label: 'Public event' },
  { type: EEventAccess.PRIVATE, label: 'Private event', disabled: true },
];

export const EventAccessType: React.FC<IEventAccessTypeProps> = ({
  formik,
  isReadOnly,
}) => (
  <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
    <InputLabel id="event-access-type-label">Event access type</InputLabel>
    <Select
      labelId="event-access-type-label"
      id="event-access-type-label-select"
      value={
        formik.values.is_public ? EEventAccess.PUBLIC : EEventAccess.PRIVATE
      }
      onChange={(e) =>
        formik.setFieldValue(
          'is_public',
          e.target.value === EEventAccess.PUBLIC
        )
      }
      input={<OutlinedInput label="Event access type" />}
      MenuProps={SELECT_MENU_PROPS}
      readOnly={isReadOnly}
    >
      {EVENT_ACCESS_TYPES.map(({ type, label, disabled }, i) => (
        <MenuItem key={type + label + i} value={type} disabled={disabled}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
