import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Loader } from 'shared/components/Loader/Loader';
import { TablePaginationActions } from 'shared/components/TablePaginationActions';
import { IEvent } from 'shared/interfaces/backendInterfaces';

import { EventsEmpty } from './EventsEmpty';
import { EventsTableBody } from './EventsTableBody';
import { EventsTableHead } from './EventsTableHead';

const EVENTS_TABLE_COLUMNS = [
  'Id',
  'Access Type',
  'Name',
  'Start Date',
  'End Date',
  'Vote end Date',
  'Payment Type',
  'Tags',
  'Max participants',
];

interface IEventsTableProps {
  count: number;
  events: IEvent[];
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  rows: number;
  setRows: (rows: number) => void;
}

export const EventsTable: React.FC<IEventsTableProps> = ({
  count,
  events,
  isLoading,
  page,
  setPage,
  rows,
  setRows,
}) => {
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setRows(parseInt(event.target.value, 10));

  const eventsTable = (
    <>
      <EventsEmpty />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <EventsTableHead columns={EVENTS_TABLE_COLUMNS} />
          </TableHead>
          <TableBody>
            <EventsTableBody
              columnsCount={EVENTS_TABLE_COLUMNS.length}
              events={events}
              isLoading={isLoading}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rows}
                colSpan={EVENTS_TABLE_COLUMNS.length}
                count={count}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );

  {
    /* <EventsBySearchEmpty table={eventsTable} />; */
  }

  return eventsTable;
};
