import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, SxProps, Theme, Box } from '@mui/material';
import { memo } from 'react';
import { theme } from 'shared/components/theme';

const descriptionItemStyles: SxProps<Theme> = {
  borderRadius: '5px',
  position: 'relative',
  padding: '5px',
  width: '376px',
  background: 'black',
};

interface IEventDescriptionItemProps {
  id: string;
  title: string;
  text: string;
  isEditing?: boolean;
  isDisabled?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const EventDescriptionItem: React.FC<IEventDescriptionItemProps> = memo(
  ({
    id,
    title,
    text,
    isEditing = false,
    onEdit,
    onDelete,
    isDisabled = false,
  }) => (
    <Box
      sx={{
        ...descriptionItemStyles,
        border:
          isEditing && !isDisabled
            ? `2px solid ${theme.palette.success.main}`
            : '2px solid black',
      }}
    >
      <p
        style={{
          margin: '0px 0px 8px 0px',
          fontSize: '1.5rem',
          textTransform: 'uppercase',
          color: '#FFFFFF',
          maxWidth: '90%',
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        style={{ color: '#C4C4C4' }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {!isDisabled && (
        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'black',
          }}
        >
          <IconButton
            sx={{ width: '13px', height: '13px', color: 'white', mr: 1, ml: 1 }}
            onClick={() => onEdit(id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ width: '15px', height: '15px', color: 'white' }}
            onClick={() => onDelete(id)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
);
