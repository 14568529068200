import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { SxProps } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { PrimaryButton } from '../DefaultButtons/PrimaryButton';
import { theme } from '../theme';

export type SettingsInputProps = {
  inputLabel: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  boxStyles?: SxProps;
  inputStyles?: SxProps;
  isHighlighted?: boolean;
  uploadButton?: IImageUpload;
  fieldInputProps?: InputBaseComponentProps;
} & TextFieldProps;

const highlightedSx = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.success.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.success.main,
    },
  },
};

interface IImageUpload {
  showButton?: boolean;
  onClick?: () => void;
  disableButton?: boolean;
}

export const SettingsInput: React.FC<SettingsInputProps> = ({
  inputLabel,
  inputStyles,
  boxStyles,
  isHighlighted,
  uploadButton,
  fieldInputProps,
  ...props
}): JSX.Element => {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: 0,
          ...boxStyles,
        }}
        control={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <TextField
              {...props}
              sx={{
                ...inputStyles,
                ...(isHighlighted ? highlightedSx : {}),
                ...(uploadButton?.showButton
                  ? {
                      width: '60% !important',
                      marginRight: '4px',
                    }
                  : {}),
              }}
              inputProps={fieldInputProps}
            />
            {uploadButton?.showButton && (
              <PrimaryButton
                sx={{
                  width: 'fit-content',
                  fontSize: '12px',
                  height: '30px',
                }}
                disabled={uploadButton.disableButton}
                onClick={uploadButton?.onClick}
              >
                Upload
              </PrimaryButton>
            )}
          </div>
        }
        label={inputLabel}
        labelPlacement="start"
      />
    </FormGroup>
  );
};
