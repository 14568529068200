import { useState } from 'react';
import { PaginationInfo, fetcherPagination } from 'services/api/utils';
import useSWR from 'swr';

export function usePaginatedApi<Data>(config: {
  baseUrl: string;
  search?: Record<string, string | number | boolean>;
}) {
  const [page, setPage] = useState(1);

  const searchParams = new URLSearchParams({ page: page.toString() });

  if (config.search) {
    Object.keys(config.search).forEach((key) => {
      const value = config.search?.[key];
      if (value) searchParams.append(key, value.toString());
    });
  }

  const requestUrl = `${config.baseUrl}?${searchParams.toString()}`;

  const { data, error, mutate } = useSWR<{
    data: Data;
    pagination: PaginationInfo;
  }>(requestUrl, fetcherPagination);

  return {
    data: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    page,
    setPage,
    mutate,
  };
}
