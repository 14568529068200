import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { theme } from 'shared/components/theme';
import { useSWRConfig } from 'swr';

import { acceptEventPublicRequest, rejectEventPublicRequest } from '../api';

interface IEventPrivateRequestProps {
  eventId: string;
}

export const EventPrivateRequest: React.FC<IEventPrivateRequestProps> = ({
  eventId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  const onAcceptRequest = async () => {
    setIsLoading(true);
    await acceptEventPublicRequest(eventId).finally(() => {
      setIsLoading(false);
      mutate(`event/${eventId}`);
    });
  };

  const onRejectRequest = async () => {
    setIsLoading(true);
    await rejectEventPublicRequest(eventId).finally(() => {
      setIsLoading(false);
      mutate(`event/${eventId}`);
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '10px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '1.25rem',
          marginBottom: '10px',
        }}
      >
        Event author requested to publicize event
      </Typography>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <PrimaryButton
          onClick={onAcceptRequest}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
          }}
          disabled={isLoading}
        >
          Accept
        </PrimaryButton>
        <PrimaryButton
          onClick={onRejectRequest}
          sx={{
            backgroundColor: theme.palette.error.main,
            '&:hover': { backgroundColor: theme.palette.error.dark },
          }}
          disabled={isLoading}
        >
          Reject
        </PrimaryButton>
      </Box>
    </Box>
  );
};
