import {
  Account,
  // IProject,
  ISubmissionFundModel,
  Metadata,
} from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IProject } from 'shared/interfaces/backendInterfaces';

export interface Submission {
  metadata: Metadata | null;
  projects: IProject[];
  authors: Account[];
  // submissionProject: IProject | null;
  funds: ISubmissionFundModel[];
}
const initialState: Submission = {
  metadata: {
    fee_token: '',
    total_fee_balance: '',
    join_fee: '',
    owner_id: '',
    version: '',
  },
  projects: [],
  authors: [],
  // submissionProject: null,
  funds: [],
};

const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setSubmissionMetadata: (
      state,
      { payload }: PayloadAction<Metadata | null>
    ) => {
      state.metadata = payload;
    },
    setSubmissionProjects: (
      state,
      { payload }: PayloadAction<IProject[]>
    ) => {
      state.projects = payload;
    },
    setSubmissionProjectsAuthors: (
      state,
      { payload }: PayloadAction<Account[]>
    ) => {
      state.authors = payload;
    },
    updateSubmissionProject: (
      state,
      {
        payload: { project },
      }: PayloadAction<{
        project: IProject;
      }>
    ) => {
      const i = state.projects.findIndex((p) => p.id === project.id);
      if (i === -1) {
        state.projects.push(project);
      } else {
        state.projects[i] = project;
      }
    },
    updateNewSubmissionProject: (
      state,
      { payload: project }: PayloadAction<IProject>
    ) => {
      const i = state.projects.findIndex(
        (p) => p.userId === project.userId
      );
      if (i === -1) {
        state.projects.push(project);
      } else {
        state.projects[i] = project;
      }
    },
    setSubmissionFunds: (
      state,
      action: PayloadAction<ISubmissionFundModel[]>
    ) => {
      state.funds = action.payload;
    },
  },
});

export const {
  setSubmissionMetadata,
  setSubmissionProjects,
  setSubmissionProjectsAuthors,
  updateSubmissionProject,
  updateNewSubmissionProject,
  setSubmissionFunds,
} = submissionSlice.actions;

export const selectSubmissionMetadata = (state: RootState) =>
  state.submission.metadata;
export const selectSubmissionProjects = (state: RootState) =>
  state.submission.projects;

// export const selectSubmissionProjectById = createSelector(
//   [
//     selectSubmissionProjects,
//     (
//       _: RootState,
//       { projectId, authorId }: { projectId: string; authorId: string }
//     ) => ({
//       projectId: projectId,
//       authorId: authorId,
//     }),
//   ],
//   (projects, { projectId, authorId }) => {
//     if (projectId) {
//       return projects.find((project) => project.id === projectId);
//     }
//     return projects.find((project) => project.author_id === authorId);
//   }
// );

export const selectSubmissionProjectsAuthors = (state: RootState) =>
  state.submission.authors;

export const selectSubmissionFunds = (state: RootState) =>
  state.submission.funds;

export const submissionReducer = submissionSlice.reducer;
