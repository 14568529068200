import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { IFund } from '@pitchtalk/contract-api-js/dist/core';
import {
  EActionStatus,
  ISubmissionFundModel,
} from '@pitchtalk/contract-api-js/dist/SubmissionService/types';

import { STATUS_COLORS } from './projectsUtils';

export const getFundStatusStyle = (status: EActionStatus): SxProps<Theme> =>
  ({
    [EActionStatus.Active]: {
      color: '#FFFFFF',
      background: STATUS_COLORS.Active,
    },
    [EActionStatus.New]: {
      color: '#FFFFFF',
      background: STATUS_COLORS.New,
    },
    [EActionStatus.Failed]: {
      color: '#FFFFFF',
      background: STATUS_COLORS.Failed,
    },
    [EActionStatus.Updated]: {
      color: '#FFFFFF',
      background: STATUS_COLORS.Updated,
    },
    [EActionStatus.Refund]: { color: '#000000' },
    [EActionStatus.Removed]: { color: '#000000' },
    [EActionStatus.Resetting]: { color: '#000000' },
  }[status]);

export const getFundParticipant = (
  funds: ISubmissionFundModel[] | IFund[],
  accountId: string
): ISubmissionFundModel | IFund | undefined => {
  let account;
  funds.some((fund) => {
    if (fund.account_id === accountId) {
      account = fund;
      return true;
    }
    if (fund.participants.includes(accountId)) {
      account = fund;
      return true;
    }
    return false;
  });
  return account;
};
