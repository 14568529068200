import { SxProps } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {
  EContributionType,
  EUniqWithdrawType,
} from '@pitchtalk/contract-api-js/dist/core';
import { useFormik } from 'formik';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { onSubmitWithdraw } from 'services/api-contract-endpoints/API';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import { wrapNearId } from 'services/config';
import { useAppSelector } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsInput } from 'shared/components/SettingsInput';
import { DEFAULT_TOKEN_META } from 'shared/constants';
import { IWithdrawModal } from 'shared/interfaces/modals';
import {
  getAmountFormatted,
  getAmountFormattedWithDecimals,
} from 'shared/utils/near';
import {
  getAvailableWithdraw,
  getEventTokensAvailableWithdraw,
  getSubmissionTokensAvailableWithdraw,
  getVestingTokensAvailableWithdraw,
} from 'shared/utils/projectsUtils';
import { selectTokens } from 'store/slices/tokensWhiteList';
import * as yup from 'yup';

const style: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '52px 36px',
  boxShadow: 24,
  borderRadius: '10px',
};

const MODAL_HEADERS = {
  [EContributionType.DONATIONS]: 'Donations: ',
  [EContributionType.INVESTMENTS]: 'Investments: ',
  [EContributionType.GRANTS]: 'Grants: ',
  [EUniqWithdrawType.VESTING_TOKENS]: 'Non distributed tokens: ',
  [EUniqWithdrawType.SUBMISSION_FEE]: 'Submission fee tokens: ',
  [EUniqWithdrawType.EVENT_FEE]: 'Event fee tokens: ',
};

const initialValues = { walletId: '', amount: 0 };

export const WithdrawModal: React.FC<IWithdrawModal> = ({
  closeModal,
  withdrawType,
  project,
  vesting,
  event,
  grantTokenId,
  subMeta,
  eventMeta,
  isWithdrawAll,
}) => {
  const tokens = useAppSelector(selectTokens);
  const tokenId =
    {
      [EContributionType.DONATIONS]: vesting?.ft_token_id,
      [EContributionType.INVESTMENTS]: vesting?.ft_token_id,
      [EContributionType.GRANTS]: grantTokenId,
      [EUniqWithdrawType.VESTING_TOKENS]: project?.ft_token_id,
      [EUniqWithdrawType.SUBMISSION_FEE]: subMeta?.fee_token,
      [EUniqWithdrawType.EVENT_FEE]: eventMeta?.token_id,
    }[withdrawType] || wrapNearId;

  const meta = tokens[tokenId] || DEFAULT_TOKEN_META;

  const {
    setFieldValue,
    isSubmitting,
    isValid,
    values,
    errors,
    handleSubmit,
    setFieldError,
  } = useFormik<{
    walletId: string;
    amount: number;
  }>({
    initialValues,
    validationSchema: yup.object({
      walletId: yup
        .string()
        .min(3, '*Account id is too short')
        .required('*Account id is required'),
      amount: yup.number().min(0, '*Incorrect withdraw token amount').max(
        getAvailableWithdraw[withdrawType](tokens)({
          project,
          vesting,
          subMeta,
          grantTokenId,
        }),
        '*Incorrect withdraw token amount'
      ),
    }),
    onSubmit: (values) => {
      // onSubmitWithdraw(
      //   withdrawType,
      //   project?.project_id || 0,
      //   values.walletId,
      //   getAmountFormattedWithDecimals(values.amount, meta.decimals),
      //   tokenId,
      //   event?.id || 0
      // );
      closeModal();
    },
  });

  const checkIsValidAccount = useCallback(
    debounce(async (walletId: string) => {
      if (walletId.length) {
        const data = await PTService.getAccountData(walletId);
        setFieldError(
          'walletId',
          // eslint-disable-next-line quotes
          data ? undefined : "*This account doesn't exists"
        );
      }
    }, 1000),
    []
  );

  const onWalletIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const walletId = e.target.value;
    setFieldValue('walletId', walletId);
    checkIsValidAccount(walletId);
  };

  const withdrawInfo = useMemo(
    () => ({
      [EContributionType.DONATIONS]: getAmountFormatted(
        +(project?.total_donations ?? 0) -
          +(project?.total_withdrawn_donations ?? 0),
        meta.decimals
      ),
      [EContributionType.INVESTMENTS]: getAmountFormatted(
        +(project?.total_investments ?? 0) -
          +(project?.total_withdrawn_investments ?? 0),
        meta.decimals
      ),
      [EContributionType.GRANTS]: getAmountFormatted(
        +(project?.total_grants?.[grantTokenId || ''] ?? 0) -
          +(project?.total_withdrawn_grants?.[grantTokenId || ''] ?? 0),
        meta.decimals
      ),
      [EUniqWithdrawType.VESTING_TOKENS]: getVestingTokensAvailableWithdraw(
        tokens
      )({ project, vesting }),
      [EUniqWithdrawType.SUBMISSION_FEE]: getSubmissionTokensAvailableWithdraw(
        tokens
      )({ subMeta }),
      [EUniqWithdrawType.EVENT_FEE]: getEventTokensAvailableWithdraw(tokens)({
        eventMeta,
      }),
    }),
    [project, vesting, meta.decimals, tokens, subMeta, eventMeta]
  );

  return (
    <Modal
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Box sx={style}>
        <Typography
          sx={{
            fontFamily: 'Everett-Regular',
            fontSize: '1.625rem',
            textAlign: 'center',
          }}
        >
          Withdraw
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Everett-Regular',
            fontSize: '1.125rem',
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          <span>{MODAL_HEADERS[withdrawType]}</span>
          <span>{withdrawInfo[withdrawType]} </span>
          <span>{meta.symbol}</span>
        </Typography>
        <Box sx={{ marginBottom: '16px' }}>
          <SettingsInput
            inputLabel="Withdraw to:"
            value={values.walletId}
            onChange={onWalletIdChange}
            error={!!errors.walletId}
            helperText={errors?.walletId}
            boxStyles={{ marginBottom: '16px' }}
            inputStyles={{
              '.MuiOutlinedInput-input': {
                background: 'rgb(51, 51, 51)',
                color: 'white',
                padding: '10px 25px 10px 20px',
              },
            }}
          />
          {!isWithdrawAll && (
            <SettingsInput
              inputLabel="Amount:"
              value={values.amount}
              onChange={(e) =>
                !isNaN(+e.target.value) &&
                setFieldValue('amount', e.target.value)
              }
              error={!!errors.amount}
              helperText={errors?.amount}
              inputStyles={{
                '.MuiOutlinedInput-input': {
                  background: 'rgb(51, 51, 51)',
                  color: 'white',
                  padding: '10px 25px 10px 20px',
                },
              }}
            />
          )}
        </Box>
        <PrimaryButton
          sx={{ width: '100%', height: '2.6rem' }}
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={() => handleSubmit()}
        >
          Send
        </PrimaryButton>
      </Box>
    </Modal>
  );
};
