import { Project } from '@pitchtalk/contract-api-js/dist/core';
import {
  PitchArgsOnChain,
  Pitch,
  EPitchType,
} from '@pitchtalk/contract-api-js/dist/interfaces/pitch';
import { PitchSub } from '@pitchtalk/contract-api-js/dist/SubmissionService/types';
import msToSec from 'date-fns/millisecondsToSeconds';
import range from 'lodash/range';

import { DEFAULT_PAGINATION_REQUEST, EMPTY_STRING } from '../constants';

export enum EPitchSettingsKeys {
  NAME = 'name',
  SPEAKER_NAME = 'speaker_name',
  STARTS_ON = 'starts_on',
  DURATION = 'duration',
  VIDEO_URL = 'video_url',
}

export const getPaginationArray = (count: number) => {
  return range(Math.ceil(count / DEFAULT_PAGINATION_REQUEST)).map((_, i) => ({
    fromIndex: i * DEFAULT_PAGINATION_REQUEST,
    limit: DEFAULT_PAGINATION_REQUEST,
  }));
};

export const getPitches = (project: Project): Pitch[] =>
  [project.intro_pitch, project.update_pitch, project.investors_pitch].filter(
    (pitch) => pitch && Object.keys(pitch).length
  ) as Pitch[];

export const getPitchDataForSaving = (
  pitchData: Pitch | PitchSub
): PitchArgsOnChain => ({
  name: pitchData.name,
  speaker_name: pitchData.speaker_name,
  starts_on_sec: msToSec(pitchData.starts_on),
  duration_sec: pitchData.duration * 60,
  video_url: pitchData.video_url,
});

export const pitchEditSettings = [
  { label: 'Pitch name:', name: EPitchSettingsKeys.NAME },
  { label: 'Speakers name:', name: EPitchSettingsKeys.SPEAKER_NAME },
  {
    label: 'Start time:',
    name: EPitchSettingsKeys.STARTS_ON,
    type: 'datePicker',
  },
  { label: 'Duration in minutes:', name: EPitchSettingsKeys.DURATION },
  { label: 'Video URL:', name: EPitchSettingsKeys.VIDEO_URL },
];

export const defaultEditPitch = {
  name: '',
  speaker_name: '',
  starts_on: Date.now(),
  duration: 0,
  video_url: '',
  is_active: false,
  stage: EPitchType.Intro,
  new: true,
};

enum PitchStagesName {
  Intro = '1.Intro:',
  Update = '2.Update:',
  Invest = '3.Investors:',
}

export const PITCH_STAGES = {
  [EPitchType.Intro]: PitchStagesName.Intro,
  [EPitchType.Update]: PitchStagesName.Update,
  [EPitchType.Investment]: PitchStagesName.Invest,
  [EPitchType.Initial]: EMPTY_STRING,
};

export const isStageInvestmentAvailable = (
  minStage: EPitchType,
  stage?: EPitchType,
  pitch?: Pitch
) => {
  const now = Date.now();

  if (!stage || stage === EPitchType.Initial || !pitch) return false;
  if (stage === EPitchType.Update && minStage === EPitchType.Investment)
    return false;

  return pitch.starts_on < now;
};

export const isInvestmentsAvailable = (project?: Project) => {
  return {
    donationsAvailable:
      project &&
      isStageInvestmentAvailable(
        EPitchType.Intro,
        project.stage,
        project.intro_pitch
      ),
    investmentsAvailable:
      project &&
      isStageInvestmentAvailable(
        EPitchType.Investment,
        project.stage,
        project.investors_pitch
      ),
  };
};

export const isPitchStarted = (pitch?: Pitch) =>
  pitch ? !!(Date.now() > pitch.starts_on) : false;

export enum EPitchKey {
  INTRO = 'intro_pitch',
  UPDATE = 'update_pitch',
  INVEST = 'investors_pitch',
}

export const findPitchByKey = (key: EPitchType) => (pitch: Pitch | PitchSub) =>
  pitch.stage === key;
