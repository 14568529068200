/* eslint-disable no-console */
import { IOffChainProject, Project } from '@pitchtalk/contract-api-js';
import { compose } from '@reduxjs/toolkit';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import * as projectsAPI from 'services/api-endpoints/projectsAPI';
import { DEFAULT_PAGINATION_REQUEST, PromiseStatus } from 'shared/constants';
import { IProject } from 'shared/interfaces/backendInterfaces';
import { getPaginationArray } from 'shared/utils/pitchUtils';
import { setOffChainProjects } from 'store/slices/projects';
import { AppDispatch } from 'store/store';

export const getOnChainProjects = () => (dispatch: AppDispatch) =>
  {
    return console.log('getOnChainProjects')
    // return PTService.getProjectsCount()
    //   .then((count) => count || 0)
    //   .then(getPaginationArray)
    //   .then((paginationArray) => Promise.allSettled(paginationArray.map(PTService.getProjects)).then(
    //     (projectsResponse) => projectsResponse.reduce(
    //       (arr, project) => project.status === PromiseStatus.FULFILLED && project.value
    //         ? [...arr, ...project.value]
    //         : arr,
    //       [] as Project[]
    //     )
    //   )
    //   )
    //   .then(compose(dispatch, setProjects))
    //   .catch(console.error);
  };

export const getOffChainProjects = () => (dispatch: AppDispatch) =>
  projectsAPI
    .fetchProjectsCount()
    .then((amount) => {
      const paginationArray = getPaginationArray(amount);

      return Promise.all(paginationArray.map((_, i) => projectsAPI
        .fetchProjects(DEFAULT_PAGINATION_REQUEST, i + 1)))
    }
    )
    .then((responses: IProject[][]) => responses.flat())
    .then(compose(dispatch, setOffChainProjects))
    .catch(console.error);


export const approveOffChainProjectSubmission =
  (projectId: string, comment?: string) => (dispatch: AppDispatch) =>
    projectsAPI
      .fetchApproveProjectSubmission(projectId, comment)
      .then(() => dispatch(getOffChainProjects()))
      .catch(console.error);

export const rejectOffChainProjectSubmission =
  (projectId: string, comment?: string) => (dispatch: AppDispatch) =>
    projectsAPI
      .fetchRejectProjectSubmission(projectId, comment)
      .then(() => dispatch(getOffChainProjects()))
      .catch(console.error);
