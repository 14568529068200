import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

const tokensWhiteListSlice = createSlice({
  name: 'tokensWhiteList',
  initialState: {} as { [key: string]: ITokenMetadata },
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{ [tokenId: string]: ITokenMetadata }>
    ) => action.payload,
  },
});

export const { setTokens } = tokensWhiteListSlice.actions;
export const selectTokens = (state: RootState) => state.tokensWhiteList;

export const tokensWhiteListReducer = tokensWhiteListSlice.reducer;
