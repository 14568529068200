export const PROJECT_ID = ':projectId';
export const TAG_ID = ':tagId';
export const PARTNER_ID = ':partnerId';
export const REFEREE_ID = ':refereeId';
export const FUND_ID = ':fundId';
export const EVENT_ID = ':eventId';
export const PARTICIPATION_ID = ':participationId';
export const DEAL_ID = ':dealId';
export const INVESTMENT_ID = ':investmentId';
export const PROJECT_NEW = 'new';

export const APP_ROUTES = {
  WELCOME: '/',
  PROJECTS: '/projects',
  MANAGEMENT: '/management',
  SUBMISSION_MANAGEMENT: '/submission-management',
  INSTITUTIONALS: '/management/institutional',
  SUBMISSION_FEE: '/management/fees',
  SUBMISSIONS: '/submission-management',
  SUBMISSIONS_FUNDS: '/submission-management/funds',
  SUBMISSION_INSTITUTIONALS: '/submission-management/institutional',
  SUB_PROJECTS_BY_ID: `/submission-management/submissions/sub-projects/${PROJECT_ID}`,
  SUB_PROJECTS_BY_ID_PITCHES: `/submission-management/submissions/sub-projects/${PROJECT_ID}/pitches`,
  SUB_PROJECTS_BY_ID_FINANCIAL: `/submission-management/submissions/sub-projects/${PROJECT_ID}/financial`,
  SUB_PROJECTS_BY_ID_LOGS: `/submission-management/submissions/sub-projects/${PROJECT_ID}/logs`,
  SUB_PROJECTS_BY_ID_WITHDRAW: `/submission-management/submissions/sub-projects/${PROJECT_ID}/withdraw`,
  SUB_PROJECTS_BY_ID_TEAM: `/submission-management/submissions/sub-projects/${PROJECT_ID}/team`,
  PROJECT_NEW: `/projects/new/${PROJECT_ID}`,
  PROJECT_BY_ID: `/projects/${PROJECT_ID}`,
  PROJECT_BY_ID_PITCHES: `/projects/${PROJECT_ID}/pitches`,
  PROJECT_BY_ID_FINANCIAL: `/projects/${PROJECT_ID}/financial`,
  FUND_BY_ID: `/management/institutional/funds/${FUND_ID}`,
  SUB_FUND_BY_ID: `/submission-management/institutional/funds/${FUND_ID}`,
  PROJECT_BY_ID_TEAM: `/projects/${PROJECT_ID}/team`,
  TRANSFERS: '/transfers',
  TOKENS: '/tokens',
  EVENTS_USERS: '/users-events',
  EVENTS: '/events',
  EVENTS_NEW: '/events/new',
  EVENTS_BY_ID: `/events/${EVENT_ID}`,
  EVENTS_BY_ID_SERVER: `/events/${EVENT_ID}/server`,
  EVENTS_BY_ID_SERVER_HACKATHON: `/events/${EVENT_ID}/hackathon`,
  EVENTS_BY_ID_ACTORS: `/events/${EVENT_ID}/actors`,
  EVENTS_BY_ID_FEE: `/events/${EVENT_ID}/fee`,
  EVENTS_BY_ID_SCHEDULE: `/events/${EVENT_ID}/schedule`,
  EVENTS_BY_ID_SUBMISSIONS: `/events/${EVENT_ID}/submissions`,
  TAGS: '/tags',
  METADATA: '/metadata',
  PARTNERS: '/metadata/partners',
  PARTNERS_NEW: '/metadata/partners/new',
  PARTNERS_BY_ID: `/metadata/partners/${PARTNER_ID}`,
  CHAT: '/chat',
  DEALS: '/deals',
  DEALS_CREATE: 'deals/create',
  DEALS_EDIT: `deals/${DEAL_ID}`,
  INVESTMENTS: '/investments',
  INVESTMENTS_BY_ID: `/investments/${INVESTMENT_ID}`,
  DEFAULT: '*',
};
