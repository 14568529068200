import {
  EEventSubmissionStatus,
  IEventScheduleCheckpoint,
} from '@pitchtalk/contract-api-js';
import { createSelector } from '@reduxjs/toolkit';
import { millisecondsToMinutes } from 'date-fns';
import { IEventDescription } from 'pages/EventsPage/EventServer/types';
import { sortEventSubmissionsByStatus } from 'shared/utils/eventsUtils';
import { isEventVoteEnded as checkIsEventVoteEnded } from 'shared/utils/eventsUtils';
import {
  IEventProjectParticipation,
  IRefereeParticipation,
} from 'store/interfaces/events';
import {
  IEventCombined,
  IEventData,
  IEventSubmissions,
} from 'store/slices/events';
import { RootState } from 'store/store';

export const selectEvents = createSelector(
  (state: RootState) => state.events.events.offChainData,
  (events): IEventCombined[] => Object.values(events)
);

export const selectEventsObject = (state: RootState) =>
  state.events.events.offChainData;

export const selectSubmissions = (state: RootState) => state.events.submissions;

export const selectEventById = createSelector(
  selectEventsObject,
  (_: RootState, eventId: string) => eventId,
  (events, eventId) => events?.[eventId]
);

export const selectEventParticipants = createSelector(
  selectEventById,
  (event) => ({
    projects: event?.projects || {},
    judges: event?.judges || {},
    maxProjectsLimit: event?.max_project_count,
    votesLimit: event?.votes_limit,
    // isEventVoteEnded: checkIsEventVoteEnded(event),
  })
);

export const selectEventSubmissions = createSelector(
  selectSubmissions,
  (
    _: RootState,
    {
      eventId,
      subStatus,
    }: { eventId: string; subStatus?: EEventSubmissionStatus }
  ) => ({ eventId, subStatus }),
  (submissions: Record<string, IEventSubmissions>, { eventId, subStatus }) => {
    const eventSubmissions = submissions?.[eventId] || {};

    const sortFilter: <T>(arr: T[]) => T[] = (arr: any[]) => {
      if (subStatus)
        return arr.filter(
          (item) => item.status === EEventSubmissionStatus.APPROVED
        );
      else return arr.sort(sortEventSubmissionsByStatus);
    };

    return {
      projectsSubmissions: sortFilter([
        ...(eventSubmissions?.projects || []),
      ]) as IEventProjectParticipation[],
      refereeSubmissions: sortFilter([
        ...(eventSubmissions?.referee || []),
      ]) as IRefereeParticipation[],
    };
  }
);

export type IEventScheduleCheckpointForm = Omit<
  IEventScheduleCheckpoint,
  'start_date'
> & { start_date: number };

export const selectEventSchedule = createSelector(
  (state: RootState) => state.events.schedules,
  (_: RootState, eventId: string) => eventId,
  (schedules, eventId): Record<string, IEventScheduleCheckpointForm> => {
    if (!event) return {};
    try {
      const schedule: IEventScheduleCheckpoint[] = schedules[eventId];
      return schedule.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            ...item,
            start_date: new Date(item.start_date),
            duration: millisecondsToMinutes(item.duration),
          },
        }),
        {}
      );
    } catch (error) {
      return {};
    }
  }
);

export const selectEventHackathonPrizes = createSelector(
  (state: RootState) => state.events.hackathons,
  (_: RootState, eventId: string) => eventId,
  (hackathons, eventId) => hackathons?.[eventId]?.prize_details
);

export const selectEventHackathonTasks = createSelector(
  (state: RootState) => state.events.hackathons,
  (_: RootState, eventId: string) => eventId,
  (hackathons, eventId) => hackathons?.[eventId]?.tasks
);

export const selectEventDescription = createSelector(
  selectEventById,
  (event): Record<string, IEventDescription> => {
    if (!event) return {};
    try {
      const description: Record<string, IEventDescription> = JSON.parse(
        event?.description || ''
      );
      return description;
    } catch (error) {
      return {};
    }
  }
);

export const selectEventPartners = createSelector(
  selectEventById,
  (event): string[] => {
    if (!event) return [];
    try {
      const partners: string[] = JSON.parse(event?.partners || '');
      return partners;
    } catch (error) {
      return [];
    }
  }
);

export const selectEventsTableData = (state: RootState) =>
  state.events.events.table.data;

export const selectEventsTablePage = (state: RootState) =>
  state.events.events.table.page;

export const selectEventsTableRowsPerPage = (state: RootState) =>
  state.events.events.table.rowsPerPage;

export const selectEventsTableCount = (state: RootState) =>
  state.events.events.table.count;

export const selectEventsTableLoading = (state: RootState) =>
  state.events.events.table.isLoading;
