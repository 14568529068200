import {
  EProjectType,
  IEventScheduleCheckpoint,
  IHackakathonPrize,
  IHackakathonTask,
  IServerEvent,
} from '@pitchtalk/contract-api-js/dist/interfaces';
import { IServerEventArgs } from 'pages/EventsPage/EventServer/types';
import { IHackakathonTaskState } from 'pages/EventsPage/HackathonSettings/components/Tasks';
import { EVENT_ID, REFEREE_ID, PARTICIPATION_ID } from 'routes/routes';
import {
  parseData,
  parseErrorData,
  parseSuccessData,
} from 'services/api/utils';
import { IEventCombined } from 'store/slices/events';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export interface IEventHackathon {
  prize_details: IHackakathonPrize[];
  tasks: IHackakathonTask[];
}

export const fetchPendingEventRefereeSubmission = (refereeId: string) =>
  fetchAPI
    .patch(API_ROUTES.pendingReferee.replace(REFEREE_ID, refereeId))
    .then(parseData);

export const pendingEventParticipation = (submissionId: string) =>
  fetchAPI
    .post(
      API_ROUTES.pendingEventParticipation.replace(
        PARTICIPATION_ID,
        submissionId
      )
    )
    .then(parseData);

export const fetchRejectEventRefereeSubmission = (refereeId: string) =>
  fetchAPI
    .patch(API_ROUTES.rejectReferee.replace(REFEREE_ID, refereeId))
    .then(parseData);

export const rejectEventParticipation = (submissionId: string) =>
  fetchAPI
    .post(
      API_ROUTES.rejectEventParticipation.replace(
        PARTICIPATION_ID,
        submissionId
      )
    )
    .then(parseData);

export const approveRefereeSubmission = (refereeId: string) =>
  fetchAPI
    .patch(API_ROUTES.approveReferee.replace(REFEREE_ID, refereeId))
    .then(parseData);

export const approveEventParticipation = (submissionId: string) =>
  fetchAPI
    .post(
      API_ROUTES.approveEventParticipation.replace(
        PARTICIPATION_ID,
        submissionId
      )
    )
    .then(parseData);

export const fetchAddEventParticipation = (
  event_id: string,
  project_id: string | number,
  project_type: EProjectType
) =>
  fetchAPI
    .post(API_ROUTES.addParticipation, { event_id, project_id, project_type })
    .then(parseData);

export const fetchAddEventReferee = (event_id: string, account_id: string) =>
  fetchAPI
    .post(API_ROUTES.addReferee, { event_id, account_id })
    .then(parseData);

export const fetchCreateEvent = (event: IServerEventArgs) =>
  fetchAPI.post(`${API_ROUTES.event}`, { ...event }).then(parseData);

export const fetchUpdateEvent = (eventId: string, event: Partial<IServerEventArgs>) =>
  fetchAPI
    .patch(`${API_ROUTES.event}/${eventId}`, { ...event })
    .then(parseData);

export const updateEventPartners = (eventId: string, partners: string[]) =>
  fetchAPI
    .post(`${API_ROUTES.event}/${eventId}/partners`, { partners })
    .then(parseData);

export const updateEventIsActive = (eventId: string, isActive: boolean) =>
  fetchAPI
    .post(`${API_ROUTES.event}/${eventId}/active`, { is_active: isActive })
    .then(parseData);

export const fetchEventById = (eventId: string) =>
  fetchAPI.get<IEventCombined>(`${API_ROUTES.event}/${eventId}`).then(parseData);

export const fetchEvents = ({ page, limit }: { page: number, limit: number }) =>
  fetchAPI.get<IEventCombined[]>(`${API_ROUTES.event}?page=${page}&limit=${limit}}`).then(parseData);

export const fetchEventsCount = () =>
  fetchAPI.get<number>(`${API_ROUTES.event}/count`).then(parseData);

export const fetchEventByOnChainId = (eventId: number) =>
  fetchAPI
    .get<IEventCombined>(`${API_ROUTES.eventOnChain}/${eventId}`)
    .then(parseData);

export const fetchEventSchedule = (eventId: string) =>
  fetchAPI
    .get<IEventScheduleCheckpoint[]>(
      API_ROUTES.eventSchedule.replace(EVENT_ID, eventId)
    )
    .then(parseData);

export const fetchEventHackathon = (eventId: string) =>
  fetchAPI
    .get<IEventHackathon>(API_ROUTES.eventHackathon.replace(EVENT_ID, eventId))
    .then(parseData);

export const fetchSaveEventSchedule = (
  eventId: string,
  schedule: IEventScheduleCheckpoint[]
) =>
  fetchAPI
    .post(`${API_ROUTES.eventSchedule.replace(EVENT_ID, eventId)}`, schedule)
    .then(parseSuccessData('Event schedule has successfully saved'))
    .catch(parseErrorData('Event schedule has not saved'));

export const fetchEventRefereeSubmissions = (eventId: string) =>
  fetchAPI
    .get(API_ROUTES.eventReferee.replace(EVENT_ID, eventId.toString()))
    .then(parseData);

export const fetchEventProjectsSubmissions = (eventId: string) =>
  fetchAPI
    .get(
      API_ROUTES.eventSubmissionsProjects.replace(EVENT_ID, eventId.toString())
    )
    .then(parseData);

export const fetchHackathonTasks = (
  eventId: string,
  tasks: IHackakathonTaskState[]
) =>
  fetchAPI
    .patch(`${API_ROUTES.hackathonTasks.replace(EVENT_ID, eventId)}`, tasks)
    .then(parseSuccessData('Hackathon tasks has successfully saved'))
    .catch(parseErrorData('Hackathon tasks has not saved'));

export const fetchHackathonPrizeDetails = (
  eventId: string,
  prize_details: IHackakathonPrize[]
) =>
  fetchAPI
    .patch(
      `${API_ROUTES.hackathonPrize.replace(EVENT_ID, eventId)}`,
      prize_details
    )
    .then(parseSuccessData('Hackathon prizes has successfully saved'))
    .catch(parseErrorData('Hackathon prizes has not saved'));

export const checkIsEventsExists = (eventIDs: number[]) =>
  fetchAPI
    .get<Record<number, { isExist: false; isActive: false }>>(
      `${API_ROUTES.eventsHasEvents}`,
      { params: { ids: eventIDs.join(',') } }
    )
    .then(parseData);
