import { IConfig } from '../config';

export default {
  networkId: 'mainnet',
  nodeUrl: 'https://near-rpc.hapilabs.one',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://helper.mainnet.near.org',
  explorerUrl: 'https://explorer.mainnet.near.org',
  contract: 'v1.pitchtalk.near',
  subServiceContract: 'submission-service.pitchtalk.near',
  wrapNearId: 'wrap.near',
  jumboApiUrl: 'https://price-service.jumbo.exchange',
  serverUrl: 'https://api.pitchtalk.com',
  ipfsHostEnd: '',
  ipfsHostStart: 'https://ipfs.pitchtalk.com/ipfs/',
} as IConfig;
