import Big, { BigSource } from 'big.js';
import BN from 'bn.js';
import { utils } from 'near-api-js';

import { BASE, YOCTO_IN_NEAR_DECIMALS } from '../constants';

export const getAmountFormatted = (
  amount: BigSource,
  decimals: number = YOCTO_IN_NEAR_DECIMALS,
  toFixed = 1
) => {
  return new Big(amount)
    .div(BASE ** decimals)
    .toFixed(toFixed)
    .toString();
};

export const getAmountFormattedWithDecimals = (
  amount: BigSource,
  decimals: number = YOCTO_IN_NEAR_DECIMALS
) => {
  return new Big(amount)
    .mul(BASE ** decimals)
    .toFixed()
    .toString();
};

export const getFiatAmount = (
  amount: BigSource,
  price: BigSource,
  decimals?: number,
  toFixed = 1
) => {
  return new Big(amount)
    .mul(price)
    .div(decimals ? BASE ** decimals : 1)
    .toFixed(toFixed)
    .toString();
};

export const getGas = (gas?: string) =>
  gas ? new BN(gas) : new BN('100000000000000');

export const getNearAmount = (amount?: string) =>
  amount ? new BN(utils.format.parseNearAmount(amount) ?? 0) : new BN('0');

export const roundToLow = (num: number, precision: number) =>
  Math.floor(num * BASE ** precision) / BASE ** precision;
