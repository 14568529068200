import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type LoaderProps = {
  size?: string;
  height?: string;
};
export const Loader: React.FC<LoaderProps> = ({
  size = '3rem',
  height = '100vh',
}): JSX.Element => {
  return (
    <div
      style={{
        width: '100%',
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="primary" size={size} />
    </div>
  );
};

export const LoaderWithOverlay: React.FC<{ sx?: SxProps<Theme> }> = ({
  sx,
}) => (
  <Box
    sx={{
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: '#cccccc3b',
      zIndex: 999,
      ...sx,
    }}
  >
    <Loader height="100%" />
  </Box>
);
