import CheckIcon from '@mui/icons-material/Check';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Box,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { APP_ROUTES } from 'routes/routes';
import { API_ROUTES } from 'services/api/config';
import { pitchTalkAppUrl } from 'services/config';
import { usePaginatedApi } from 'services/hooks/useAPI';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';

import { acceptRemove, rejectRemove } from './api';
import { IInvestmentRound } from './types';

export const InvestmentsPage: React.FC = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    page,
    setPage,
    pagination,
    mutate,
  } = usePaginatedApi<IInvestmentRound[]>({
    baseUrl: API_ROUTES.getInvestment,
    search: { limit: rowsPerPage },
  });

  const handleChangePage = (_: unknown, newPage: number) =>
    setPage(newPage + 1);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onAcceptRemove = async (investmentId: string) => {
    await acceptRemove(investmentId);
    mutate();
  };

  const onRejectRemove = async (investmentId: string) => {
    await rejectRemove(investmentId);
    mutate();
  };

  if (isInvestmentsLoading) return <Typography>Loading...</Typography>;
  if (!investments) return <Typography>No investments found.</Typography>;

  return (
    <Box sx={{ margin: '20px' }} component="div">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table aria-label="Investment Rounds Table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Blockchain</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investments.map((investment) => (
                <TableRow key={investment.id}>
                  <TableCell>
                    {investment.id}
                    {investment.isAvailable && (
                      <>
                        <CheckIcon
                          data-tooltip-id={investment.id}
                          sx={{
                            width: 18,
                            height: 18,
                            color: 'green',
                          }}
                        />
                        <Tooltip id={investment.id}>
                          Project owner successfully created funding round,
                          sended tokens and activated it
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Link to={APP_ROUTES.PROJECTS + '/' + investment.projectId}>
                      Project in Admin Panel
                    </Link>
                    <br />
                    <Link
                      to={`http://${pitchTalkAppUrl}/projects/${investment.projectId}`}
                      target="_blank"
                    >
                      Project in Pitchtalk
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={APP_ROUTES.INVESTMENTS + '/' + investment.id}>
                      {investment.name}
                    </Link>
                  </TableCell>
                  <TableCell>{investment.description}</TableCell>
                  <TableCell>
                    {new Date(investment.startDate).toUTCString()}
                  </TableCell>
                  <TableCell>
                    {new Date(investment.endDate).toUTCString()}
                  </TableCell>
                  <TableCell>{investment.blockchain}</TableCell>
                  <TableCell>
                    {investment.requestToRemove ? (
                      <>
                        <PrimaryButton
                          sx={{ mb: 1 }}
                          onClick={() => onAcceptRemove(investment.id)}
                        >
                          Accept remove
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => onRejectRemove(investment.id)}
                        >
                          Reject remove
                        </PrimaryButton>
                      </>
                    ) : (
                      <h4>No Actions</h4>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pagination?.totalPages || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
