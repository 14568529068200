import { Project } from '@pitchtalk/contract-api-js';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IProject } from 'shared/interfaces/backendInterfaces';

export interface IUpdateProjectData {
  currentProject: IProject | null;
  projects: IProject[];
  offChainProjects: IProject[];
}

const initialState: IUpdateProjectData = {
  currentProject: null,
  projects: [] as IProject[],
  offChainProjects: [] as IProject[],
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // setProjects: (state, { payload }: PayloadAction<Project[]>) => {
    //   state.projects = payload;
    // },
    setOffChainProjects: (
      state,
      { payload }: PayloadAction<IProject[]>
    ) => {
      state.offChainProjects = payload;
    },
    updateProject: (
      state,
      { payload: { project } }: PayloadAction<{ project: IProject }>
    ) => {
      const i = state.projects.findIndex(
        (p) => p.id === project.id
      );
      if (i === -1) {
        state.projects.push(project);
      } else {
        state.projects[i] = project;
      }
    },
  },
});

// ON CHAIN PROJECTS
// TODO: Rename to selectOnChainProjects
export const selectProjects = (state: RootState) => state.projects.projects;

export const selectActiveProjects = (state: RootState) =>
  state.projects.projects.filter((project) => project.is_active);

export const selectProjectById = createSelector(
  [selectProjects, (_: RootState, projectId: string) => projectId],
  (projects: IProject[], projectId: string) =>
    projects.find((project) => project.id === projectId)
);

// OFF CHAIN PROJECTS
export const selectOffChainProjectsAllData = (state: RootState) =>
  state.projects.offChainProjects;

export const selectOffChainProjectById = createSelector(
  [selectOffChainProjectsAllData, (_: RootState, id: string) => id],
  (projects: IProject[], id: string) =>
    projects.find((project) => project.id === id)
);

// export const selectOffChainProjectsSubmissions = createSelector(
//   selectOffChainProjectsAllData,
//   (projects: IProject[]) => projects.filter((p) => p.is_submission)
// );

// export const selectOffChainProjectsOrigins = createSelector(
//   selectOffChainProjectsAllData,
//   (projects: IProject[]) => projects.filter((p) => !p.is_submission)
// );

export const { setOffChainProjects, updateProject } =
  projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
