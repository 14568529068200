/* eslint-disable no-console */
import Big from 'big.js';
import * as pricesAPI from 'services/api-endpoints/pricesAPI';
import { ZERO } from 'shared/constants';

import { setTokenPrices } from '../../slices/tokenPrices';
import { AppDispatch } from '../../store';

export const fetchTokenPrices = () => async (dispatch: AppDispatch) => {
  try {
    const prices = await pricesAPI.fetchTokenPricesFromJumbo();
    const mappedPrices = prices.reduce((acc, current) => {
      return {
        ...acc,
        [current?.id]: new Big(current?.price || ZERO),
      };
    }, {});
    dispatch(setTokenPrices(mappedPrices));
  } catch (error) {
    console.warn('Error during fetching tokens prices: ' + error);
    dispatch(setTokenPrices({}));
  }
};
