import { ITag } from '@pitchtalk/contract-api-js/dist/interfaces';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from 'shared/utils/reduxUtils';

import { selectProjects } from './projects';
import { selectEvents } from '../selectors/events';
import { RootState } from '../store';

export const SLICE_PREFIX = 'tags';

export interface ITagsData {
  tags: ITag[];
  isLoading: boolean;
}

const initialState: ITagsData = {
  tags: [] as ITag[],
  isLoading: false,
};

const tagsSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    setTags: (state, { payload }: PayloadAction<ITag[]>) => {
      state.tags = payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setTags } = tagsSlice.actions;

export const selectTags = (state: RootState) => state.tags.tags;

export const selectTagsIsLoading = (state: RootState) => state.tags.isLoading;

export const selectTagMeta = createSelector(
  (state: RootState) => state,
  (_: RootState, id: string) => id,
  (state, id) => {
    const tags = selectTags(state);
    const tag = tags.find((tag) => tag.id === id);
    if (!tag) return { tag: {}, projects: [], events: [] };

    const onChainProjects = selectProjects(state);
    // const offChainProjects = selectOffChainProjectsOrigins(state);
    const events = selectEvents(state);
    const projects = onChainProjects.filter(
      (project) =>
        ((project?.tags as ITag[]) || []).find((projectTagId: any) => projectTagId === tag.id)
    );
    const eventsWithTag = events.filter((event) =>
      event.tags?.find((eventTagId) => eventTagId === tag.id)
    );

    return {
      tag,
      projects,
      events: eventsWithTag,
    };
  }
);

export const tagsReducer = tagsSlice.reducer;
