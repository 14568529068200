import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { IHackakathonPrize } from '@pitchtalk/contract-api-js/dist/interfaces';
import { useFormik } from 'formik';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { getHackathonPrizeValidationSchema } from 'shared/utils/validationsUtils';
import { updateHackathonPrizes } from 'store/thunks/events';

interface IPrizesProps {
  offChainEventId: string;
  prizes: IHackakathonPrize[];
  isEventVoteEnded: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PRIZES_LIMIT = 3;

const getNewPrize = (): IHackakathonPrize => ({
  id: crypto.randomUUID(),

  amount: '$100',
  position: '1-5',
  description: '',

  order: 0,
});

const getPrizesState = (prizes: IHackakathonPrize[]) =>
  prizes?.length ? [...prizes].sort((a, b) => a.order - b.order) : [];

const getPrizesForSaving = (prizes: IHackakathonPrize[]) =>
  prizes.map((prize, i) => ({ ...prize, order: i }));

export const Prizes: React.FC<IPrizesProps> = ({
  offChainEventId,
  prizes,
  isEventVoteEnded,
  setIsLoading,
}) => {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: getPrizesState(prizes),
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setIsLoading(true);
      dispatch(
        updateHackathonPrizes(
          offChainEventId,
          getPrizesForSaving(values)
        )
      ).finally(() => {
        setIsLoading(false);
        formikHelpers.setSubmitting(false);
      });
    },
    enableReinitialize: true,
    validationSchema: getHackathonPrizeValidationSchema(),
  });

  const addNewPrize = () =>
    formik.setValues((prev) => [...prev, getNewPrize()]);

  const removePrize = (id: string) =>
    formik.setValues((prev) => prev.filter((prize) => prize.id !== id));

  return (
    <Box>
      <SettingsBox
        legend="Hackathon prizes"
        sx={{
          padding: '10px',
          width: '450px',
          height: 'fit-content',
          '.MuiFormGroup-root': { paddingBlock: '5px' },
        }}
      >
        {!formik.values.length && (
          <Typography sx={{ textAlign: 'center', mb: 1 }} variant="h6">
            There are no prizes
          </Typography>
        )}
        {formik.values.map((prize, i) => (
          <Box
            sx={{ border: '1px solid black', padding: '5px', mb: 2 }}
            key={i + prize.id}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>{prize.id}</Typography>
              {!isEventVoteEnded && (
                <IconButton
                  sx={{ width: '15px', height: '15px' }}
                  onClick={() => removePrize(prize.id)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box>
              <SettingsInput
                inputLabel="Prize position"
                name="prize_position"
                value={prize.position}
                onChange={(e) =>
                  formik.setFieldValue(`${i}.position`, e.target.value)
                }
                error={!!formik.errors?.[i]?.position}
                helperText={formik.errors && formik.errors?.[i]?.position}
                InputProps={{ readOnly: isEventVoteEnded }}
              />
              <SettingsInput
                inputLabel="Prize amount"
                name="prize_amount"
                value={prize.amount || ''}
                onChange={(e) =>
                  formik.setFieldValue(`${i}.amount`, e.target.value)
                }
                error={!!formik.errors?.[i]?.amount}
                helperText={formik.errors && formik.errors?.[i]?.amount}
                InputProps={{ readOnly: isEventVoteEnded }}
              />
              <SettingsInput
                inputLabel="Prize description"
                name="description"
                value={prize.description || ''}
                onChange={(e) =>
                  formik.setFieldValue(`${i}.description`, e.target.value)
                }
                error={!!formik.errors?.[i]?.description}
                helperText={formik.errors && formik.errors?.[i]?.description}
                InputProps={{ readOnly: isEventVoteEnded }}
              />
            </Box>
          </Box>
        ))}
        {formik.values.length < PRIZES_LIMIT && (
          <PrimaryButton
            disabled={formik.isSubmitting || isEventVoteEnded}
            sx={{ mb: 2 }}
            onClick={addNewPrize}
          >
            Add
          </PrimaryButton>
        )}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <PrimaryButton
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              formik.isSubmitting ||
              isEventVoteEnded
            }
            sx={{ flex: 1 }}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </PrimaryButton>
          <PrimaryButton
            disabled={!formik.dirty || formik.isSubmitting}
            sx={{ flex: 1 }}
            onClick={formik.handleReset}
          >
            Reset
          </PrimaryButton>
        </Box>
      </SettingsBox>
    </Box>
  );
};
