import {
  EEventType,
  IEvent,
  IEventPrize,
  ITag,
} from 'shared/interfaces/backendInterfaces';

export interface EventSettings {
  name: string;
  is_active: boolean;
  is_public: boolean;
  publicRequest: boolean;
  type: EEventType;
  short_description: string;
  description: string;
  tag: string;
  logo: string;
  banner: string;
  web_url: string;
  video_url: string;
  location: string;
  social_links: any;
  start_date: Date;
  end_date: Date;
  vote_end_date: Date;
  participants_limit: number | null;
  tags: string[] | ITag[];
  votes_limit: number;
  isNews: boolean;
  prizes: IEventPrize[]; // ???
  partners: string; // ???
  prize_pool: string;
  communicationChannel: string;
}

export const getInitialEventSettings = (
  (newDate: number) =>
  (event: IEvent | undefined): EventSettings => ({
    name: event?.name || '',
    is_active: event ? !!event.is_active : true,
    is_public: event ? !!event.is_public : true,
    publicRequest: event ? !!event.publicRequest : false,
    type: event?.type || EEventType.Hackathon,
    description: event?.description || '',
    short_description: event?.short_description || '',
    prize_pool: event?.prize_pool || '',
    logo: event?.logo || '',
    banner: event?.banner || '',
    web_url: event?.web_url || '',
    video_url: event?.video_url || '',
    social_links: event?.social_links || ({} as any),
    start_date: new Date(event?.start_date || newDate),
    end_date: new Date(event?.end_date || newDate),
    vote_end_date: new Date(event?.vote_end_date || newDate),
    participants_limit: event ? event.participants_limit || null : null,
    tags: event?.tags || [],
    votes_limit: event?.votes_limit || 1,
    tag: event?.eventTag || '',
    location: event?.location || '',
    isNews: event?.eventNews || false,
    prizes: event?.prizes || [],
    partners: event?.partners || '',
    communicationChannel: event?.communicationChannel || '',
  })
)(Date.now() + 10 * 60 * 1000);

export const getGeneralEventArgs = (settings: EventSettings) => ({
  name: settings.name,

  event_type: settings.type,

  logo: settings.logo || undefined,
  banner: settings.banner || undefined,
  web_url: settings.web_url || undefined,
  video_url: settings.video_url || undefined,
  description: settings.description || undefined,
  social_links: settings.social_links || {},

  start_date: settings.start_date,
  end_date: settings.end_date,
  vote_end_date: settings.vote_end_date,

  vote_limit: settings.votes_limit,
  max_project_count: settings.participants_limit,

  fee: null,

  tags: settings.tags,
});
