import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Box,
  ImageListItem,
} from '@mui/material';
import React from 'react';
import { API_ROUTES } from 'services/api/config';
import { usePaginatedApi } from 'services/hooks/useAPI';
import { useNavigate } from 'services/router';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { Loader } from 'shared/components/Loader/Loader';
import {
  IDeal,
} from 'shared/interfaces/backendInterfaces';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';

export const DealsPage: React.FC = () => {

  const navigate = useNavigate()
  const {
    data: deals,
    isLoading: isLoadingDeals,
    isError,
    setPage,
    pagination,
    mutate,
  } = usePaginatedApi<IDeal[]>({ baseUrl: API_ROUTES.getDeals });

  const editDeal = (dealId: string) => {
    navigate(dealId)
  }

  if (isLoadingDeals) return <Loader />;
  if (isError) return <Typography>Error loading Deals.</Typography>;
  if (!deals?.length) return <Typography>No Deals</Typography>;

  return (
    <Box component="div" sx={{ maxWidth: 880, marginTop: '15px', display: 'flex', flexDirection: 'column' }}>
       <PrimaryButton
        onClick={() => editDeal('create')}
      >
        Create Deal
      </PrimaryButton>
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {deals.map((deal) => {
          return (
            <ListItem
              key={deal.id}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: '#f5f5f5',
                gap: 2,
                mb: 2,
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <ImageListItem sx={{ width: 168, height: 63 }}>
                  <img
                    src={getCorrectIPFSLinks({img: deal.img as string}).img}
                    loading="lazy"
                  />
                </ImageListItem>
                <ListItemText
                  sx={{ width: '200px'}}
                  primary={
                    <Link
                      href={deal.webLink as string}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {deal.name}
                    </Link>
                  }
                />
                <Typography
                  sx={{ width: '100%' }}
                  variant="body2"
                >
                  {deal.description}
                </Typography>
                <Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ width: '100%' }}
                  variant="body2"
                >
                  <b>Offer:</b> {deal.offer}
                </Typography>
                <Typography
                  sx={{ width: '100%' }}
                  variant="body2"
                >
                  <b>Connect Link:</b> {deal.connectLink}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <PrimaryButton
                  onClick={() => editDeal(deal.id)}
                  sx={{ mx: 1 }}
                >
                  Edit
                </PrimaryButton>
              </Box>
              </Box>
            </ListItem>
          );
        })}
      </List>
       <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
         <PrimaryButton
          disabled={(pagination?.currentPage || 0) <= 1}
          onClick={() => setPage((p) => p - 1)}
        >
          Prev
        </PrimaryButton>
        <PrimaryButton
          disabled={
            (pagination?.totalPages || 0) <= (pagination?.currentPage || 0)
          }
          onClick={() => setPage((p) => p + 1)}
        >
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
};
