import ETH from 'assets/images/blockchain/ETH.png';
import Near from 'assets/images/blockchain/NEAR.png';
import Solana from 'assets/images/blockchain/Solana.png';
import { memo } from 'react';
import { ESupportedBlockchains } from 'shared/interfaces/backendInterfaces';

const BLOCKHAIN_IMAGES = {
  [ESupportedBlockchains.ETHEREUM]: ETH,
  [ESupportedBlockchains.LINEA]: '',
  [ESupportedBlockchains.NEAR]: Near,
  [ESupportedBlockchains.SOLANA]: Solana,
};

interface IBlockchainImgProps {
  blockchain: ESupportedBlockchains;
}

const BlockchainImg: React.FC<IBlockchainImgProps> = ({ blockchain }) => {
  return (
    <img
      src={BLOCKHAIN_IMAGES[blockchain]}
      style={{ width: 24, height: 24 }}
      alt={blockchain}
    />
  );
};

export default memo(BlockchainImg);
