import { AxiosResponse } from 'axios';
import { ToastService } from 'services/toast/ToastService';

import { fetchAPI } from './ServerAPI';

export const parseSuccessData =
  (successMsg?: string) =>
  <Data, Config>(resp: AxiosResponse<Data, Config>) => {
    if (successMsg) ToastService.success('', successMsg);
    return resp.data;
  };

export const parseErrorData = (errorMsg?: string) => (er: unknown) => {
  if (errorMsg) ToastService.error('', errorMsg);
  return Promise.reject(er);
};

export const parseData = <Data, Config>(resp: AxiosResponse<Data, Config>) =>
  resp.data;

export interface PaginationInfo {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export const fetcherPagination = async <Data>(
  url: string
): Promise<{ data: Data; pagination: PaginationInfo }> => {
  const response = await fetchAPI.get(url); // Make sure this is how you get your response
  const headers = response.headers;

  const pagination: PaginationInfo = {
    currentPage: parseInt(headers['x-current-page'], 10),
    pageSize: parseInt(headers['x-page-limit'], 10),
    totalItems: parseInt(headers['x-total-records'], 10),
    totalPages: parseInt(headers['x-total-pages'], 10),
  };

  return { data: response.data, pagination };
};

export const fetcher = async <Data>(url: string): Promise<Data> => {
  const response = await fetchAPI.get(url);

  return response.data;
};
