import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { Editor } from '@tinymce/tinymce-react';
import { FormikProps } from 'formik';
import omit from 'lodash/omit';
import { memo, useRef } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'services/hooks/redux-hooks';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { SettingsBox } from 'shared/components/SettingsBox';
import { SettingsInput } from 'shared/components/SettingsInput';
import { updateServerEvent } from 'store/thunks/events';

import { EventDescriptionNew } from './EventDescriptionNew';
import { EventDescriptionPreview } from './EventDescriptionPreview';
import { IEventDescription, IEventServerState } from '../types';

const createNewDescriptionItem = (i: number): IEventDescription => ({
  title: `Description Title ${i}`,
  text: `Description text content ${i}`,
});

const MAX_DESCRIPTION_ITEM_AMOUNT = 3;

interface IEventDescriptionProps {
  formik: FormikProps<IEventServerState>;
  isEventVoteEnded: boolean;
}

export const EventDescription: React.FC<IEventDescriptionProps> = memo(
  ({ formik, isEventVoteEnded }) => {
    const { eventId = '' } = useParams();
    const dispatch = useAppDispatch();
    const editorRef = useRef<Editor | null>(null);

    const saveDescription = () => {
      if (editorRef.current) {
        const editId = formik.values.editId;
        const title = formik.values.title;
        const text = (editorRef.current as any)?.getContent();
        formik.setFieldValue(`description.[${editId}].title`, title);
        formik.setFieldValue(`description.[${editId}].text`, text);
      }
    };

    const editDescription = (id: string) => {
      const title = formik.values.description[id].title;
      const text = formik.values.description[id].text;

      formik.setFieldValue('editId', id);
      formik.setFieldValue('title', title);
      formik.setFieldValue('text', text);
    };

    const removeDescription = (removeId: string) => {
      formik.setFieldValue(
        'description',
        omit(formik.values.description, removeId)
      );
      if (removeId === formik.values.editId) {
        formik.setFieldValue('editId', '');
        formik.setFieldValue('title', '');
        formik.setFieldValue('text', '');
      }
    };

    const addDescription = () => {
      const id = crypto.randomUUID();
      const newDescriptionItem = createNewDescriptionItem(
        Object.keys(formik.values.description).length + 1
      );
      formik.setFieldValue(`description.[${id}]`, newDescriptionItem);
      formik.setFieldValue('editId', id);
      formik.setFieldValue('title', newDescriptionItem.title);
      formik.setFieldValue('text', newDescriptionItem.text);
    };

    const isAddDisabled =
      Object.keys(formik.values.description).length >=
        MAX_DESCRIPTION_ITEM_AMOUNT || isEventVoteEnded;

    const updateEventDescription = async () => {
      const description = JSON.stringify(formik.values.description);
      dispatch(updateServerEvent(eventId, { description }));
    };

    return (
      <Box>
        <SettingsBox
          legend="Server Event Description"
          sx={{
            padding: '10px',
            width: '1167px',
            height: 'fit-content',
            '.MuiFormGroup-root': { paddingBlock: '5px' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <SettingsInput
                inputLabel="Title"
                name="title"
                value={formik.values.title}
                onChange={(e) => formik.setFieldValue('title', e.target.value)}
                InputProps={{
                  disabled: isEventVoteEnded || !formik.values.editId,
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Editor
                  apiKey="42tg2twsgedwt23bbpqhw77bp2ufjhm3w8svmho849e38b37"
                  // apiKey="7t3bysnxw5ppxs3u1zg9ymg3431c364ce62v8ai1czs1lc40"
                  onInit={(_, editor) =>
                    (editorRef.current = editor as unknown as Editor)
                  }
                  initialValue={formik.values.text}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link charmap print preview anchor',
                      'searchreplace visualblocks fullscreen',
                      'insertdatetime media paste help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help' +
                      'link',
                    content_style:
                      'body { font-family:Everett-Regular; font-size:16px }',
                  }}
                  disabled={!formik.values.editId}
                />
                {formik.values.editId && !isEventVoteEnded && (
                  <Box
                    sx={{
                      width: '75%',
                      marginInline: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                    }}
                  >
                    <PrimaryButton
                      sx={{ height: '30px' }}
                      onClick={() => saveDescription()}
                      disabled={isEventVoteEnded}
                    >
                      Save description
                    </PrimaryButton>
                    <PrimaryButton
                      sx={{ height: '30px' }}
                      onClick={() => {
                        formik.setFieldValue('editId', '');
                        formik.setFieldValue('title', '');
                        formik.setFieldValue('text', '');
                      }}
                      disabled={isEventVoteEnded}
                    >
                      Cancel
                    </PrimaryButton>
                  </Box>
                )}
                <EventDescriptionNew
                  onAdd={() => addDescription()}
                  disabled={isAddDisabled}
                />
              </Box>
            </Box>
          </Box>
        </SettingsBox>
        <EventDescriptionPreview
          items={formik.values.description}
          onEdit={(id) => editDescription(id)}
          onDelete={(id) => removeDescription(id)}
          editId={formik.values.editId}
          isEditDisabled={isEventVoteEnded}
        />
        <Button onClick={updateEventDescription} disabled={!eventId}>
          Update Event description
        </Button>
      </Box>
    );
  }
);
