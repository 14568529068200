/* eslint-disable no-console */
import { ITokenMetadata } from '@pitchtalk/contract-api-js/dist/FungibleTokenService';
import { compose } from '@reduxjs/toolkit';
import FTService from 'services/api-contract/FTServiceAPI';
import PTService from 'services/api-contract/PitchTalkServiceAPI';
import {
  DEFAULT_TOKEN_META,
  emptyBalance,
  PRECISION_DEFAULT,
} from 'shared/constants';
import { getTokensMeta } from 'shared/utils/FTPUtils';
import { getAmountFormatted } from 'shared/utils/near';
import { setFungibleToken } from 'store/slices/fungibleTokens';
import { selectTokens, setTokens } from 'store/slices/tokensWhiteList';
import { AppDispatch, RootState } from 'store/store';

export const fetchTokens = () => (dispatch: AppDispatch) =>
  PTService.getWhiteListTokens()
    .then(getTokensMeta)
    .then(compose(dispatch, setTokens))
    .catch((e) => console.error(e));

export const addToken = (tokenId: string) => (dispatch: AppDispatch) =>
  FTService.getMetadata(tokenId)
    .then((tokenMeta) => tokenMeta || DEFAULT_TOKEN_META)
    .then((tokenMeta) => dispatch(setFungibleToken({ tokenId, ...tokenMeta })))
    .catch((e) => console.error(e));

export const fetchUserBalance =
  (accountId: string, ftTokenId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const tokenMetadata: ITokenMetadata | undefined = selectTokens(getState())[
      ftTokenId
    ];

    if (!tokenMetadata) {
      try {
        await dispatch(addToken(ftTokenId));
        return await Promise.resolve({ ...emptyBalance, symbol: ftTokenId });
      } catch (e) {
        console.error(e);
        return emptyBalance;
      }
    }

    try {
      const balance = await FTService.getBalanceOf({
        accountId,
        ftTokenId,
      });
      const balanceFormatted = getAmountFormatted(
        balance || '0',
        tokenMetadata.decimals,
        PRECISION_DEFAULT
      );
      return {
        balance: balanceFormatted,
        symbol: tokenMetadata.symbol || ftTokenId,
        decimals: tokenMetadata.decimals,
      };
    } catch (e) {
      console.error(e);
      return emptyBalance;
    }
  };
