import { IPitchtalkMetadata } from '@pitchtalk/contract-api-js/dist/interfaces';
import {
  parseData,
  parseErrorData,
  parseSuccessData,
} from 'services/api/utils';

import { API_ROUTES } from '../api/config';
import { fetchAPI } from '../api/ServerAPI';

export const fetchPitchTalkMetaData = () =>
  fetchAPI.get<IPitchtalkMetadata>(`${API_ROUTES.metadata}`).then(parseData);

export const fetchUpdatePitchTalkMetadata = (data: IPitchtalkMetadata) =>
  fetchAPI
    .patch<IPitchtalkMetadata>(`${API_ROUTES.metadata}`, { ...data })
    .then(parseSuccessData('Pitchtalk metadata has successfully updated'))
    .catch(parseErrorData('Pitchtalk metadata has not saved'));
