import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'services/hooks/redux-hooks';
import { ToastService } from 'services/toast/ToastService';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { LoaderWithOverlay } from 'shared/components/Loader/Loader';
import YUP from 'shared/utils/yupUtils';
import {
  selectEventById,
  selectEventDescription,
  selectEventPartners,
} from 'store/selectors/events';

import { EventDescription } from './components/EventDescription';
import { EventMetadata } from './components/EventMetadata';
import { EventPartners } from './components/EventPartners';
import { IEventServerState } from './types';
import { EventEndedNotification } from '../components/EventEndedNotification';

export const EventServer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { eventId = '' } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const event = useAppSelector((state) => selectEventById(state, eventId));

  const eventDescription = useAppSelector((state) =>
    selectEventDescription(state, event.id!)
  );
  const eventPartners = useAppSelector((state) =>
    selectEventPartners(state, event.id!)
  );

  const formik = useFormik<IEventServerState>({
    initialValues: {
      partner: '',
      title: '',
      text: '',
      editId: '',
      description: eventDescription || {},
      partners: eventPartners || [],
      prize_pool: event?.serverEvent?.prize_pool || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (event?.serverEvent) {
        //
      } else if (event) {
        setIsLoading(true);
      } else {
        ToastService.error('', 'Cannot find event');
        setIsLoading(false);
      }
    },
    validationSchema: YUP.object({
      prize_pool: YUP.string().required('Prize Pool is required'),
    }),
  });

  const isEventVoteEnded = false;

  return (
    <Box>
      {/* <EventEndedNotification /> */}
      <Box
        sx={{
          width: '1300px',
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
        }}
      >
        {isLoading && <LoaderWithOverlay />}
        <EventPartners formik={formik} isEventVoteEnded={isEventVoteEnded} />
        <EventDescription formik={formik} isEventVoteEnded={isEventVoteEnded} />
      </Box>
      <Box mt={2} mb={3}>
        <PrimaryButton
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting || !formik.isValid || isLoading}
        >
          {event?.serverEvent ? 'Update' : 'Create'} event on backend
        </PrimaryButton>
      </Box>
    </Box>
  );
};
