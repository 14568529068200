import { Box, Typography } from '@mui/material';
import React from 'react';
import { getCorrectIPFSLinks } from 'shared/utils/ipfs';

import { EMPTY_STRING } from '../../constants';

export interface IPreviewImage {
  image: string;
}

export const PreviewImage: React.FC<IPreviewImage> = ({ image }) => {
  const correctImages = getCorrectIPFSLinks({
    logo: image,
  });

  const imageLink = correctImages.logo;

  return (
    <Box
      sx={{
        marginTop: '30px',
      }}
    >
      <Box
        sx={{
          border: '1px solid rgb(101, 62, 226)',
          height: '229px',
          display: 'flex',
          alignItems: 'center',
          aspectRatio: '1.5 / 1',
          borderRadius: '3px',
          justifyContent: 'center',
        }}
      >
        {imageLink ? (
          <img
            style={{
              width: '55%',
            }}
            src={imageLink}
            onError={(e) => {
              (e.target as HTMLInputElement).onerror = null;
              (e.target as HTMLInputElement).src = EMPTY_STRING;
            }}
            alt="logo"
          />
        ) : (
          <Typography
            sx={{
              color: 'rgb(196, 196, 196)',
              fontSize: '0.875rem',
              textAlign: 'center',
              fontFamily: 'Everett-Regular',
              lineHeight: '20px',
            }}
          >
            Please upload a logo
          </Typography>
        )}
      </Box>
    </Box>
  );
};
