export const taskDescriptionEditorInitSettings: Record<
  string,
  string | string[] | number | boolean
> & {
  selector?: undefined;
  target?: undefined;
} = {
  height: 150,
  menubar: false,
  plugins: [
    'advlist autolink lists link charmap print preview anchor',
    'searchreplace visualblocks fullscreen',
    'insertdatetime media paste help wordcount',
  ],
  toolbar:
    'undo redo | formatselect | ' +
    'bold italic | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  content_style: 'body { font-family:Everett-Regular; font-size:16px }',
};
