import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { PrimaryButton } from 'shared/components/DefaultButtons/PrimaryButton';
import { ISignOutModal } from 'shared/interfaces/modals';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#141414',
  color: 'white',
  border: '2px solid #653EE2',
  padding: '48px 52px 64px',
  boxShadow: 24,
  borderRadius: '10px',
};

export const SignOutModal: React.FC<ISignOutModal> = ({
  closeModal,
  accountId,
  signOut,
}): JSX.Element => {
  const handleSignOut = () => {
    signOut();
    closeModal();
  };
  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontFamily: 'Everett-Regular',
              fontSize: '1.125rem',
              textAlign: 'center',
              marginBottom: '32px',
            }}
          >
            Are you sure want to sign out from current account?
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Everett-Regular',
              fontSize: '1.625rem',
              textAlign: 'center',
              marginBottom: '32px',
            }}
          >
            {accountId}
          </Typography>
          <PrimaryButton
            sx={{ width: '100%', height: '42px' }}
            variant="contained"
            onClick={handleSignOut}
          >
            Sign Out
          </PrimaryButton>
        </Box>
      </Modal>
    </div>
  );
};
